import React, {useEffect, useRef, useState, memo} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {hover, title} from '../styles/globalStyleVars';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import {Col, Container, Row} from "react-bootstrap";
import {ImageParallax} from "./ImageParallax";
import {Link} from "react-router-dom";
import gsap from "gsap";

const DiscoverSlider = ({data, offset, feature_data}) => {
    const swiperRef = useRef(null);


    // handle pagination
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const sectionRef = useRef();

    const handleButtonClickPagination = (index) => {
        if (swiperRef.current) {
            setActiveSlideIndex(index);
            swiperRef.current.swiper.slideToLoop(index);
        }
    };


    useEffect(() => {
        let discover = document.querySelector('#discover_section')
        if (discover && sectionRef?.current) {
            if (window?.innerWidth > 767) {
                gsap.to(discover, {
                    scrollTrigger: {
                        trigger: sectionRef?.current,
                        start: "top",
                        end: "bottom-=250",
                        pinType: 'transform',
                        pin: true,
                        pinSpacing: true,
                        ease: "elastic",
                        anticipatePin: 1,
                        immediateRender: false,
                        pinnedContainer: sectionRef?.current,
                        toggleActions: "play none none reverse",
                        pinReparent: true,
                    }
                });
            }
        }
    }, [null]);

    return (
        <>
            <StyledBanner offset={offset} id={'discover_section'} ref={sectionRef}
                          className={'home-banner discover_section'}>
                <div className="">
                    {
                        data?.list && data?.list?.length > 0 &&
                        <Swiper
                            ref={swiperRef}
                            spaceBetween={0}
                            loop={true}
                            // autoplay
                            speed={1500}
                            grabCursor={false}
                            lazy="true"
                            watchSlidesProgress={true}
                            effect="fade"
                            navigation={{
                                prevEl: '#banner-prev',
                                nextEl: '#banner-next',
                            }}
                            pagination
                            modules={[Autoplay, Pagination, Navigation, EffectFade]}
                            sName="mySwiper main-swiper"
                        >
                            {
                                data?.list && data?.list?.length > 0 &&
                                data?.list.map((e, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div className="slide-inner">

                                                {
                                                    e?.images?.large &&
                                                    <div className="desktop">
                                                        <ImageParallax
                                                            src={e?.images?.large}/>
                                                    </div>
                                                }

                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    }

                    <Container className={'container-fixed'}>
                        <Row>
                            <Col md={12}>
                                {
                                    data?.subtitle &&
                                    <h6>{data?.subtitle}</h6>

                                }
                                <ul className={'item-text'}>

                                    {
                                        data?.list && data?.list?.length > 0 &&
                                        data?.list.map((e, index) => {
                                            const isActive = index === activeSlideIndex; // Check if the current element is active
                                            return (
                                                <li key={index} className={isActive ? 'active hoverable' : 'hoverable'}
                                                    onMouseEnter={() => handleButtonClickPagination(index)}
                                                >
                                                    {
                                                        e?.url ?
                                                            <Link  to={e?.url}>
                                                                {
                                                                    e?.title &&
                                                                    <h2 className={'hoverable'}>{e?.title}</h2>
                                                                }
                                                            </Link>
                                                            : ''
                                                    }

                                                </li>
                                            )
                                        })
                                    }


                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </StyledBanner>
        </>
    );
};

const StyledBanner = styled.section`
  height: 100vh;
  position: relative;
  opacity: 1 !important;

  .swiper-button-prev, .swiper-button-next, .swiper-pagination, .mobile {
    display: none;
  }

  .swiper-slide {
    overflow: hidden;
  }

  .swiper-pagination {
    display: none;
  }

  .swiper-button-disabled {
    opacity: 0.5 !important;
  }


  .main-swiper {
    //.swiper-slide {
    //  transform: unset;
    //  -webkit-flex-shrink: 0;
    //  -ms-flex-negative: 0;
    //  flex-shrink: 0;
    //  backface-visibility: hidden;
    //}
  }

  .global-image {
    transition: clipPath .8s cubic-bezier(.74, 0, .24, .99), border-color 3.6s linear, -webkit-clipPath .8s cubic-bezier(.74, 0, .24, .99);
    will-change: clipPath;
    overflow: hidden;
    -webkit-clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .swiper-slide-active, .swiper-slide-visible {
    .global-image {
      clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    .slide-inner {
      img {
        height: 100vh !important;
      }

      &__info {
        //transition-delay: 0.3s;
        //opacity: 1;
        //transform: translateX(0px) !important;

        .dc-btn {
          //transition-delay: 0.4s;
          //opacity: 1;
          //transform: translateX(0px) !important;
        }
      }

    }
  }


  .slide-inner {
    position: relative;
    height: 100vh;

    .container {
      position: absolute;
      left: 0;
      right: 0;
      top: calc(50% - 10vh);
      transform: translateY(-50%);
      z-index: 2;
    }

    .global-image {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
      }
    }

    &__info {
      position: relative;
      overflow: hidden;
      //transform: translateX(50%);
      //transition: all 2s cubic-bezier(.74,0,.24,.99);
      //transition-delay: 1.2s;
      //opacity: 1;
      @media (min-width: 1024px) {
        width: 70%;
      }

      h2 {
        font-size: 60px;
        font-weight: 500;
        line-height: 70px;
        color: #FFFFFF;
        font-family: ${title};
        position: relative;
        margin: 0;

      }

      .dc-btn {
        position: relative;
        //transform: translateY(400px);
        //transition-delay: 1.2s;
        //transform: translateX(50%);

        //transition: 2.4s all cubic-bezier(.25, .74, .22, .99);
        //opacity: 0;

      }
    }
  }


  .container-fixed {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    z-index: 99;

    h6 {
      margin-bottom: 60px;
    }

    ul {
      display: inline-flex;
      flex-direction: column;

      li {
        margin-bottom: 60px;
        display: inline-block;
        cursor: pointer;
        position: relative;
        padding-bottom: 10px;
        transition: 0.7s all cubic-bezier(.54, .32, .05, .96);

        a {
          color: #FFFFFF !important;

        }
        
        

        &:after {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 0;
          background: #fff;
          height: 1px;
          content: '';
          transition: 0.7s all cubic-bezier(.54, .32, .05, .96);
        }
        
        &.active{
          &:after{
            width: 100%;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  //responsive
  @media (min-width: 1550px) {
    .slide-inner {
      &__info {
        position: relative;
        overflow: hidden;

        h2 {
          font-size: 80px;
          line-height: 100px;
        }

        .dc-btn {
          a {
            font-size: 22px;
            line-height: 33px;
          }
        }
      }
    }

    .swipper-navigation-slider-custom {
      bottom: 300px;
    }

    .feature-box-container {
      bottom: -150px;
    }

    .feature-box-container {
      .single-item-wrapper {
        min-height: 300px;

        .content {
          min-height: 250px;
        }
      }
    }
  }
  @media (max-width: 992px) {
    .swipper-navigation-slider-custom {
      bottom: 250px;

    }
  }

  @media (max-width: 992px) and (min-width: 768px) {
    height: 100vh;
    .swipper-navigation-slider-custom {
      bottom: 60px;

      .swiper-initialized {
        padding-right: 30%;
      }
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: 45%;
      }
    }

    .feature-box-container {
      display: none;
      position: relative;
      left: 0px;
      right: 0px;
      bottom: 200px;
      z-index: 999;
      width: calc(100% - 30px);
      margin: 0px auto;


      .single-item-wrapper a .content {
        align-items: flex-start;
      }

      .feature-box-container_item:last-child {
        order: 1;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .feature-box-container_item:first-child {
        order: 2;
        flex: 0 0 100%;
        max-width: 100%;

        .single-item-wrapper {
          border-right: none;
          border-bottom: 1px solid rgba(198, 198, 198, 0.5);
        }

      }

      .feature-box-container_item:nth-of-type(2) {
        order: 3;
        flex: 0 0 50%;
        max-width: 50%;
      }

      .feature-box-container_item:nth-of-type(3) {
        order: 4;
        flex: 0 0 50%;
        max-width: 50%;

        .single-item-wrapper {
          border-right: none;

        }

      }
    }


  }
  @media (max-width: 767px) {
    height: 100vh;

    .container-fixed {
      text-align: left;
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: unset;
        transform: unset;
        bottom: 40%;
      }

      &__info {
        position: relative;
        overflow: hidden;


        h2 {
          font-size: 40px;
          line-height: 48px;
        }

        .dc-btn {
          margin-top: 30px;
        }
      }
    }

    .swipper-navigation-slider-custom {
      padding-left: 15px !important;
      padding-right: 15px !important;
      bottom: 30px;

      .swiper-initialized {
        padding-right: 30%;
      }
    }


    .scrollTo {
      left: 15px;
      right: unset;
      display: inline-block;
      bottom: 20px;
      transform: translateX(0);
    }

    .navigation {
      display: none;
    }


    .feature-box-container {

      display: none;
      position: relative;
      bottom: 150px;

      .feature-box-container_item {
        &:first-child {
          order: 2;
        }

        &:nth-of-type(2) {
          order: 3;
        }

        &:nth-of-type(3) {
          order: 4;
        }

        &:last-child {
          order: 1;

          .content {
          }
        }
      }

      .single-item-wrapper {
        border-right: none;
        border-bottom: 1px solid rgba(198, 198, 198, 0.5);

        .content {
          align-items: flex-start !important;
        }

        &.last {
          border: none;

          .content {
            align-items: center !important;
          }
        }


      }

      .container {
        padding: 0;
      }
    }
  }





`;

export default React.memo(DiscoverSlider);
