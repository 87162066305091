import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import swiper, {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import NavigationIcon from "./NavigationIcon";
import InsightBox from "./InsightBox";
import Button from "./Button";

const InsightSlider = ({data, id, no_slider, bg, padding, insight}) => {


    let [offset, setOffset] = useState(90)

    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.thisis').offsetLeft + 0)
        }
    }, [data])
    const [current, setCurrent] = useState('1')
    const handleSliderCurrent = () => {
        setTimeout(() => {
            if (document.querySelector('.sustain .swiper-pagination-current')) {
                setCurrent(document.querySelector('.sustain .swiper-pagination-current').innerHTML)
            }
        }, 200)
    }
    let title = insight ? 'Related Insights' : 'Insights';




    return (
        <StyledComponent bg={bg} container={offset} id={`${id ? id : "Sustainability"}`}
                         className={`sustain  ${padding ? padding : 'pt-200 pb-200'} ${no_slider ? 'slider_no' : ''}`}>
            <Container className={'thisis'}>
                <Row>
                    <Col md={12}>
                        <div className={'sustain__top d-flex'}>
                            <h3 className={'split-up'}>{data?.title ? data?.title : ''}</h3>
                            <Button src={data?.url ? data?.url : '/insight'} color={'#F1F0EE'} borderColor={'#F1F0EE'} text={'View All'}/>

                        </div>
                    </Col>
                    {
                        data?.list && data?.list?.length > 0 &&
                        data?.list?.map((e,index) => {
                            return(
                                <Col md={4} key={index} className={'single-item'}>
                                    <InsightBox data={e}/>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background: ${props => props.bg ? props.bg : 'inherit'};


  .reveal {
    overflow: hidden;

    img {
      transform-origin: left;
      inset: 0px;
    }
  }
  .single-item{
    margin-bottom: 30px;

  }

  .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .sustain__top {
    padding-bottom: 10px;
    justify-content: space-between;
    margin-bottom: 60px;
    p {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    .wrapper{
      padding-right: 0;
    }
    
    .sustain__top{
      margin-bottom: 40px;
      flex-direction: column;
      h3{
        margin-bottom: 40px;
      }
    }
    
    .single-item{
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }




`;

export default React.memo(InsightSlider);
