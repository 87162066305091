import React from 'react';
import styled from "styled-components";

const Logo = () => {
    return (
        <StyledLogo>
            <svg id="Button_-_Nav_-_R" data-name="Button - Nav - R" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                <g id="Base" fill="none" stroke="#f9f5f2" strokeWidth="1" opacity="0.3">
                    <rect width="45" height="45" stroke="none"/>
                    <rect x="0.5" y="0.5" width="44" height="44" fill="none"/>
                </g>
                <g id="Hover" fill="none" stroke="#f9f5f2" strokeWidth="1" opacity={0} stroke-dasharray="0 200">
                    <rect width="45" height="45" stroke="none"/>
                    <rect x="0.5" y="0.5" width="44" height="44" fill="none"/>
                </g>
                <g id="Group_22584" data-name="Group 22584" transform="translate(-1238.43 -17.5)">
                    <line id="Line_12397" data-name="Line 12397" x1="20" transform="translate(1253.857 32.929) rotate(45)" fill="none" stroke="#f1eee9" strokeLinecap="round" strokeWidth="1"/>
                    <line id="Line_12403" data-name="Line 12403" x1="20" transform="translate(1268 32.929) rotate(135)" fill="none" stroke="#f1eee9" strokeLinecap="round" strokeWidth="1"/>
                </g>
            </svg>
        </StyledLogo>
    );
};

const StyledLogo = styled.div`
    g{
      transition: 0.3s all cubic-bezier(.74,0,.24,.99);
    }
  
  &:hover{
    #Hover{
      stroke-dasharray: 180px 200px;
      opacity: 1;
    }
  }
`;

export default React.memo(Logo);
