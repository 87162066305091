import React from 'react';
import styled from "styled-components";

const MenuClose = () => {
    return (
        <StyledMenuClose>
            <svg xmlns="http://www.w3.org/2000/svg" width="61.354" height="17" viewBox="0 0 61.354 17">
                <g id="Group_23772" data-name="Group 23772" transform="translate(-298.646 -32)">
                    <text id="Close" transform="translate(323 46)" fill="#f1f0ee" fontSize="15" fontFamily="BananaGrotesk-Semibold, Banana Grotesk" fontWeight="600" letterSpacing="-0.05em"><tspan x="0" y="0">Close</tspan></text>
                    <g id="Group_23771" data-name="Group 23771" transform="translate(3.071 3.5)">
                        <line id="Line_1" data-name="Line 1" x2="20" transform="translate(295.929 29.929) rotate(45)" fill="none" stroke="#f1f0ee" strokeWidth="1"/>
                        <line id="Line_12429" data-name="Line 12429" x2="20" transform="translate(310.071 29.929) rotate(135)" fill="none" stroke="#f1f0ee" strokeWidth="1"/>
                    </g>
                </g>
            </svg>
        </StyledMenuClose>
    );
};

const StyledMenuClose = styled.div`


`;

export default React.memo(MenuClose);
