import React, {useEffect} from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {ImageParallaxNoLoad} from "./ImageParallaxNoLoad";
import {gsap} from 'gsap';

const InnerBanner = ({img, logo, subtext, title, uptitle, project, notFull, noPara}) => {
    useEffect(() => {
        // Use GSAP to animate opacity from 0 to 1
        gsap.to('.InnerBanner', {alpha: 1, visibility: 'visible' ,duration: 0.5, delay:0.5});
        gsap.to('.InnerBanner h1', {alpha: 1, visibility: 'visible' ,duration: 1, delay:0.5});
        gsap.to('.InnerBanner p', {alpha: 1, visibility: 'visible' ,duration: 0.5, delay:0.5});

        // You can customize the animation duration and other properties as needed
    }, [img,title, subtext]); // Run this effect only once when the component mounts

    return (
        <StyledInnerBanner notFull={notFull} project={project} className="InnerBanner">
            {
                noPara ?
                    <Img banner={true} src={img ? img : '/images/static/blurbg.svg'}/>
                    :
                    <ImageParallaxNoLoad banner={true} src={img ? img : '/images/static/blurbg.svg'}/>

            }
            <Container>
                <Row>
                    <Col md={12}>
                        {
                            logo ?
                                <div className="logo">
                                    <img src={logo} alt=""/>
                                </div>
                                : ''
                        }
                        <p className={'up-title'}>{uptitle ? uptitle : reactHtmlParser(uptitle)}</p>
                        <h1>{title ? reactHtmlParser(title) : ''}</h1>
                        <p>{subtext ? reactHtmlParser(subtext) : ''}</p>
                    </Col>
                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: ${props => props.notFull ? '65vh' : '100vh'};
  position: relative;
  background-color: #DDD;
  height: ${props => props.notFull ? '75vh' : '100vh'};
  overflow: hidden;
  opacity: 0;
  visibility: hidden;

  &:after {
    //content: '';
    position: absolute;
    background: linear-gradient(142deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5018601190476191) 0%);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  @media(max-width: 1024px) and (min-width: 768px)  {
    padding-top: 65vh;
    overflow: hidden;
    height: 65vh;

  }



  .container {
    position: absolute;
    height: 100%;
    margin: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;

    h1 {
      margin-bottom: ${props => props.project ? '30px' : '15px'};
      font-size: 5.333rem;
      line-height: 4.667rem;
      transform: translateY(0);
      opacity: 0;
    }

    h1 {
      color: #F1EEE9;
      text-transform: uppercase;
    }

    p {
      color: #F1EEE9;
      transform: translateY(0);
      opacity: 0;
    }

    .up-title {
      margin-bottom: 35px;
    }

    .row {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  .logo {
    img {
      height: 30px;
    }
  }

  @media (max-width: 390px) {
    padding-top: ${props => props.notFull ? '65vh' : '100vh'};
    h1 {
      font-size: 3.333rem !important;
      line-height: 3.667rem !important;
    }
  }

@media(max-width: 767px) {

  .container h1{
    font-size: 3.333rem;
    line-height: 3.667rem;
  }

}

`;

export default InnerBanner;
