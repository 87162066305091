import React, {useState, useEffect} from 'react';
import {hover} from '../styles/globalStyleVars';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import {Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {postForm} from "../api/redux/newsletter";


const SubscribeForm = () => {

    useEffect(() => {
        // Get a reference to the SVG element and the group with id "Group_5537"
        const svgElement = document.getElementById("Button_-_Subscribe");
        const groupElement = svgElement.querySelector("#Arrow");

// Define the new transform value for the group when mouseover occurs
        const newTransform = "translate(-687.5 -1329)"; // Adjust scale as needed
// Add a mouseover event listener to the SVG element
        svgElement.addEventListener("mouseover", () => {
            // Change the transform attribute of the group on mouseover
            groupElement.setAttribute("transform", newTransform);
        });

// Add a mouseout event listener to reset the transform on mouseout
        svgElement.addEventListener("mouseout", () => {
            // Reset the transform attribute of the group on mouseout
            groupElement.setAttribute("transform", "translate(-692.5 -1329)");
        });
    })


    const dispatch = useDispatch();
    const responseData = useSelector(state => state.newsletter);

    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    // --- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };


    const onSubmit = (e) => {

        let api_services = apiEndPoints.SUBSCRIBENEWSLETTER;

        var formData = new FormData();
        formData.append('your-email', e?.email);

        if (e.email !== '') {
            dispatch(postForm([api_services, {"email" : e.email, 'list'  : 1}]));
            // success('Successfully Submitted')
            reset();
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            // toast.error('please fill out the correct inputs');
        }
        count = 0;
    };
    useEffect(() => {
        if(responseData?.error?.message){
            error('Something went wrong or This email already exists')
        }

        if(responseData?.success?.message){
            success(responseData?.success?.message)
        }
        reset();

    }, [responseData])


    return (
        <StyledBtn className={`form`}>
            <Form className={``} onSubmit={handleSubmit(onSubmit, onError)}>
                <Form.Group className="form-group" controlId="formBasicEmail">

                    <Form.Control autoComplete="off"
                                  className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                  {...register("email", {
                                      required: {
                                          value: true, message: 'please enter your email'
                                      }, pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: 'please enter a valid email address'
                                      }
                                  })}
                                  type="email" placeholder="Your Email Address"/>
                    <div className="dc-btn-submit" onClick={handleSubmit(onSubmit, onError)}>
                        <svg id="Button_-_Subscribe" data-name="Button - Subscribe" xmlns="http://www.w3.org/2000/svg"
                             width="90.707" height="22.5" viewBox="0 0 90.707 22.5">
                            <g id="Group_5537" data-name="Group 5537">
                                <g id="Arrow" transform="translate(-692.5 -1329)">
                                    <line id="Line_3" data-name="Line 3" x2="5" y2="5"
                                          transform="translate(772.5 1332.5)" fill="none" stroke="#f1f0ee"
                                          strokeLinecap="round" strokeWidth="1"/>
                                    <line id="Line_4" data-name="Line 4" y1="5" x2="5"
                                          transform="translate(772.5 1337.5)" fill="none" stroke="#f1f0ee"
                                          strokeLinecap="round" strokeWidth="1"/>
                                </g>
                                <text id="Subscribe" transform="translate(0 14)" fill="#f1f0ee" fontSize="15"
                                      fontFamily="BananaGrotesk-Semibold, Banana Grotesk" fontWeight="600">
                                    <tspan x="0" y="0">Subscribe</tspan>
                                </text>
                            </g>
                            <line id="Line_5" opacity="0" data-name="Line 5" x2="70" transform="translate(0 22)"
                                  fill="none"
                                  stroke="#f1f0ee" strokeDasharray={'0 70'} strokeWidth="1"/>
                        </svg>
                    </div>
                </Form.Group>

            </Form>

        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  .form-group {
    position: relative;

    .form-control {
      height: 30px;
      //padding-left: 40px;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 1px solid #F1F0EE;
      background-color: transparent;
      border-radius: 0;
      autocomplete: off;
      padding: 0 0 10px;
      margin-bottom: 30px;
      font-size: 1rem; /* 15px / 15px = 1rem */
      font-weight: 600;
      line-height: 1rem; /* 20px / 15px = 1.3333rem */
      color: #F1F0EE;


      ::placeholder {
        font-size: 1rem; /* 15px / 15px = 1rem */
        font-weight: 600;
        line-height: 1rem; /* 20px / 15px = 1.3333rem */
        color: #F1F0EE;
        outline: none;
        border: none;

      }

      :focus {
        outline: none;
        outline-color: transparent;
        border-color: white;
      }
    }

    .dc-btn-submit {
      cursor: pointer;

      svg {
        #Arrow, #Line_5 {
          transition: 0.5s all cubic-bezier(.74,0,.24,.99);
        }

        &:hover {
          #Line_5 {
            opacity: 1;
            stroke-dasharray: 70px 70px;
          }

          #Group_5537 {
            //transform: translateX(20px);
          }
        }
      }
    }

  }

`;


export default React.memo(SubscribeForm);
