import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row, Form} from "react-bootstrap";
import {black, hover, title} from "../styles/globalStyleVars";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {Img} from "./Img";
import 'react-modal-video/css/modal-video.min.css';
import {Link, useLocation} from "react-router-dom";
import Logo from "./svg/Logo";
import HamubrerLine from "./svg/HamubrerLine";
import MenuClose from "./svg/MenuClose";
import {BsChevronRight, BsX} from "react-icons/bs";
import Button from "./Button";
import Search from "./svg/Search";
import OutsideClickHandler from 'react-outside-click-handler';
import Social from "./Social";
import MobileMenuClose from "./svg/MobileMenuClose";
import Cursor from "./Cursor";
import CareerModal from "./CareerModal";

gsap.registerPlugin(CSSPlugin);

const Menu = ({offset}) => {
    const router = useLocation();
    let tl = new TimelineLite();
    let tl2 = new TimelineLite();
    let tll = new TimelineLite();
    let [videoId, setVideo] = useState("");
    let [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('')
    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };
    const searchClickRef = useRef()
    const searchItemRef = useRef()
    const [searchInput, setSearchInput] = useState('')


    //desktop menu contact dropdown
    useEffect(() => {
        document.querySelector(`.click-dropdown`)?.addEventListener('click', () => {
            if (!document.querySelector('.main-menu__contact').classList.contains('open-dropdown')) {
                document.querySelector('.main-menu__contact').classList.add('open-dropdown');
                tl.to('.dropdown-list', {
                    duration: .2,
                    display: 'block',
                }).to('.dropdown-list', {
                    y: '20px',
                    duration: .4,
                    height: 'calc(100% - 20px)',
                    alpha: 1,
                    paddingTop: '33px',
                    paddingBottom: '15px',
                    ease: "circ.out"
                })
            } else {
                document.querySelector('.main-menu__contact').classList.remove('open-dropdown');
                tl.to('.dropdown-list', {
                    duration: .3,
                    height: '0%',
                    alpha: 0,
                    paddingTop: '0',
                    y: '0px',
                    ease: "circ.out"
                }).to('.dropdown-list', {
                    duration: .2,
                    display: 'none',
                })
            }
        })
        window.addEventListener('click', (e) => {
            if (document.querySelector('.main-menu__contact') && document.querySelector('.main-menu__contact').classList.contains('open-dropdown')) {
                if (!e.target.matches('.click-dropdown, .click-dropdown p, .click-dropdown svg, .click-dropdown line')) {
                    tl.to('.dropdown-list', {
                        duration: .3,
                        height: '0%',
                        alpha: 0,
                        paddingTop: '0',
                        y: '0px',
                        ease: "circ.out"
                    }).to('.dropdown-list', {
                        duration: .2,
                        display: 'none',
                    })

                    document.querySelector('.main-menu__contact').classList.remove('open-dropdown');
                }
            }
        })
    }, []);

    //desktop menu on click toggle
    useEffect(() => {
        document.querySelector('.main-menu__menu__inner').addEventListener('click', () => {
            // disableScroll.on()
            if (window.safari !== undefined) {
                document.body.classList.add('overflow')
            }
            document.querySelector('.main-menu').classList.add('menu-is-open')
            document.querySelector('body').classList.add('menu-is-open')
            tll.to('.main-menu__items', {
                duration: '0',
                display: 'block'
            }).to('.main-menu__items', {
                opacity: '1',
                duration: '.6'
            }).to('.main-menu__items__img', {
                opacity: '1',
                duration: '.3'
            }, '-=.5').to('.main-menu__items__list', {
                duration: .5,
                y: 0,
                alpha: 1
            }, '-=.5')

        })

        // desktop menu close
        const closeDelstopMenu = () => {
            tll.to('.main-menu__items__list', {
                duration: .2,
                y: '-40px',
                alpha: 0
            }).to('.main-menu__items', {
                opacity: '0',
                duration: '.3'
            }).to('.main-menu__items__img', {
                opacity: '0',
                duration: '.2'
            }, '-=.5').to('.main-menu__items__list', {
                duration: .2,
                y: '40px',
                alpha: 0
            }, '-=.3').to('.main-menu__items', {
                duration: '.4',
                opacity: 0
            }).to('.main-menu__items', {
                display: 'none'
            })
        }
        document.querySelector('.menu-close').addEventListener('click', () => {
            document.querySelector('.main-menu').classList.remove('menu-is-open')
            document.querySelector('body').classList.remove('menu-is-open')

            closeDelstopMenu()
            // disableScroll.off()
            if (document.body.classList.contains('overflow')) {
                document.body.classList.remove('overflow')
            }
        })

    }, [])


    //desktop menu hover action
    useEffect(() => {
        let getLis = document.querySelectorAll('.menu-col li a'),
            getImgs = document.querySelectorAll('.main-menu__items__img li');
        for (var i = 0; i < getLis.length; i++) {
            getLis[i].setAttribute('data-index', i);

            getLis[i].onmouseover = function () {
                document.querySelector('.main-menu__items__img').classList.remove('parent-add')
                getImgs.forEach(e => {
                    e.classList.remove('active')
                })
                getImgs[this.getAttribute('data-index')].classList.add('active')
                setTimeout(() => {
                    document.querySelector('.main-menu__items__img').classList.add('parent-add')
                }, 200)
            };
        }
    }, [])


    //----- mobile menu action
    useEffect(() => {
        let getMobileMenuBar = document.querySelector('.main-menu-mobile');
        let getItemsParent = document.querySelector('.main-menu-mobile__items');
        let getItems = document.querySelectorAll('.main-item');
        let getBacks = document.querySelectorAll('.sub-menu-back');
        let getHamburgerClick = document.querySelector('#open-click')
        let getHamburgerCloseClick = document.querySelector('#close-click')

        // menu open toggle
        getHamburgerClick.addEventListener('click', () => {
            document.body.classList.add('menu-on')
            document.querySelector('.main-menu-mobile').classList.add('menu-is-open')
            getMobileMenuBar.classList.add('menu-open')
            document.body.classList.add('stop-scroll')
            tl2.to(getItemsParent, {
                duration: .2,
                display: 'block',
            }).to(getItemsParent, {
                duration: .2,
                x: 0
            }, '-=.2')
        })

        getHamburgerCloseClick?.addEventListener('click', () => {
            getMobileMenuBar.classList.remove('menu-open')
            document.body.classList.remove('menu-on')
            document.querySelector('.main-menu-mobile').classList.remove('menu-is-open')
            document.body.classList.remove('stop-scroll')
            if (document.querySelector('.main-item.active')) {
                getItemsParent.classList.remove('active')
                document.querySelector('.main-item.active').classList.remove('active')
            }

            tl2.to(getItemsParent, {
                duration: .2,
                x: '100%'
            }).to(getItemsParent, {
                duration: .2,
                display: 'none'
            })
        });


        // sub menu toggle
        getItems.forEach(i => {
            i.addEventListener('click', () => {
                getItemsParent.classList.add('active')
                i.classList.add('active')
            })
        })

        getBacks.forEach(i => {
            i.addEventListener('click', (e) => {

                getItemsParent.classList.remove('active')
                i.parentNode.parentElement.classList.remove('active')
                e.stopPropagation()
            })
        })

    }, [])


    // menu fixed on scroll
    useEffect(() => {
        if (document.body.classList.contains("scroll-down")) {
            document.body.classList.remove("scroll-down");
        }
    });

    useEffect(() => {
        const whichPage = router.pathname
    }, [router?.pathname])

    const [scrollUp, setScrollUp] = useState(false)
    const [scrollDown, setScrollDown] = useState(false)
    useEffect(() => {
        const body = document.body;
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";
        let lastScroll = 0;
        let howMuchScroll = 20;
        window.addEventListener("scroll", () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                // body.classList.remove(scrollUp);
                setScrollUp(false)
                setScrollDown(false)

                return;
            }
            if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
                // down
                // body.classList.remove(scrollUp);
                // body.classList.add(scrollDown);
                setScrollDown(true)
                setScrollUp(false)

            } else if (
                currentScroll < lastScroll
                // body.classList.contains(scrollDown)
            ) {
                // up
                // body.classList.remove(scrollDown);
                // body.classList.add(scrollUp);
                setScrollUp(true)
                setScrollDown(false)

            }
            lastScroll = currentScroll;
        });
    }, []);


    // search handle
    const handleInput = (e) => {
        setSearchInput(e.target.value);
    }

    const searchSubmit = (e) => {
        e.preventDefault()
        if (searchInput === '') {
            return;
        } else {
            window.location.href = `/search?keyword=${searchInput}`;
            document.querySelector('.search-desktop').classList.remove('search-open')
            gsap.to(searchItemRef.current, {
                duration: .4, height: 0, opacity: 0, overflow: 'hidden'
            })
            gsap.to(searchItemRef.current.querySelector('.container'), {
                opacity: 0,
            }, '-=.4')
            gsap.to(searchItemRef.current, {
                display: 'none'
            })
        }
        // setSearchInput('')


    }


    useEffect(() => {
        // Function to close the search box
        const closeSearchBox = () => {
            if (searchItemRef.current.classList.contains("search-open")) {
                document.querySelector("body").classList.remove("open_menu");
                document.body.classList.remove("menu-open");

                searchItemRef.current.classList.remove("search-open");
                gsap.to(searchItemRef.current, {
                    duration: 0.4,
                    height: 0,
                    opacity: 0,
                    overflow: "hidden",
                });
                gsap.to(searchItemRef.current.querySelector(".container"), {
                    opacity: 0,
                }, "-=.4");
                gsap.to(searchItemRef.current, {
                    display: "none",
                });

            }
        };

        // Open the search box when the search button is clicked
        searchClickRef.current.addEventListener("click", () => {
            if (!searchItemRef.current.classList.contains("search-open")) {
                searchItemRef.current.classList.add("search-open");
                gsap.to(searchItemRef.current, {
                    display: "flex",
                });
                gsap.to(searchItemRef.current, {
                    duration: 0.5,
                    height: 195,
                    opacity: 1,
                    overflow: "visible",
                });
                gsap.to(searchItemRef.current.querySelector(".container"), {
                    opacity: 1,
                    delay: 0.2,
                });
                document.querySelector("body").classList.add("open_menu");

                // Add a mousedown event listener to the document to check if the click occurred within the search box, input field, or the excluded element
                document.addEventListener("mousedown", (e) => {
                    if (
                        !searchItemRef.current.contains(e.target) &&
                        !searchClickRef.current.contains(e.target) &&
                        !e.target.classList.contains("search-desktop") &&
                        !e.target.classList.contains("search-open")
                    ) {
                        closeSearchBox();
                    }
                });
            } else {
                // closeSearchBox();
            }
        });

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", closeSearchBox);
        };
    }, []);


    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        // setPopupId()
    }


    return (
        <>
            <CareerModal show={show} data={popupId} handleClose={handleClose}/>

            {/* desktop menu*/}
            <StyledMenu offset={offset}
                        className={`main-menu menu-bar ${scrollDown ? 'scroll-down' : ''} ${scrollUp ? 'scroll-up' : ''} `}>


                {/*menu bar*/}
                <Container>
                    <Row>
                        <Col md={5} className={'main-menu__menu'}>
                            <div className="main-menu__menu__inner">
                                <div className="wrap hoverable">
                                    <HamubrerLine/>
                                </div>
                            </div>

                            <ul className={'short-menu'}>
                                <li>
                                    <a href={'tel:+8801773-253467'}>
                                        +8801773-253467
                                    </a>
                                </li>

                            </ul>

                        </Col>
                        <Col md={2} className={'main-menu__logo justify-content-start d-flex'}>
                            <Link to={'/'}>
                                <Logo/>
                            </Link>
                        </Col>
                        <Col md={5} className={'main-menu__search justify-content-end align-items-center d-flex'}>
                            <div className="button" onClick={() => handleShow()}>
                                <Button margin={'0 60px 0 0'} color={'#F1F0EE'} borderColor={'#F1F0EE'}
                                        text={'Book a Call'}/>
                            </div>
                            <div ref={searchClickRef} className="search_button hoverable">
                                <Search/>
                            </div>
                        </Col>

                    </Row>
                </Container>

                {/*menu items*/}
                <div className="main-menu__items">

                    <div className="d-flex">
                        {/*menu items right*/}
                        <Col sm={7} className={'p-0'}>
                            <div className="main-menu__items__list d-flex">
                                <div className="menu-close ">
                                    <MenuClose/>
                                </div>
                                <Col className={'menu-col'} sm={{span: 5}}>
                                    <ul>
                                        <li className={`${router.pathname === '/' ? 'active' : ''}`}><Link
                                            to="/">Home</Link></li>
                                        <li className={`${router.pathname === '/about' ? 'active' : ''}`}><Link
                                            to="/about">About Us</Link></li>
                                        <li className={`${router.pathname === '/insights' ? 'active' : ''}`}><Link
                                            to="/insights">Insights</Link></li>

                                        <li className={`${router.pathname === '/contacts' ? 'active' : ''}`}>
                                            <Link
                                                to="/contacts">Contact</Link></li>

                                    </ul>
                                </Col>
                                <Col className={'menu-col'} sm={5}>
                                    <ul>
                                        <li className={`${router.pathname === '/furniture' ? 'active' : ''}`}><Link
                                            to="/furniture">Furniture</Link></li>
                                        <li className={`${router.pathname === '/lights' ? 'active' : ''}`}>
                                            <Link
                                                to="/lights">Lights</Link></li>
                                        <li className={`${router.pathname === '/tiles' ? 'active' : ''}`}><Link
                                            to="/tiles">Tiles</Link></li>

                                        <li className={`${router.pathname === '/brand' ? 'active' : ''}`}>
                                            <Link
                                                to="/brand">Brands</Link></li>
                                        <li className={`${router.pathname === '/upcoming-products' ? 'active' : ''}`}>
                                            <Link to="/upcoming-products">Upcoming Products</Link></li>
                                    </ul>
                                </Col>

                                <div className="main-menu__items__list__bottom row">
                                    <div className="copyright col-md-6">
                                        <p><a href="mailto:info@ticbd.com">
                                            info@ticbd.com
                                        </a></p>
                                        <p><a href="tel:+8801773-253467">
                                            +8801773-253467
                                        </a></p>
                                    </div>
                                    <div className="copyright col-md-6">
                                        <Social/>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        {/*menu left img*/}
                        <Col sm={5} className={'p-0'}>
                            <div className="main-menu__items__img">
                                <li className={'active'}><Img src={'/images/dynamic/menu1.jpg'}/></li>
                                <li><Img src={'/images/dynamic/sliderbanner.jpg'}/></li>
                                <li><Img src={'/images/dynamic/menu1.jpg'}/></li>
                                <li><Img src={'/images/dynamic/sliderbanner.jpg'}/></li>
                                <li><Img src={'/images/dynamic/menu1.jpg'}/></li>
                                <li><Img src={'/images/dynamic/sliderbanner.jpg'}/></li>
                                <li><Img src={'/images/dynamic/menu1.jpg'}/></li>
                                <li><Img src={'/images/dynamic/sliderbanner.jpg'}/></li>
                                <li><Img src={'/images/dynamic/menu1.jpg'}/></li>
                                <li><Img src={'/images/dynamic/sliderbanner.jpg'}/></li>
                                <li><Img src={'/images/dynamic/menu1.jpg'}/></li>
                                <li><Img src={'/images/dynamic/sliderbanner.jpg'}/></li>

                            </div>
                        </Col>


                    </div>

                </div>


                {/*search */}
                <div className="search-desktop" ref={searchItemRef}>
                    <Container>
                        <div className="clickto clickto_close">
                            {/*<BsX/>*/}
                        </div>
                        <Form onSubmit={searchSubmit}>
                            <Form.Group className="search-input">
                                <Form.Control value={searchInput} onChange={e => handleInput(e)} type="text"
                                              placeholder="Search
                                                  "/>
                                <button type='submit'>Search</button>
                            </Form.Group>
                        </Form>


                    </Container>
                </div>
            </StyledMenu>


            {/*mobile menu*/
            }
            <StyledMobileMenu
                className={`main-menu-mobile ${scrollDown ? 'scroll-down-mb' : ''} ${scrollUp ? 'scroll-up-mb' : ''}`}>


                <div className="main-menu-mobile__bar">
                    {/*sound toggle */}
                    <div className="mute-toggle-mobile">

                        <svg width="16.4" height="16">
                            <g id="Group_16461" data-name="Group 16461" transform="translate(-150 1177)">
                                <g id="Group_16460" data-name="Group 16460" transform="translate(-11 -1)">
                                    <g id="Group_16459" data-name="Group 16459"
                                       transform="translate(-63 -1208)">
                                        <path id="volume"
                                              d="M9.229,0A6.557,6.557,0,0,0,4.591,1.921L2.713,3.8.345,4.983A.625.625,0,0,0,0,5.542v4.917a.625.625,0,0,0,.346.559L2.713,12.2l1.878,1.877A6.557,6.557,0,0,0,9.229,16a.625.625,0,0,0,.625-.625V.625A.625.625,0,0,0,9.229,0ZM8.6,14.714a5.269,5.269,0,0,1-3.13-1.519C3.4,11.132,3.524,11.209,3.363,11.129L1.25,10.072V5.928L3.363,4.872A19.679,19.679,0,0,0,5.474,2.805,5.27,5.27,0,0,1,8.6,1.286Z"
                                              transform="translate(224 32)" fill="#34342e"/>
                                        <line id="Line_13" data-name="Line 13" y1="5"
                                              transform="translate(236.9 37.5)" fill="none" stroke="#34342e"
                                              strokeLinecap="round" strokeWidth="1"/>
                                        <line id="Line_14" data-name="Line 14" y1="10"
                                              transform="translate(239.9 34.5)" fill="none" stroke="#34342e"
                                              strokeLinecap="round" strokeWidth="1"/>
                                    </g>
                                </g>
                            </g>
                        </svg>

                    </div>


                    <div className="main-menu-mobile__bar__logo">
                        <Link to={'/'}>
                            <Logo/>
                        </Link>
                    </div>

                    <div className="main-menu-mobile__bar__hamburger d-flex justify-content-end">
                        <div className="" id={'open-click'}>
                            <HamubrerLine/>
                        </div>
                        <div id={'close-click'}>
                            <MobileMenuClose/>
                        </div>
                    </div>
                </div>

                <div className="main-menu-mobile__items">

                    <div className="main-menu-mobile__items__head">

                    </div>
                    <div className="main-menu-mobile__items__body">
                        <div className="button-for-mobile">
                            <a href="tel:+8801773-253467"> +8801773-253467</a>
                            <Link to={'/search'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="68" height="17" viewBox="0 0 68 17">
                                    <g id="Group_23775" data-name="Group 23775" transform="translate(-143 -203)">
                                        <text id="Search" transform="translate(188 217)" fill="#1a181b" fontSize="15"
                                              fontFamily="BananaGrotesk-Semibold, Banana Grotesk" fontWeight="600"
                                              letterSpacing="-0.05em">
                                            <tspan x="-22.118" y="0">Search</tspan>
                                        </text>
                                        <g id="_12" data-name="12" transform="translate(141.04 203.04)">
                                            <path id="Path_1903" data-name="Path 1903"
                                                  d="M14.768,13.847l-1.835-1.829a6.174,6.174,0,1,0-.914.914l1.829,1.835a.651.651,0,1,0,.921-.921ZM3.283,8.147A4.864,4.864,0,1,1,8.147,13.01,4.864,4.864,0,0,1,3.283,8.147Z"
                                                  transform="translate(0 0)" fill="#1a181b"/>
                                        </g>
                                    </g>
                                </svg>
                            </Link>
                        </div>
                        <ul className={'menu-list'}>
                            <li className={`${router.pathname === '/' ? 'active' : ''}`}><Link to="/">Home</Link>
                            </li>
                            <li className={`${router.pathname === '/about' ? 'active' : ''}`}><Link
                                to="/about">About Us</Link></li>
                            <li className={`${router.pathname === '/insights' ? 'active' : ''}`}><Link
                                to="/insights">Insights</Link></li>
                            <li className={`${router.pathname === '/contacts' ? 'active' : ''}`}><Link
                                to="/contacts">contact</Link></li>
                            <li className={`${router.pathname === '/furniture' ? 'active' : ''}`}><Link
                                to="/furniture">Furniture</Link></li>
                            <li className={`${router.pathname === '/lights' ? 'active' : ''}`}>
                                <Link to="/lights">Lights</Link></li>
                            <li className={`${router.pathname === '/tiles' ? 'active' : ''}`}>
                                <Link to="/tiles">Tiles</Link></li>
                            <li className={`${router.pathname === '/brand' ? 'active' : ''}`}>
                                <Link to="/brand">Brands</Link></li>
                            <li className={`${router.pathname === '/upcoming-products' ? 'active' : ''}`}>
                                <Link to="/upcoming-products">Upcoming Products</Link></li>
                        </ul>
                        <div className="bottom">
                            <div className="socila-for-mobile"><Social/></div>
                            <p><a href="tel:+8801773253467">+8801773-253467</a></p>
                            <p><a href="mailto:info@ticbd.com">info@ticbd.com</a></p>
                        </div>
                    </div>
                </div>

            </StyledMobileMenu>
        </>

    )
        ;
};

const StyledMenu = styled.section`
  height: 90px;
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
  align-items: center;
  justify-content: center;
  display: flex;

  > div {
    width: 100%;
  }

  &.menu-is-open {
    transform: none !important;
  }

  @media (max-width: 989px) {
    display: none;
  }

  .logo {
    position: absolute;
    top: 40px;
    left: 98px;
    z-index: 5;
  }

  .main-menu__contact {
    height: fit-content;

    &__drop {
      width: 152px;
      height: 40px;
      background-color: black;
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      display: flex;
      cursor: pointer;


      p {
        font-size: 20px;
        color: #ffffff;

        svg {
          margin-left: 10px;
        }
      }
    }

    &__list {
      background-color: #F2EEE8;
      padding: 0px 30px 0 30px;
      width: 260px;
      display: none;
      height: 0;
      overflow: hidden;


      a {
        color: ${hover};
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 23px;


        span {
          display: inline-flex;
          height: 24px;
          width: 24px;
          background: #000;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          span:after {
            height: 0;
            width: 0;
          }
        }
      }

      ul {
        li {
          display: flex;
          padding-bottom: 17px;
          margin-bottom: 18px;
          border-bottom: 1px solid rgba(178, 168, 159, 0.20);
          flex-wrap: wrap;

          a {
            color: ${hover};
            font-size: 16px;
            line-height: 20px;
            display: flex;
            width: 100%;
            margin: 0;
            padding: 0;
            text-transform: lowercase;
            transition: letter-spacing 0.7s cubic-bezier(.74, 0, .24, .99);

            &:hover {
              letter-spacing: 1px;
              color: ${hover};

            }
          }

          span {
            font-size: 12px;
            line-height: 24px;
            color: #B2A89F;
            width: 100%;
            padding-bottom: 2px;

          }

          &:nth-last-of-type(1) {
            padding: 0;
            margin: 0;
            border: none;
          }
        }
      }
    }

  }

  .main-menu__logo {
    align-items: center;
    justify-content: center !important;

    svg {
      transition: all .3s cubic-bezier(.74, 0, .24, .99);
      height: auto;
    }
  }

  .main-menu__menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .short-menu {
      display: flex;

      li {
        margin-left: 35px;

        a {
          color: #F1F0EE;
          font-size: 1rem; /* 15px / 15px = 1rem */
          font-weight: 600;
          line-height: 1rem; /* 20px / 15px = 1.3333rem */
          letter-spacing: -0.05rem;
          position: relative;
          top: 2px;
        }
      }
    }

    &__inner {
      padding: 0px 0 0px 0;
      cursor: pointer;
      height: fit-content;
      transition: padding .4s cubic-bezier(.74, 0, .24, .99);
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin-top: 1px;
        text-transform: uppercase;
        font-weight: bold;
        color: #F7F3F0;
        line-height: 18px;
        letter-spacing: 2px;
        transition: all .6s cubic-bezier(.74, 0, .24, .99) 0s;;

      }

      .wrap {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 18px;
        margin-left: 0px;
      }

      .line {
        height: 2px;
        background-color: white;
        margin-left: auto !important;
        border-radius: 5px;
        transform-origin: right;
        transition: width .6s cubic-bezier(.74, 0, .24, .99) 0s;;
        width: 40px;

      }

      &:hover {
        p {
          color: #5C5550;
        }

        //
        //.line {
        //  background: #5C5550;
        //
        //  &:nth-of-type(2) {
        //    width: 20px;
        //  }
        //}
      }
    }

  }

  .main-menu__items {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    top: 0;
    left: 0;
    display: none;
    opacity: 0;
    background: #1a181b;


    // overview_bg
    .noise {
      position: absolute;
      inset: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: #26201E;

      &:after {
        content: '';
        background-image: url('/images/static/background-noise.png');
        background-blend-mode: multiply;
        //animation: Noise_grain 5s infinite linear;
        //width: calc(100% + 500px);
        //height: calc(100% + 500px);
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        opacity: 0.3;
      }

      &:after {
        //animation: Noise_grain 5s infinite linear;
      }


    }


    .d-flex {
      width: 100%;
    }


    &__img {
      position: relative;
      height: 100vh;
      overflow: hidden;
      background: #fff;


      li {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;

        img {
          transition: all .6s cubic-bezier(.74, 0, .24, .99);
          opacity: 0;
        }

        &.active {
          opacity: 1;
          z-index: 3;

          img {
            opacity: 1;
          }

          &:after {
          }
        }
      }
    }

    &__list {
      min-height: 100%;
      padding-top: 160px;
      transform: translateY(40px);
      opacity: 0;

      .menu-close {
        position: absolute;
        top: 40px;
        left: ${props => props.offset + 0}px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        cursor: pointer;
        z-index: 4;

        &:after {
          //z-index: 2;
          background-color: #F7F3F0;
          border: 1px solid #F7F3F0;
          border-radius: 50%;
        }


      }

      .menu-col {
        padding-left: ${props => props.offset + 0}px;
      }

      ul {
        li {
          margin-bottom: 25px;

          a {
            font-family: ${title};
            font-size: 24px;
            line-height: 32px;
            font-weight: 300;
            color: #F1F0EE;
            position: relative;
            display: inline-block;
            margin: 0;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              height: 1px;
              background-color: ${hover};
              bottom: 0;
              width: 0;
              transition: 0.7s all cubic-bezier(.74, 0, .24, .99);
            }

            &:hover {
              &:before {
                width: 100%;
              }

              color: ${hover} !important;
            }
          }

          &.active {
            a {
              color: ${hover} !important;

              pointer-events: none;

              &:before {
                width: 100%;
              }
            }
          }
        }
      }

      &__bottom {
        position: absolute;
        bottom: 60px;
        background-color: transparent;
          //width: calc(100% - ${props => props.offset + 0});
        padding-left: ${props => props.offset + 0}px;
        width: 100%;
        right: 0;
        margin: 0;
        //@media (max-height: 650px) {
        //  display: none;
        //}

        .copyright {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-end;
          padding-left: 0;
          padding-right: 0;

          &:first-child {
            p {
              margin: 0;
            }
          }

          &:last-child {
            p {
              margin-bottom: 0px;
            }
          }

          p {
            color: #F1F0EE;
          }

          a {
            color: #F1F0EE;
          }

          .social-list {
            display: flex;
            width: 100%;
            margin-top: 10px;
            display: flex;
            margin-left: -10px;

            li {
              margin-bottom: 0;

              &:before {
                display: none !important;
              }

              a {
                &:before {
                  display: none !important;
                }

                svg {
                  position: relative;
                  z-index: 2;

                  g {
                  }
                }

                &:hover {
                  path {
                    fill: ${hover};
                  }
                }
              }

              .hover:after {
                background-color: ${hover};
              }

              &:not(:nth-last-of-type(1)) {
                margin-right: 20px;
              }
            }
          }
        }

        .video {

          .hover:after {
            background-color: #fff;
          }

          span {
            display: flex;
            height: 30px;
            width: 30px;
            border: 1px solid ${hover};
            align-items: center;
            justify-content: center;
            background-color: #000;
            border-radius: 50%;
            margin-right: 13px;
            margin-top: -4px;

            path {
              transition: fill 0.7s cubic-bezier(.74, 0, .24, .99);
            }

          }

          p {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 20px;
            line-height: 24px;
            color: ${hover};

            &:hover {
              .hover:after {
                height: 0;
                width: 0;
              }

              path {
                fill: #FFF;
              }
            }
          }
        }

        .social {
          margin-top: 30px;

          ul {
            display: flex;

            li {
              a {
                margin: 0 30px 0 0;
                height: 45px;
                width: 45px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #000;
                padding: 0;

                &:after {
                  //display: none;
                }
              }

              &:nth-last-of-type(1) a {
                margin: 0;
              }
            }
          }
        }
      }
    }

  }

  .mute-toggle {
    position: absolute;
    left: 200px;
    top: 10px;
    //bottom: 0;
    margin: auto;
    cursor: pointer;
    height: fit-content;
    display: none;
    //align-items: center;

    line {
      display: none;
    }

    svg {
      cursor: pointer;
    }

    &.sound {
      line {
        display: block;
      }
    }
  }


  @media (max-height: 750px) {
    //.main-menu__items__list__bottom {
    //  left: 60px;
    //}
    .main-menu__items__list {
      padding-top: 150px;


    }
  }


  .search-desktop {
    //height: 195px;
    background-color: #F9F9F9;
    //display: flex;
    align-content: center;
    flex-wrap: wrap;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 95px;
    height: 0;
    display: none;
    opacity: 0;
    overflow: hidden;
    z-index: 9;
    box-shadow: 0 1px 6px rgb(0 0 0 / 10%);


    .clickto_close {
      cursor: pointer;

      svg {
        fill: black;
      }
    }

    .container {
      position: relative;
      opacity: 0;
    }

    position: absolute;

    svg {
      position: absolute;
      right: 15px;
      top: -50px;
      z-index: 3;

    }


    form {
      min-width: 100%;

      .search-input {
        button, p, a {
          box-shadow: none;
          border: none;
          background-color: transparent;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: fit-content;
          font-size: 12px;
          line-height: 18px;
          color: #221f1f;
          transition: color .3s cubic-bezier(.74, 0, .24, .99);
          cursor: pointer;

          &:hover {
            color: #D0CA10;
          }
        }

        span {
          position: absolute !important;
          bottom: 18px;
        }

        .form-control {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #221F1F;
          padding-left: 0px;
          padding-bottom: 9px;
          padding-top: 0;
          height: 50px;
          font-size: 32px;
          line-height: 36px;
          font-weight: 600;
          color: black;
          background-color: #F9F9F9;

          ::placeholder {
            font-size: 32px;
            line-height: 36px;
            font-weight: 600;
            color: rgba(60, 60, 59, 0.5);
          }
        }

      }
    }

    &__menu {
      margin-top: 23px;
      min-width: 100%;

      p {
        font-size: 12px;
        color: #ed1b34;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 13px;

      }

      ul {
        display: inline-flex;

        li {
          a {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: #221f1f;
            display: flex;
            margin-right: 30px;
          }

          &:nth-last-child(1) {
            a {
              margin-right: 0;
            }
          }
        }
      }

    }

  }

`;

//----- mobile menu
const StyledMobileMenu = styled.section`
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  transition: transform 0.2s cubic-bezier(.74, 0, .24, .99);
  transition-delay: .3s;
  //background: rgba(0, 0, 0, 0.3);
  height: 60px;
  padding-bottom: 20px;


  .mute-toggle-mobile {
    position: absolute;
    left: 15px;
    top: 12px;
    //bottom: 0;
    margin: auto;
    cursor: pointer;
    height: fit-content;
    display: none;
    //align-items: center;

    path {
      fill: ${hover}
    }

    line {
      display: none;
      stroke: ${hover};
    }

    svg {
      cursor: pointer;
    }

    &.sound {
      line {
        display: block;
      }
    }
  }

  &.menu-is-open {
    transform: none !important;

    #close-click {
      opacity: 1;
      display: block;
    }

    #open-click {
      opacity: 0;
      display: none;

    }
  }

  @media (min-width: 989px) {
    display: none;
  }

  #open-click {
    transition: all .4s cubic-bezier(.74, 0, .24, .99);
    display: block;

  }

  #close-click {
    opacity: 0;
    display: none;

  }

  .main-menu-mobile__bar {

    display: flex;
    justify-content: space-between;
    //align-items: center;
      //border-bottom: 1px solid ${hover};
    padding: 0 15px;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    //background-color: rgba(34, 34, 34, 0.30);
    z-index: 9999;
    transition: all .4s cubic-bezier(.74, 0, .24, .99);
    height: 60px;

    &__logo {
      position: relative;
      margin: auto;
      width: fit-content;

      img {
        height: 50px;
      }

    }

    &__hamburger {

      width: 100%;
      padding-top: 0;
      height: 100%;
      align-items: center;


      svg {
        height: 20px;
        opacity: 1;
        transition: 1s all cubic-bezier(.74, 0, .24, .99);
      }
    }


  }


  .main-menu-mobile__items {
    padding: 60px 0 0;
    position: relative;
    transform-origin: top left;
    transition: all .3s cubic-bezier(.54,.32,.05,.96);
    height: calc(100vh);
    overflow-y: auto;
    overflow-x: hidden;
    width: 100vw;
    transform: translateX(100%);
    display: none;
    z-index: 999;
    background: #1a181b;

    // overview_bg
    .noise {
      position: absolute;
      inset: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: #26201E;

      &:after {
        content: '';
        background-image: url('/images/static/background-noise.png');
        background-blend-mode: multiply;
        //animation: Noise_grain 5s infinite linear;
        //width: calc(100% + 500px);
        //height: calc(100% + 500px);
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        opacity: 0.3;
      }

      &:after {
        //animation: Noise_grain 5s infinite linear;
      }


    }

    &__head {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      width: calc(100vw);
      top: 0px;
      left: 0px;
      height: 60px;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      background: transparent;


      #close-click {

      }

      .sub-menu-back {
        display: none;
      }
    }

    &__body {
      height: 100%;
      width: 100%;
      padding: 60px 20px;
      position: relative;
      right: 0;
      margin: 0 0 0 auto;

      .button-for-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 100%;
          text-align: center;
          color: #1A181B;
          background: #F1F0EE;
          font-size: 1rem; /* 15px / 15px = 1rem */
          font-weight: 600;
          line-height: 1rem; /* 20px / 15px = 1.3333rem */
          letter-spacing: -0.05rem;
          border-radius: 25px;
          overflow: hidden;
          margin-bottom: 25px;

          &:last-child {
            margin-bottom: 0;
          }

          &:hover {
            color: #1A181B;
          }
        }
      }

      ul {
        width: 100%;

        &.menu-list {
          //height: calc(100vh - 300px);
          //overflow-y: auto;
          //width: 100%;
          height: 100%;
          width: 100%;

          a {
            width: 100%;
            display: flex;
          }
        }

        .main-item {
          ul {
            li {
              a {
                pointer-events: auto !important;
              }

              &:nth-of-type(1) {
                a {
                  position: relative;
                  display: flex;


                  &:after {
                    content: '';
                    position: absolute;
                    height: 24px;
                    width: 24px;
                    background-color: ${hover};
                    right: -30px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    border-radius: 100%;
                    //background-image: url();
                    //background-repeat: no-repeat;
                    //background-position: center;
                  }
                }
              }


            }
          }
        }


        li {
          text-transform: capitalize;
          display: block;
          width: 100%;


          a {
            color: #F1F0EE;
            font-size: 24px;
            line-height: 24px;
            font-weight: 300;
            font-family: ${title};
          }

          &:not(:nth-last-of-type(1)) {
            margin-bottom: 24px;

          }

          &.active {
            a {
              border-bottom: 1px solid #F1F0EE;
              width: fit-content;
              pointer-events: none;
            }
          }

          //sub menus
          ul {
            left: 0;
            list-style: none;
            margin: 0;
            position: absolute;
            top: 113px;
            padding: 0 15px;
            //height: 100vh;
            opacity: 0;
            transform: translate3d(100vw, 0, 0);
            transition: all .3s cubic-bezier(.54,.32,.05,.96);
            //padding-bottom: 40px;
            width: 100vw;
            padding-bottom: 25px;
            height: auto !important;
            overflow: hidden !important;

            li {
              width: auto !important;
              background-color: transparent !important;
              height: auto !important;
              display: block !important;
              text-align: left !important;

              &:nth-of-type(1) {
                margin-bottom: 80px !important;
              }

              &:not(:nth-of-type(1)) {
                a {

                  text-transform: capitalize;
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  font-size: 30px;
                  line-height: 30px;

                  span {
                    min-width: 100%;
                    font-size: 12px;
                    line-height: 23px;
                    display: block;
                    margin-bottom: 13px;
                    color: #b2a89f;
                  }
                }
              }

            }
          }

          &.active {
            ul {
              opacity: 1;
              z-index: 2;
            }

          }

        }
      }


      &.active {
        transform: translate3d(-100vw, 0, 0) !important;

        .main-menu-mobile__items__head {
          transform: translate3d(100vw, 0, 0) !important;

          .sub-menu-back {
            display: block;
          }

          a {
            display: none;
          }
        }
      }

      .bottom {
        width: calc(100vw - 30px);
        position: relative;
        bottom: 60px;
        padding-bottom: 60px;

        p {
          &:first-child {
            margin-bottom: 20px;
          }

          margin: 0;

          a {
            color: #F1F0EE;
            font-size: 12px;
            line-height: 24px;
            font-weight: 500;
          }
        }

        .socila-for-mobile {
          margin-bottom: 20px;

          li {
            margin: 0 15px 0 0;
            width: auto;
          }
        }


      }
    }

  }





`;

export default Menu;
