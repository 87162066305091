import React, {Suspense, useEffect, useLayoutEffect, useRef, useState} from "react";
import {Route, Switch, useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {ImageReveal} from "./components/animations/imageReveal";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollSmoother} from "gsap/ScrollSmoother";
// page imports
import About from './pages/about';
import Contacts from './pages/contacts';
import Home from './pages/home';
import Project from './pages/product';
import UpcomingProject from './pages/product/upcomingproducts';
import Brand from './pages/product/brands';
import TitleCat from './pages/category/tiles';
import FurnitureCat from './pages/category/furniture';
import LightCat from './pages/category/lights';
import ProjectDetail from './pages/product/single';
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import Insights from "./pages/insights";
import InsightDetail from './pages/insights/single';
import Tiles from "./pages/product/tiles";
import Furniture from "./pages/product/furniture";
import Lights from "./pages/product/lights";
import Brands from "./pages/brands/index";
import {SmootherContext} from "./components/SmootherContext";
import QuotationButton from "./components/QuotationButton";
import CareerModal from "./components/CareerModal";
import Search from './pages/search';
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PageLoaderNew from "./components/preload/PageLoaderNew";
// import Router from "./router/Router";
import Cursor from "./components/Cursor2";

function App() {

    const location = useLocation();
    const [offset, setOffset] = useState('90');
    const main = useRef();
    const smoother = useRef()
    let [smootherContext, setSmootherContext] = useState();
    ScrollTrigger.refresh()


    // smooth scroll
    const useIsomorphicLayoutEffect = typeof window !== "undefined"
        ? useLayoutEffect
        : useEffect;


    // smooth scroll


    useIsomorphicLayoutEffect(() => {
        // Check if the screen width is greater than 767 pixels
            gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

            const ctx = gsap.context(() => {
                smoother.current = ScrollSmoother.create({
                    wrapper: '#smooth-wrapper',
                    content: '#smooth-content',
                    smooth: window?.innerWidth < 767 ? false : 3, // how long (in seconds) it takes to "catch up" to the native scroll position
                    effects: window?.innerWidth < 767 ? false : true, // looks for data-speed and data-lag attributes on elements
                    normalizeScroll: {
                        allowNestedScroll: true,
                    },
                    ignoreMobileResize: true,
                    preventDefault: true,
                }, main);



            }, main);








            const bigBall = document.querySelector(".cursor__ball--big");
            const smallBall = document.querySelector(".cursor__ball--small");
            const hoverables = document.querySelectorAll(".hoverable");
            const alla = document.querySelectorAll("a");
// Listeners
            document.body.addEventListener("mousemove", onMouseMove);
            hoverables.forEach((element) => {
                element.addEventListener("mouseenter", onMouseHover);
                element.addEventListener("mouseleave", onMouseHoverOut);
            }); alla.forEach((element) => {
                element.addEventListener("mouseenter", onMouseHover);
                element.addEventListener("mouseleave", onMouseHoverOut);
            });

            const xBTo = gsap.quickTo(bigBall, "x", {duration: 0.4});
            const yBTo = gsap.quickTo(bigBall, "y", {duration: 0.4});
            const xSTo = gsap.quickTo(smallBall, "x", {duration: 0.1});
            const ySTo = gsap.quickTo(smallBall, "y", {duration: 0.1});

// Move the cursor
            function onMouseMove(e) {
                xBTo(e.clientX - 15);
                yBTo(e.clientY - 15);
                xSTo(e.clientX - 5);
                ySTo(e.clientY - 7);
            }

// Hover an element
            function onMouseHover() {
                gsap.to(bigBall, {
                    scale: 4,
                    duration: 0.3
                });
                // Add any other hover effects you want here
            }

// Hover out of an element
            function onMouseHoverOut() {
                gsap.to(bigBall, {
                    scale: 1,
                    duration: 0.3
                });
                // Remove any other hover effects here
            }


            return () => {
                ctx.revert();
            };
    }, [location.pathname,window?.innerWidth]);


    useEffect(() => {
        // Check if the screen width is greater than 767 pixels
        if (window.innerWidth > 767) {
            gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

            const ctx = gsap.context(() => {
                smoother.current = ScrollSmoother.create({
                    wrapper: '#smooth-wrapper',
                    content: '#smooth-content',
                    smooth: 2,
                    // speed:1,
                    effects: true,
                    normalizeScroll: {
                        allowNestedScroll: true,
                    },
                    ignoreMobileResize: false,
                    preventDefault: false,
                }, main);

                // ScrollTrigger.normalizeScroll({
                //     allowNestedScroll: true,
                //     lockAxis: false,
                //     momentum: (self) => Math.min(3, Math.abs(self.velocityY) / 1000),
                //     type: 'touch,wheel,pointer',
                // });

                // ScrollTrigger.config({
                //     limitCallbacks: true,
                //     ignoreMobileResize: false,
                // });
            }, main);

            return () => {
                ctx.revert();
            };
        }
    }, [location.pathname]);


    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === '/') {
            document.body.classList.add('in-home-page')
        } else {
            document.body.classList.remove('in-home-page')
        }

        window.scroll(0, 0)
    }, [location.pathname])


    // animation functions
    ImageReveal()
    // SplitUp()


    // get offset for container
    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container').offsetLeft + 15)
        }
    }, [location.pathname])


    const [isLoadingContent, setIsLoadingContent] = useState(true);
    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        setIsLoadingContent(true); // Show preloader when route changes

        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoadingContent(false); // Set isLoadingContent to false when content is ready
        }, 2500); // Adjust the delay duration as needed
    }, [location?.pathname]);

    // Check if the current pathname matches the pattern "products/{slug}"
    const isProductPage = location.pathname.match(/^\/product\/[a-zA-Z0-9-]+$/);
    const isBrand = location.pathname.match(/^\/brands/);


    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        // setPopupId()
    }


    // social icon
    let [shareUrl, setShareUrl] = useState('')
    useEffect(() => {
        setShareUrl(window.location.href)
    }, [shareUrl])


    const requestForm = useRef()

    // useEffect(() => {
    //     const links = document.querySelector('.request-for-quoteOK');
    //
    //     if (links) {
    //         links.addEventListener('click', function (e) {
    //
    //         });
    //
    //         // Also listen for touch events
    //         links.addEventListener('touchstart', function (e) {
    //             e.preventDefault();
    //             gsap.to(window, {
    //                 duration: 1,
    //                 scrollTo: { y: "#" + 'request-form', offsetY: 0 },
    //             });
    //             ScrollTrigger.refresh();
    //         });
    //     }
    //
    // }, [location.pathname]);

    const GOTO = (e) => {
        e.preventDefault();
        gsap.to(window, {
            duration: 1,
            scrollTo: {y: "#" + 'request-form', offsetY: 0},
        });
        ScrollTrigger.refresh()
    }
    return (
        <>
            <GlobalStyle/>
            <ToastContainer position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>
            <Menu offset={offset}/>

            {
                location?.pathname === '/' &&
                <PageLoaderNew/>
            }

            {
                isProductPage &&
                <div className="fixed-element request-for-quoteOK">
                    <div data-attr={'request-form'} id={'#request'} onClick={GOTO}
                         className={'request-for-quote d-inline-block'}>
                        <QuotationButton  text={'Request for Quotation'}/>

                    </div>
                </div>
            }

            {
                isBrand &&
                <div className="fixed-element share">
                    <div className="share_wrapper d-flex">
                        <p className="meta-font">Share:</p>
                        <div className="social-lists">
                            <EmailShareButton url={shareUrl}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Rectangle_6283" data-name="Rectangle 6283" width="14" height="14"
                                                  transform="translate(14289 121)" fill="#fff"/>
                                        </clipPath>
                                    </defs>
                                    <g id="Mask_Group_19945" data-name="Mask Group 19945"
                                       transform="translate(-14289 -121)" clipPath="url(#clip-path)">
                                        <g id="Icon_feather-link-2" data-name="Icon feather-link-2"
                                           transform="translate(14289.616 130.128) rotate(-45)">
                                            <path id="Path_173" data-name="Path 173"
                                                  d="M7.66,0H9.3a2.882,2.882,0,0,1,2.736,3.009A2.882,2.882,0,0,1,9.3,6.018H7.66m-3.283,0H2.736A2.882,2.882,0,0,1,0,3.009,2.882,2.882,0,0,1,2.736,0H4.377"
                                                  fill="none" stroke="#f1f0ee" strokeLinecap="round"
                                                  strokeLinejoin="round" strokeWidth="1.2"/>
                                            <path id="Path_174" data-name="Path 174" d="M0,0H5.1"
                                                  transform="translate(3.467 3.009)" fill="none" stroke="#f1f0ee"
                                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"/>
                                        </g>
                                    </g>
                                </svg>

                            </EmailShareButton>
                            <FacebookShareButton url={shareUrl}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Rectangle_6282" data-name="Rectangle 6282" width="14" height="14"
                                                  transform="translate(14289 121)" fill="#fff"/>
                                        </clipPath>
                                    </defs>
                                    <g id="Mask_Group_19944" data-name="Mask Group 19944"
                                       transform="translate(-14289 -121)" clipPath="url(#clip-path)">
                                        <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f"
                                              d="M8.032,7.088l.356-2.28H6.16V3.327A1.151,1.151,0,0,1,7.469,2.1H8.482V.154A12.575,12.575,0,0,0,6.684,0,2.809,2.809,0,0,0,3.649,3.069V4.807H1.609v2.28h2.04V12.6H6.16V7.088Z"
                                              transform="translate(14290.955 121.7)" fill="#f1f0ee"/>
                                    </g>
                                </svg>
                            </FacebookShareButton>
                            <LinkedinShareButton url={shareUrl}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Rectangle_6281" data-name="Rectangle 6281" width="14" height="14"
                                                  transform="translate(14289 121)" fill="#fff"/>
                                        </clipPath>
                                    </defs>
                                    <g id="Mask_Group_19943" data-name="Mask Group 19943"
                                       transform="translate(-14289 -121)" clipPath="url(#clip-path)">
                                        <g id="_x31_0.Linkedin" transform="translate(14289.663 121.662)">
                                            <path id="Path_10758" data-name="Path 10758"
                                                  d="M46.491,43.967V39.324c0-2.282-.491-4.024-3.153-4.024a2.751,2.751,0,0,0-2.487,1.363h-.032V35.506H38.3v8.461h2.63v-4.2c0-1.109.206-2.171,1.569-2.171,1.347,0,1.363,1.252,1.363,2.234v4.119h2.63Z"
                                                  transform="translate(-33.816 -31.292)" fill="#f1f0ee"/>
                                            <path id="Path_10759" data-name="Path 10759" d="M11.3,36.6h2.63v8.461H11.3Z"
                                                  transform="translate(-11.094 -32.386)" fill="#f1f0ee"/>
                                            <path id="Path_10760" data-name="Path 10760"
                                                  d="M11.521,10a1.529,1.529,0,1,0,1.521,1.521A1.521,1.521,0,0,0,11.521,10Z"
                                                  transform="translate(-10 -10)" fill="#f1f0ee"/>
                                        </g>
                                    </g>
                                </svg>
                            </LinkedinShareButton>
                            <TwitterShareButton url={shareUrl}>

                                <svg id="layer1" xmlns="http://www.w3.org/2000/svg" width="11.035" height="10.328"
                                     viewBox="0 0 11.035 10.328">
                                    <path id="path1009"
                                          d="M281.527,167.31l4.26,5.7-4.287,4.632h.965l3.754-4.055,3.033,4.055h3.284l-4.5-6.017,3.991-4.311h-.965l-3.457,3.735-2.793-3.735Zm1.419.711h1.509l6.661,8.907h-1.509Z"
                                          transform="translate(-281.5 -167.31)" fill="#f1f0ee"/>
                                </svg>

                            </TwitterShareButton>

                        </div>
                    </div>
                </div>
            }

            {
                window?.innerWidth < 767 &&
                <div className="fixed-element">
                    <div onClick={() => handleShow()}>
                        <QuotationButton text={'Book a Call'}/>
                    </div>
                </div>
            }

            <Cursor/>


            <SmootherContext.Provider value={smoother.current}>
                <div className="App" ref={main} id="smooth-wrapper">
                    <div id="smooth-content">

                        <Suspense fallback={''}>
                            {
                                window?.innerWidth < 767 &&
                                <CareerModal show={show} data={popupId} handleClose={handleClose}/>
                            }


                            <Switch location={location} key={'location.pathname'}>
                                <Route exact path={`/`}>
                                    <Home/>
                                </Route>

                                <Route exact path={`/insights`}>
                                    <Insights/>
                                </Route>
                                <Route exact path={`/insights/:slug`}>
                                    <InsightDetail/>
                                </Route>
                                <Route exact path={`/about`} >
                                    <About/>
                                </Route>
                                <Route exact path={`/product`}>
                                    <Project/>
                                </Route>
                                <Route exact path={`/brands/:slug`}>
                                    <Brand/>
                                </Route>
                                <Route exact path={`/categories/tiles`}>
                                    <TitleCat/>
                                </Route>
                                <Route exact path={`/categories/furniture`}>
                                    <FurnitureCat/>
                                </Route>
                                <Route exact path={`/categories/lights`}>
                                    <LightCat/>
                                </Route>
                                <Route exact path={`/tiles`}>
                                    <Tiles/>
                                </Route>
                                <Route exact path={`/furniture`}>
                                    <Furniture/>
                                </Route>
                                <Route exact path={`/lights`} >
                                    <Lights/>
                                </Route>
                                <Route exact path={`/contacts`} >
                                    <Contacts/>
                                </Route>
                                <Route exact path={`/product/:slug`} >
                                    <ProjectDetail/>
                                </Route>
                                <Route exact path={`/upcoming-products`} >
                                    <UpcomingProject/>
                                </Route>
                                <Route exact path={`/search`}>
                                    <Search/>
                                </Route>
                                <Route exact path={`/brand`}>
                                    <Brands/>
                                </Route>
                                <Route component={Error}/>

                            </Switch>

                            <Footer/>
                        </Suspense>
                    </div>
                </div>
            </SmootherContext.Provider>


        </>


    );
}

export default App;
