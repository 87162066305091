import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "./Img";
import StoneWhite from "./svg/StoneWhite";
import StoneBlack from "./svg/StoneBlack";
import {OnlyImg} from "../OnlyImg";
import reactHtmlParser from "react-html-parser";
import {OnlyImageNoLoad} from "./OnlyImageNoLoad";


const OverviewInner = ({padding, data}) => {


    const space = parseInt(data?.space)
    const NonBreakingSpaces = ({count}) => {
        const nonBreakingSpaces = '\u00A0'.repeat(count); // '\u00A0' is the HTML entity for a non-breaking space
        return <span dangerouslySetInnerHTML={{__html: nonBreakingSpaces}}/>;
    };


    return (
        <StyledOverviewInner className={`about_overview ${padding ? padding : 'pt-200 pb-200'} `}>
            {
                window?.innerWidth > 767 &&
                <div className="icon_black box-up">
                    <StoneBlack/>
                </div>
            }

            {
                window?.innerWidth > 767 &&
                <div data-lag={0.3} className="img_fixed">
                    <div className="icon_white box-up">
                        <StoneWhite/>
                    </div>
                    <div className="img_wrp reveal">
                        <OnlyImg src={data?.image_left}/>
                    </div>
                </div>
            }

            <Container>
                <Row>
                    <Col md={{span: 10, offset: 1}}>
                        <div className="title-wrp">
                            <h3 data-lag={0.1}>
                                <NonBreakingSpaces count={space}/>
                                {

                                    data?.title &&
                                    reactHtmlParser(data?.title)
                                }</h3>
                        </div>
                    </Col>
                    {
                        window?.innerWidth < 767 &&
                        <Col md={12}>
                            <div className="img_fixed">
                                <div className="icon_white box-up">
                                    <StoneWhite/>
                                </div>
                                <div className="img_wrp reveal">
                                    <OnlyImageNoLoad src={data?.image_left}/>
                                </div>
                            </div>
                        </Col>
                    }
                    <Col md={{span: 5, offset: 7}}>
                        <div className="content">
                            {/*<p className={'p1-font split-up'}></p>*/}
                            {reactHtmlParser(data?.description)}
                        </div>

                        <div data-lag={0.3} className="image_content">
                            <div className="img-wrapper">
                                {
                                    window?.innerWidth < 767 &&
                                    <div className="icon_black box-down">
                                        <StoneBlack/>
                                    </div>
                                }
                                <div className="reveal-right">
                                    <Img src={data?.image_right}/>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;

  .title-wrp {
    margin-bottom: 80px;
  }

  .img_fixed {
    left: 0;
    top: 30%;
    position: absolute;

    .icon_white {
      position: absolute;
      right: -100px;
      bottom: 80px;
      z-index: 8;
    }

    .img_wrp {
      overflow: hidden;
      //padding-top: calc(600 / 468 * 100%);
      position: relative;
      max-width: 468px;
      max-height: 600px;
      @media (min-width: 1920px) {
        max-width: 900px;
        max-height: 800px;
      }
    }
  }

  .icon_black {
    right: 0;
    top: 200px;
    position: absolute;
  }

  .content {
    margin-bottom: 80px;
    width: 60%;

    p {
      color: #818181;
    }
  }

  .image_content {
    .img-wrapper {
      overflow: hidden;
      position: relative;
      padding-top: calc(360 / 470 * 100%);
    }
  }

  @media (max-width: 767px) {
    .img_fixed {
      position: relative;
      left: 0;
      right: 0;
      top: unset;
      bottom: unset;
      width: 74%;
      margin-bottom: 60px;
    }

    .image_content {
      width: 75%;
      margin: 0 0 0 auto;

      .icon_black {
        z-index: 3;
        right: unset;
        left: -50px;
        top: -50px;
      }

      .bZKZcg {
        height: 100px;
        width: 150px;
      }

      .img-wrapper {
        overflow: visible;
      }
    }

    .content {
      margin-bottom: 60px;
      width: 74%;
    }
  }
`;


export default React.memo(OverviewInner);
