import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import gsap, {CSSPlugin, TimelineLite} from "gsap";
import InnerBanner from "../../components/InnerBanner";
import OverviewInner from "../../components/about/OverviewInner";
import MissionVision from "../../components/MissionVision";
import DiscoverAbout from "../../components/DiscoverAbout";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPosts} from "../../api/redux/about";
import {useSelector, useDispatch} from "react-redux";
import {SplitText} from "gsap/SplitText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useLocation} from "react-router-dom";
gsap.registerPlugin(ScrollTrigger, CSSPlugin, SplitText);

const ABOUT = () => {


    const dispath = useDispatch()
    const location = useLocation()





    // api call
    useEffect(() => {
        let api_url = apiEndPoints.ABOUT
        dispath(fetchPosts([api_url]))
    }, [dispath, location?.pathname])


    let getPost = useSelector(store => store.about)

    let page_data = getPost?.posts?.page_data?.[0]?.meta;
    let inner_banner = getPost?.posts?.page_data?.[0].innerBanner;
    let overview = getPost?.posts?.page_data?.[0].overview;
    let mission = getPost?.posts?.page_data?.[0].mission;
    let vision = getPost?.posts?.page_data?.[0].vision;
    let features = getPost?.posts?.page_data?.[0].features;




    // for close menu
    let getMobileMenuBar = document.querySelector('.main-menu-mobile');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');
    let tl2 = new TimelineLite();
    const closeDelstopMenu = () => {
        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })
    }

    useEffect(() => {
        document.querySelector('body').classList.remove('menu-is-open')

        document.querySelector('.main-menu').classList.remove('menu-is-open')
        closeDelstopMenu()
        // disableScroll.off()
        if (document.body.classList.contains('overflow')) {
            document.body.classList.remove('overflow')
        }


        getMobileMenuBar?.classList.remove('menu-open')
        document.body?.classList.remove('menu-on')
        document.querySelector('.main-menu-mobile').classList.remove('menu-is-open')
        document.body?.classList.remove('stop-scroll')
        if (document.querySelector('.main-item.active')) {
            getItemsParent.classList.remove('active')
            document.querySelector('.main-item.active').classList.remove('active')
        }

        tl2.to(getItemsParent, {
            duration: .2,
            x: '100%'
        }).to(getItemsParent, {
            duration: .2,
            display: 'none'
        })


        ScrollTrigger.refresh()
    }, [location?.pathname,dispath])
    useEffect(() => {

        gsap.utils.toArray('.split-up').forEach((item, i) => {
            const parentSplit = new SplitText(item, {
                linesClass: "split-parent"
            })
            const childSplit = new SplitText(item, {
                type: "lines, chars",
                linesClass: "split-child"
            })
            gsap.from(parentSplit.lines, {
                duration: 1,
                yPercent: 100,
                // stagger: 0.1,
                scrollTrigger: {
                    trigger: item,
                    toggleActions: "restart none none reset",
                    // start: "top 60%",
                    // markers: true
                }
            })
        });


        const box = document.querySelectorAll('.box');
        const boxplus = document.querySelectorAll('.boxr');
        const cta = document.querySelectorAll('.cta');
        const box_up = document.querySelectorAll('.box-up');
        const box_down = document.querySelectorAll('.box-down');



        if (window.innerWidth > 767) {


            box.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_up.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_down.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });

            boxplus.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            cta.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -50,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });


        }

    }, [getPost,location?.pathname,dispath])


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title+' - Trade Intercontinental' : 'Trade Intercontinental'}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                <InnerBanner title={inner_banner?.title} subtext={inner_banner?.shortDesc}
                             img={inner_banner?.image?.large}/>
                <OverviewInner data={overview}/>
                <MissionVision mission={mission} vision={vision} padding={'pb-200'}/>
                {
                    features ?
                        <DiscoverAbout data={features} padding={''}/>
                        : ''
                }
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default ABOUT;
