import React, {useState, useEffect, useRef, memo} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "./Button";
import {toast} from "react-toastify";
import {Img} from "./Img";
import {useForm} from "react-hook-form";
import {postForm} from "../api/redux/contact";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../api/network/apiEndPoints";

const ContactForm = ({padding, data, id}) => {


    const dispatch = useDispatch();
    const responseData = useSelector(state => state.CONTACT);

    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});



    // --- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };


    const onSubmit = (e) => {

        let api_services = apiEndPoints.CONTACT_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.phone);
        formData.append('your-message', e?.message);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error?.message)
        }
        if (responseData && responseData?.success !== '') {
            success(responseData?.success)

        }
        reset();

    }, [responseData])


    return (<StyledListWithForm id={`${id ? id : 'ListWithForm'}`}
                                className={`list_with_form ${padding ? padding : 'pt-200 pb-200'} `}>
        <Container fluid>
            <Row>

                <Col className={'p-0'} md={{span: 8, offset: 2}}>
                    <div className="
">
                        <h3 className={'split-text'}>Connect & explore <br/> opportunities</h3>

                    </div>
                    <div className="form_wrapper">
                        <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>

                            <input name={'spam_protector'} type='hidden'/>
                            <input name={'form_id'} value={'contact-form'} type='hidden'/>
                            <div className="form-group">
                                <Form.Group controlId="formBasicEmail">

                                    <Form.Control
                                        className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("name", {
                                            required: 'Name is required',

                                        })}
                                        type="text"
                                        placeholder="Name*"
                                    />
                                </Form.Group>
                            </div>
                            <Row>
                                <Col md={6}>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicPhone">

                                            <Form.Control
                                                className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("phone", {
                                                    required: {
                                                        value: true, message: 'please enter your phone first'
                                                    }, pattern: {
                                                        value: /^01[0-9]{9}$/,
                                                        message: 'please enter a valid 11 digit phone number'
                                                    }
                                                })}
                                                type="number"
                                                placeholder="Phone Number*"/>
                                        </Form.Group>
                                    </div>


                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">

                                            <Form.Control
                                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("email", {
                                                    required: {
                                                        value: true, message: 'please enter your email'
                                                    }, pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: 'please enter a valid email address'
                                                    }
                                                })}
                                                type="email"
                                                placeholder="Email*"/>
                                        </Form.Group>
                                    </div>

                                </Col>
                            </Row>

                            <div className="form-group">
                                <Form.Group controlId="formBasicPhone">
  <textarea
      className={formState?.errors?.email?.message ? 'has-error form-control-lg textarea form-control' : 'textarea form-control form-control-lg'}
      {...register('message', {
          required: {
              // value:true,
              message: 'please enter your Message',
          },
      })}
      placeholder="Message (Optional)"
      rows={4} // You can adjust the number of rows as needed
  />
                                </Form.Group>

                            </div>

                            <div className={`form-group text-center d-flex justify-content-center`}>
                                <div onClick={handleSubmit(onSubmit, onError)}>

                                    <Button color={'#F1F0EE'} borderColor={'#F1F0EE'} text={'Submit Message'}/>

                                </div>

                            </div>

                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    </StyledListWithForm>)
};

const StyledListWithForm = styled.div`

  .form_wrapper {
    padding: 60px;
    height: 100%;
    position: relative;
    // overview_bg

    .left-col {
      div {
        height: 100%;
      }
    }

    @media (max-width: 992px) and (min-width: 768px) {
      padding: 30px;
    }

    form {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
    }

    .textarea {
      min-height: 120px;
    }

    .title {
      font-size: 24px;
      font-weight: 4000;
      line-height: 28px;
      color: #F9F9F9;
      margin: 0 0 40px;
    }

    .form-group {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        display: block;
      }

      .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;
        background: transparent;
        height: auto;
        border-left: none;
        border-right: none;
        border-top: none;
        border-color: rgba(241, 238, 233, 0.5);
        padding: 0 0 8px;
        color: #F1EEE9;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;

        svg {
          display: block;
        }

        &::placeholder {
          color: rgba(241, 238, 233, 0.5);
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }


  }

  .row{
    .form-group{
      margin-bottom: 40px !important;
    }
  }

  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
    .left-col {
      min-height: 60vh;
    }

    .form_wrapper {
      padding: 60px 15px;
      margin-top: 0px;
    }

    .left-col {
      padding-right: 0px;
    }

    .container {
      padding: 0;
    }

    .width-fit {
      display: flex;
    }
  }
`;

export default React.memo(ContactForm);
