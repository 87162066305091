import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from "react-bootstrap";
import {hover, text} from "../styles/globalStyleVars";
import Select, {components} from "react-select";

const SelectField = ({next_id,clear, onChange, disabled, prev_id, color, placeholder, selection, option}) => {
    const selectLocationInputRef = useRef();
    const [selectLocation, setSelectLocation] = useState('')

    const types = [
        {value: 'commercial', label: 'Commercial'},
        {value: 'residential', label: 'Residential'},
    ]


    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s cubic-bezier(.74,0,.24,.99)",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `${text}` : '#221F1F',
            backgroundColor: state.isSelected ? `rgba(232, 231, 231, 0.6)` : '#FFF',
            margin: 0,
            fontSize: 14,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 700 : 400,
            "&:hover": {
                backgroundColor: `${text}`,
                color: '#000',
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#191818',
            backgroundColor: state.isSelected ? `${text}` : '#191818',
            margin: 0,
            borderRadius: 0,
            fontSize: 14,
            zIndex: 10
            // width: 200,
        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#191818',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 14,
        }),

    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };
    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
            <g id="Group_23485" data-name="Group 23485" transform="translate(0.5 0.5)">
                <line id="Line_12425" data-name="Line 12425" x2="10" transform="translate(0 5)" fill="none"
                      stroke="#f1f0ee" strokeLinecap="round" strokeWidth="1"/>
                <line id="Line_12426" data-name="Line 12426" x2="10" transform="translate(5) rotate(90)" fill="none"
                      stroke="#f1f0ee" strokeLinecap="round" strokeWidth="1"/>
            </g>
        </svg>

            ;
    };


    const handleLocation = (e) => {
        // Remove the following line:
        // setSelectLocation(e);

        // Invoke the onChange callback with the selected value
        if (onChange) {
            onChange(e);
        }
    };


    return (
        <StyledSelectField>
            <div className="form-group">
                <Form.Group controlId="formBasicPhone">
                    <Select isDisabled={disabled ? true : false}
                            classNamePrefix="filter"
                            ref={selectLocationInputRef}
                            isSearchable={false}
                            isClearable={clear ? true : false}
                            options={selection ? selection : types}
                            onChange={e => {
                                handleLocation(e);
                            }}
                            placeholder={`${placeholder ? placeholder : 'Select Location'}`} styles={customStyles}
                            components={{DropdownIndicator}}
                    />
                </Form.Group>
            </div>
        </StyledSelectField>
    )
};


const StyledSelectField = styled.div`
  position: relative;


  //react select
  .filter--is-disabled{
    opacity: 0.3;
    cursor: not-allowed;
  }
  .filter__control {
    height: 50px;
    border-radius: 0 !important;
    font-size: 16px;
    outline: none !important;
    border-color: #818181 !important;
    box-shadow: none !important;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    padding: 0;
    cursor: pointer;
    background: transparent;


    .filter__value-container, .filter__indicator {
      padding-left: 0;
      padding-right: 0;
    }

    .filter__placeholder {
      color: #818181;
      outline: none;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 24px;
      text-transform: uppercase;
    }

    .filter__single-value {
      color: #F1F0EE;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 24px;
      text-transform: uppercase;
    }

    .filter__indicator-separator {
      display: none;
    }

    .filter__indicators, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .filter__control.filter__control--is-focused {
    border-color: #f1f0ee !important;

    .filter__indicator {
      svg {
        line {
          stroke: #f1f0ee !important;;
        }
      }
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }




`;

export default React.memo(SelectField);














