export const ApiParam = {
    type: 'type',
    slug: 'slug',
    division_id : 'division',
    city_id : 'city',
    thana_id : 'area',
    keyword: 'title',
    cat: 'cat',
    size: 'size',
    page: 'page',
    material: 'material',
    usagearea: 'usage-area',
    origin: 'origin',
    KEYWORD : 'title',
    finish : 'finish',
    brand : 'brand',
    light_type : 'light_type',
};
