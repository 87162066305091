import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Img} from "./Img";
import {Link} from "react-router-dom";
import {OnlyImg} from "../OnlyImg";

const BrandBox = ({img, title, link, logo, type, location}) => {

    return (
        <StyledProjectBox>
            <div className="wrapper hoverable get_height">
                <Link to={link}>
                    <div className="all-merge">
                        <div className="item-image-slide">
                            <div className="slide_1 img-wrapper">
                                <Img src={img ? img : "/images/dynamic/brand1.jpg"}/>
                                <OnlyImg classname={'logo-brand'} src={logo ? logo : ""} alt=""/>

                            </div>

                        </div>
                        <div className="content">
                            <h4>{title ? title : ''}</h4>
                        </div>
                    </div>
                </Link>
            </div>
        </StyledProjectBox>

    )
};


const StyledProjectBox = styled.div`

  .wrapper {
    .all-merge {
      //overflow: hidden;
      .logo-brand {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) ;
        z-index: 3;
        opacity: 0;
        visibility: hidden;
        transition: 0.7s all cubic-bezier(.74,0,.24,.99);
        height: 50px;
      }
      .item-image-slide {
        padding-top: calc(420 / 470 * 100%);
        position: relative;
        //overflow: hidden;

        &:after {
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          inset: 0;
          z-index: 1;
          content: '';
          transition: 0.7s all cubic-bezier(.74,0,.24,.99);

        }


      
      }

      .content {
        text-align: center;
        margin-top: 40px;
        transition: 0.7s all cubic-bezier(.74,0,.24,.99);

        h4 {
          color: #F1F0EE;
        }
      }

    }
  }




`;

export default React.memo(BrandBox);














