import React from 'react';
import styled from "styled-components";

const MenuClose = () => {
    return (
        <StyledMenuClose>
            <svg xmlns="http://www.w3.org/2000/svg" width="63.496" height="17" viewBox="0 0 63.496 17">
                <g id="Group_18761" data-name="Group 18761" transform="translate(-97.646 -32)">
                    <text id="Close" transform="translate(124.143 46)" fill="#f1f0ee" fontSize="15" fontFamily="BananaGrotesk-Semibold, Banana Grotesk" fontWeight="600" letterSpacing="-0.05em"><tspan x="0" y="0">Close</tspan></text>
                    <g id="Group_18751" data-name="Group 18751" transform="translate(-2.5)">
                        <line id="Line_1" data-name="Line 1" x2="20" transform="translate(100.5 33.5) rotate(45)" fill="none" stroke="#f1f0ee" strokeWidth="1"/>
                        <line id="Line_2" data-name="Line 2" x2="20" transform="translate(114.643 33.5) rotate(135)" fill="none" stroke="#f1f0ee" strokeWidth="1"/>
                    </g>
                </g>
            </svg>

        </StyledMenuClose>
    );
};

const StyledMenuClose = styled.div`


`;

export default React.memo(MenuClose);
