import React, {useState, useEffect, useRef, memo} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "./Button";
import {toast} from "react-toastify";
import {Img} from "./Img";
import {useForm} from "react-hook-form";
import {postForm} from "../api/redux/product";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../api/network/apiEndPoints";

const ProductForm = ({padding, data, id}) => {


    const dispatch = useDispatch();
    const responseData = useSelector(state => state.PRODUCT);

    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    // --- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };


    const onSubmit = (e) => {

        let api_services = apiEndPoints.PRODUCT_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.phone);
        formData.append('product', data);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error?.message)
        }
        if (responseData && responseData?.success !== '') {
            success(responseData?.success)

        }
        reset();

    }, [responseData])


    return (<StyledListWithForm id={`${id ? id : 'ListWithForm'}`}
                                className={`list_with_form ${padding ? padding : 'pt-200 pb-200'} `}>
        <Container fluid>
            <Row>

                <Col className={'p-0'} md={{span: 8, offset: 2}}>
                    <div className="text-center">
                        <h3 className={'split-text'}>Get update on the product's  <br/>arrival</h3>

                    </div>
                    <div className="form_wrapper">
                        <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>

                            <input name={'spam_protector'} type='hidden'/>
                            <input name={'form_id'} value={'contact-form'} type='hidden'/>
                            <div className="form-group">
                                <Form.Group controlId="formBasicEmail">

                                    <Form.Control
                                        className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("name", {
                                            required: 'Name is required',
                                        })}
                                        type="text"
                                        placeholder="Name*"
                                    />
                                </Form.Group>
                            </div>
                            <Row>
                                <Col md={6}>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicPhone">

                                            <Form.Control
                                                className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("phone", {
                                                    required: {
                                                        value: true, message: 'please enter your phone first'
                                                    }, pattern: {
                                                        value: /^01[0-9]{9}$/,
                                                        message: 'please enter a valid 11 digit phone number'
                                                    }
                                                })}
                                                type="number"
                                                placeholder="Phone Number*"/>
                                        </Form.Group>
                                    </div>


                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">

                                            <Form.Control
                                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("email", {
                                                    required: {
                                                        value: true, message: 'please enter your email'
                                                    }, pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: 'please enter a valid email address'
                                                    }
                                                })}
                                                type="email"
                                                placeholder="Email*"/>
                                        </Form.Group>
                                    </div>

                                </Col>
                            </Row>
                            <div className="form-group lock">
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                        className={formState?.errors?.name?.message ? 'has-error form-control-lg ' : 'form-control-lg '}
                                        placeholder={data}
                                        value={data}
                                        type="text"
                                    />
                                    <div className="lock-svg">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.909" height="17" viewBox="0 0 13.909 17">
                                            <path id="Path_9012" data-name="Path 9012" d="M50.119,8.065a1.118,1.118,0,0,0-.821-.338h-.386V5.409a5.2,5.2,0,0,0-1.594-3.815,5.364,5.364,0,0,0-7.631,0,5.205,5.205,0,0,0-1.594,3.815V7.727h-.386a1.154,1.154,0,0,0-1.159,1.159v6.954A1.154,1.154,0,0,0,37.707,17H49.3a1.154,1.154,0,0,0,1.159-1.159V8.886A1.117,1.117,0,0,0,50.119,8.065Zm-3.525-.338H40.412V5.409a2.978,2.978,0,0,1,.906-2.185,3.09,3.09,0,0,1,4.371,0,2.978,2.978,0,0,1,.905,2.185Z" transform="translate(-36.548)" fill="#f1f0ee"/>
                                        </svg>

                                    </div>
                                </Form.Group>
                            </div>


                            <div className={`form-group text-center d-flex justify-content-center`}>
                                <div onClick={handleSubmit(onSubmit, onError)}>
                                    <Button color={'#F1F0EE'} borderColor={'#F1F0EE'} text={'Submit'}/>
                                </div>

                            </div>

                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    </StyledListWithForm>)
};

const StyledListWithForm = styled.div`

  .form_wrapper {
    padding: 60px;
    height: 100%;
    position: relative;
    // overview_bg

    .left-col {
      div {
        height: 100%;
      }
    }

    @media (max-width: 992px) and (min-width: 768px) {
      padding: 30px;
    }

    form {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
    }

    .textarea {
      min-height: 120px;
    }

    .title {
      font-size: 24px;
      font-weight: 4000;
      line-height: 28px;
      color: #F9F9F9;
      margin: 0 0 40px;
    }

    .form-group {
      margin-bottom: 40px;
      position: relative;
      .lock-svg{
        position: absolute;
        right: 0;
        top:0;
      }
      &:last-child {
        margin-bottom: 0;
      }

      svg {
        display: block;
      }

      .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;
        background: transparent;
        height: auto;
        border-left: none;
        border-right: none;
        border-top: none;
        border-color: rgba(241, 238, 233, 0.5);
        padding: 0 0 8px;
        color: #F1EEE9;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        &.lock{
          
        }
        svg {
          display: block;
        }

        &::placeholder {
          color: rgba(241, 238, 233, 0.5);
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }


  }

  .row{
    .form-group{
      margin-bottom: 40px !important;
    }
  }

  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
    .left-col {
      min-height: 60vh;
    }

    .form_wrapper {
      padding: 60px 15px;
      margin-top: 0px;
    }

    .left-col {
      padding-right: 0px;
    }

    .container {
      padding: 0;
    }

    .width-fit {
      display: flex;
    }
  }
`;

export default React.memo(ProductForm);
