import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Internal Component
import {Img} from "./Img";
import {Col, Container, Row} from "react-bootstrap";
import Button from "./Button";
import {useSelector} from "react-redux";
import reactHtmlParser from "react-html-parser";

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import {hover} from "../styles/globalStyleVars";
import {CircleFlag} from "react-circle-flags";

function SlickNextArrow(props) {
    const {className, style, onClick} = props;


    return (
        <div
            className={className}
            style={{...style}}
            onClick={onClick}
        >
            <svg id="Component_94_1" data-name="Component 94 – 1" xmlns="http://www.w3.org/2000/svg" width="50"
                 height="50" viewBox="0 0 50 50">
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="25" cy="25" r="25" fill="#221f1f"/>
                <circle id="Ellipse_2" data-name="Ellipse 2" cx="2" cy="2" r="2" transform="translate(23 23)"
                        fill="#1E5C95" opacity="0"/>
                <g id="Group_5988" data-name="Group 5988" transform="translate(23 20)">
                    <line id="Line_366" data-name="Line 366" x2="5" y2="5" fill="none" stroke="#fff"
                          strokeLinecap="round" strokeWidth="1"/>
                    <line id="Line_367" data-name="Line 367" y1="5" x2="5" transform="translate(0 5)" fill="none"
                          stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                </g>
            </svg>

        </div>
    );
}

function SlickPrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style}}
            onClick={onClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <g id="Component_94_2" data-name="Component 94 – 2" transform="translate(50 50) rotate(180)">
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="25" cy="25" r="25" fill="#221f1f"/>
                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="2" cy="2" r="2" transform="translate(23 23)"
                            fill="#1E5C95" opacity="0"/>
                    <g id="Group_5988" data-name="Group 5988" transform="translate(23 20)">
                        <line id="Line_366" data-name="Line 366" x2="5" y2="5" fill="none" stroke="#fff"
                              strokeLinecap="round" strokeWidth="1"/>
                        <line id="Line_367" data-name="Line 367" y1="5" x2="5" transform="translate(0 5)" fill="none"
                              stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                    </g>
                </g>
            </svg>
        </div>
    );
}


const ProductInfo = ({data,type}) => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [filterColor, setFilterColor] = useState('')
    const [filterColorName, setFilterColorName] = useState('')
    const [fakeLoader, setFakeLoader] = useState(false)
    const DetailData = data


    let [shareUrl, setShareUrl] = useState('')


    let shareButtonClickM = useRef()
    let shareButtonContentM = useRef()
    let shareToggle = useRef()

    useEffect(() => {
        setShareUrl(window.location.href)
    }, [shareUrl])



    // filter gallery
    const FilterGallery = DetailData?.project_product_details_group?.find(f => f?.color === filterColor);
    const MapGallery = FilterGallery ? FilterGallery : DetailData?.project_product_details_group?.[0]
    const HandleColor = (e, i) => {
        setFilterColor(e)
        setFilterColorName(i)
        setFakeLoader(true)
        setTimeout(() => {
            setFakeLoader(false)
        }, 600)
    }

    useEffect(() => {
        setFilterColor(MapGallery?.color)

        if(  type === 'furniture' || type === 'lights'){
            setFilterColorName(MapGallery?.color)

        }else{
            setFilterColorName(MapGallery?.color_name)

        }
    }, [MapGallery])





    return (
        <>
            <StyledProductInfo className={'detail'}>
                <Container>
                    <Row>
                        <Col sm={6} className={'pr-0'}>
                            {MapGallery?.gallery?.length > 0 ?
                                <Slider
                                    asNavFor={nav2}
                                    ref={(slider1) => setNav1(slider1)}
                                    // nextArrow={<SlickNextArrow/>}
                                    // prevArrow={<SlickPrevArrow/>}
                                    infinite={false}
                                    // fade={true}
                                    speed={1200}
                                    autoPlay={true}
                                >
                                    {MapGallery?.gallery?.length > 0 && MapGallery?.gallery?.map((item, index) => (
                                        <div key={index} className={'ProductSlider__single_item'}>
                                            <Img src={item?.img_url} objectFit={'contain'} layout={'fill'}/>
                                        </div>
                                    ))}


                                </Slider>
                                :

                                <div key={DetailData?.ID} className={'ProductSlider__single_item'}>
                                    <Img src={DetailData?.project_thumb} objectFit={'contain'} layout={'fill'}/>
                                </div>

                            }

                            {MapGallery?.gallery?.length > 1 &&
                                <Slider
                                    asNavFor={nav1}
                                    ref={(slider2) => setNav2(slider2)}
                                    slidesToShow={3}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                    nextArrow={<SlickNextArrow/>}
                                    prevArrow={<SlickPrevArrow/>}
                                    arrows={true}
                                    speed={1200}
                                    autoPlay={true}
                                    infinite={false}
                                    className={'ProductThumbSlider'}
                                >
                                    {MapGallery?.gallery?.length > 0 && MapGallery?.gallery?.map((item, index) => (
                                        <div key={index} className={'ProductThumbSlider__single_item'}>
                                            <Img src={item?.img_url}/>
                                        </div>
                                    ))}
                                </Slider>
                            }
                        </Col>

                        {/*description*/}
                        <Col sm={{span: 5, offset: 1}} className={'pl-0 detail__desc'}>
                            <div className="share_wrapper d-flex">
                                <p className="meta-font">Share:</p>
                                <div className="social-lists">
                                    <EmailShareButton url={shareUrl}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                             viewBox="0 0 14 14">
                                            <defs>
                                                <clipPath id="clip-path">
                                                    <rect id="Rectangle_6283" data-name="Rectangle 6283"
                                                          width="14" height="14"
                                                          transform="translate(14289 121)" fill="#fff"/>
                                                </clipPath>
                                            </defs>
                                            <g id="Mask_Group_19945" data-name="Mask Group 19945"
                                               transform="translate(-14289 -121)" clipPath="url(#clip-path)">
                                                <g id="Icon_feather-link-2" data-name="Icon feather-link-2"
                                                   transform="translate(14289.616 130.128) rotate(-45)">
                                                    <path id="Path_173" data-name="Path 173"
                                                          d="M7.66,0H9.3a2.882,2.882,0,0,1,2.736,3.009A2.882,2.882,0,0,1,9.3,6.018H7.66m-3.283,0H2.736A2.882,2.882,0,0,1,0,3.009,2.882,2.882,0,0,1,2.736,0H4.377"
                                                          fill="none" stroke="#f1f0ee" strokeLinecap="round"
                                                          strokeLinejoin="round" strokeWidth="1.2"/>
                                                    <path id="Path_174" data-name="Path 174" d="M0,0H5.1"
                                                          transform="translate(3.467 3.009)" fill="none"
                                                          stroke="#f1f0ee" strokeLinecap="round"
                                                          strokeLinejoin="round" strokeWidth="1.2"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </EmailShareButton>
                                    <FacebookShareButton url={shareUrl}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                             viewBox="0 0 14 14">
                                            <defs>
                                                <clipPath id="clip-path">
                                                    <rect id="Rectangle_6282" data-name="Rectangle 6282"
                                                          width="14" height="14"
                                                          transform="translate(14289 121)" fill="#fff"/>
                                                </clipPath>
                                            </defs>
                                            <g id="Mask_Group_19944" data-name="Mask Group 19944"
                                               transform="translate(-14289 -121)" clipPath="url(#clip-path)">
                                                <path id="Icon_awesome-facebook-f"
                                                      data-name="Icon awesome-facebook-f"
                                                      d="M8.032,7.088l.356-2.28H6.16V3.327A1.151,1.151,0,0,1,7.469,2.1H8.482V.154A12.575,12.575,0,0,0,6.684,0,2.809,2.809,0,0,0,3.649,3.069V4.807H1.609v2.28h2.04V12.6H6.16V7.088Z"
                                                      transform="translate(14290.955 121.7)" fill="#f1f0ee"/>
                                            </g>
                                        </svg>
                                    </FacebookShareButton>
                                    <LinkedinShareButton url={shareUrl}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                             viewBox="0 0 14 14">
                                            <defs>
                                                <clipPath id="clip-path">
                                                    <rect id="Rectangle_6281" data-name="Rectangle 6281"
                                                          width="14" height="14"
                                                          transform="translate(14289 121)" fill="#fff"/>
                                                </clipPath>
                                            </defs>
                                            <g id="Mask_Group_19943" data-name="Mask Group 19943"
                                               transform="translate(-14289 -121)" clipPath="url(#clip-path)">
                                                <g id="_x31_0.Linkedin"
                                                   transform="translate(14289.663 121.662)">
                                                    <path id="Path_10758" data-name="Path 10758"
                                                          d="M46.491,43.967V39.324c0-2.282-.491-4.024-3.153-4.024a2.751,2.751,0,0,0-2.487,1.363h-.032V35.506H38.3v8.461h2.63v-4.2c0-1.109.206-2.171,1.569-2.171,1.347,0,1.363,1.252,1.363,2.234v4.119h2.63Z"
                                                          transform="translate(-33.816 -31.292)"
                                                          fill="#f1f0ee"/>
                                                    <path id="Path_10759" data-name="Path 10759"
                                                          d="M11.3,36.6h2.63v8.461H11.3Z"
                                                          transform="translate(-11.094 -32.386)"
                                                          fill="#f1f0ee"/>
                                                    <path id="Path_10760" data-name="Path 10760"
                                                          d="M11.521,10a1.529,1.529,0,1,0,1.521,1.521A1.521,1.521,0,0,0,11.521,10Z"
                                                          transform="translate(-10 -10)" fill="#f1f0ee"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </LinkedinShareButton>
                                    <TwitterShareButton url={shareUrl}>

                                        <svg id="layer1" xmlns="http://www.w3.org/2000/svg" width="11.035"
                                             height="10.328" viewBox="0 0 11.035 10.328">
                                            <path id="path1009"
                                                  d="M281.527,167.31l4.26,5.7-4.287,4.632h.965l3.754-4.055,3.033,4.055h3.284l-4.5-6.017,3.991-4.311h-.965l-3.457,3.735-2.793-3.735Zm1.419.711h1.509l6.661,8.907h-1.509Z"
                                                  transform="translate(-281.5 -167.31)" fill="#f1f0ee"/>
                                        </svg>

                                    </TwitterShareButton>

                                </div>
                            </div>
                            <div className="detail__desc__title">
                                <h5>{DetailData?.post_title} </h5>
                                <p className={'meta-font'}>{DetailData?.product_size}</p>
                            </div>

                            {DetailData?.project_product_details_group?.length > 0 &&
                                <div className="detail__desc__color">
                                    <p className={'meta-font'}>{type === 'furniture' || type === 'lights' ? 'Country' : 'Color'}: <span>{filterColorName}</span></p>
                                    <ul>
                                        {
                                            type === 'furniture' || type === 'lights' ?
                                                DetailData?.project_product_details_group?.map((color, index) => (

                                                    <li onClick={() => HandleColor(color?.color, color?.color_name)}
                                                        key={index}
                                                        style={{backgroundColor: `${color?.color}`}}
                                                        className={filterColor === color?.color ? 'active flag' : 'flag'}>
                                                        <CircleFlag countryCode={`${color?.color_name}`} />
                                                    </li>
                                                ))
                                                :
                                                DetailData?.project_product_details_group?.map((color, index) => (
                                                    <li onClick={() => HandleColor(color?.color, color?.color_name)}
                                                        key={index}
                                                        style={{backgroundColor: `${color?.color}`}}
                                                        className={filterColor === color?.color ? 'active' : ''}/>
                                                ))

                                        }

                                        {/*<li style={{backgroundColor: '#AE9B63'}} className={'active'}/>*/}
                                        {/*<li style={{backgroundColor: '#F0D1BF'}}/>*/}
                                        {/*<li style={{backgroundColor: '#FFF'}}/>*/}
                                        {/*<li style={{backgroundColor: '#BF8773'}}/>*/}


                                    </ul>
                                </div>
                            }
                            <div className="detail__desc__spec">

                                <p>{reactHtmlParser(DetailData?.description)}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledProductInfo>
        </>

    );
};

const StyledProductInfo = styled.section`
  padding-top: 130px;
  padding-bottom: 60px;

  .share_wrapper {
    position: absolute;
    right: 0;
    top: 0;
    @media(max-width: 767px){
      right: 15px;
    }
    @media(max-width: 550px){
      position: relative;
      padding-left: 15px;
      margin-bottom: 15px;
    }
    
    display: flex;
    align-items: flex-start;

    .meta-font{
      margin-right: 7px;
      line-height: 19px;
    }
    
    .social-lists {
      display: flex;
      gap: 7px;
      align-items: flex-start;

      &.open {
        opacity: 1;
        visibility: visible;
        height: 100%;
      }

      transition: 0.7s all cubic-bezier(.74,0,.24,.99);
      flex-direction: row;
      .react-share__ShareButton {
        margin-bottom: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }



  .ProductSlider {
    &__single_item {
      position: relative;
      padding-top: calc(428 / 600 * 100%);

      img {
        object-fit: cover;
      }
    }
  }

  .slick-arrow {
    z-index: 1;
    width: 50px;
    height: 50px;

    &:before {
      content: none;
    }

    &.slick-prev {
      left: 15px;

      &.slick-disabled {
        svg {
          opacity: .5;
        }

        pointer-events: none;
      }
    }

    &.slick-next {
      right: 5px;

      &.slick-disabled {
        svg {
          opacity: .5;
        }

        pointer-events: none;
      }
    }

    svg {
      circle {
        &:nth-of-type(2) {
          cx: 0;
          cy: 0;
          r: 0;
          opacity: 0;
          transform: translate(25, 25);
          transition: all cubic-bezier(.54,.32,.05,.96) .2s;
        }
      }
    }

    &:hover {
      svg {
        circle {
          &:nth-of-type(2) {
            cx: 25;
            cy: 25;
            r: 25;
            opacity: 1;
            transform: translate(0, 0);
          }
        }
      }
    }
  }

  .ProductThumbSlider {
    margin-top: 13px;
    margin-left: -10px;
    margin-right: -10px;

    .slick-slide {
      padding: 0 10px;
    }

    &__single_item {
      position: relative;
      padding-top: calc(120 / 175 * 100%);
    }
  }

  .detail__desc {
    &__title {
        h5{
          margin-bottom: 10px;
          color: #F1F0EE;
        }
     
    }
    .meta-font{
      color: #818181;
      span{
        color: #F1F0EE !important;
        margin-left: 8px;
      }
    }

    &__color {
      margin-top: 30px;
      padding: 26px 0;
      border-top: 1px solid #818181;
      border-bottom: 1px solid #818181;
      display: flex;
      justify-content: space-between;
      align-items: center;


      ul {
        display: flex;

        li {
          height: 28px;
          width: 28px;
          border-radius: 50%;
          cursor: pointer;
          position: relative;
          border: 1px solid #B8B8B8;

          &.flag{
            border: none !important;
            img{
              position: absolute;
              inset:0;
              height: 28px;
              width: 28px;
              border-radius: 50%;
              border: 1px solid #B8B8B8;

            }
          }
          &:not(:nth-last-of-type(1)) {
            margin-right: 12px;
          }

          &.active.flag{
            background: transparent !important;
            img{
              border: 2px solid ${hover};
            }
            border: none !important;
          }
          &.active {
            
            border: 2px solid ${hover};
          }
        }
      }
    }

    &__spec {
      margin-top: 30px;
      p{
        font-size: 1rem; /* 15px / 15px = 1rem */
        font-weight: 600;
        line-height: 1rem; /* 20px / 15px = 1.3333rem */
        letter-spacing: -0.05rem;
        margin-bottom: 15px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }

  }

  @media (max-width: 991px) {
    padding-top: 60px;
    .col-sm-6 {
      min-width: 100%;
      padding-right: 15px !important;
    }

    .col-sm-5 {
      min-width: 100%;
      padding-left: 15px !important;
      margin: 0;
    }

    .detail__desc {
      margin-top: 40px;
    }
  }

  @media (max-width: 600px) {
    .detail__desc__color {
      flex-wrap: wrap;

      h3 {
        min-width: 100%;
      }

      ul {
        min-width: 100%;
        margin-top: 20px;

        li:not(:nth-last-of-type(1)) {
          margin-right: 20px;
        }
      }
    }
  }


  .slick-arrow {
    opacity: 0 !important;
    transition: opacity .4s cubic-bezier(.54,.32,.05,.96);
    //visibility: hidden;

    svg {
      height: 40px;
      width: 40px;
    }
  }

  .slick-slider:hover {
    .slick-arrow {
      opacity: 1 !important;
      //visibility: visible;
    }
  }

  @media (max-width: 767px) {
    .slick-arrow {
      opacity: 1 !important;
      height: 30px;
      width: 30px;

      svg {
        height: 30px;
        width: 30px;
      }
    }

    .slick-arrow.slick-next {
      right: 15px;
    }
  }


`;

export default ProductInfo;
