import React from 'react';
import styled from 'styled-components';
import {hover, medium, text} from "../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const Social = () => {


    return (

        <StyledSocial>
            <ul className={'social'}>
                <li><a href={'https://www.facebook.com/TradeIntercontinental/'}
                       target={'_blank'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                        <g id="Group_19953" data-name="Group 19953" transform="translate(-398 -664)">
                            <circle id="Ellipse_447" data-name="Ellipse 447" cx="12.5" cy="12.5" r="12.5"
                                    transform="translate(398 664)" fill="#f1f0ee"/>
                            <circle className={'hover_circle'} data-name="Ellipse 594" cx="12.5" cy="12.5" r="0"
                                    transform="translate(398 664)" fill={hover}/>
                            <path id="Path_2115" data-name="Path 2115"
                                  d="M1204.9,102.967l.339-2.21h-2.12V99.323a1.1,1.1,0,0,1,1.246-1.194h.964V96.248a11.752,11.752,0,0,0-1.711-.149,2.7,2.7,0,0,0-2.887,2.974v1.684h-1.941v2.21h1.941v5.341h2.388v-5.341Z"
                                  transform="translate(-791.62 574.567)" fill="#1a181b"/>
                        </g>
                    </svg>


                </a>
                </li>
                <li><a href={''}
                       target={'_blank'}>


                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                        <g id="Group_19952" data-name="Group 19952" transform="translate(-438 -664)">
                            <circle id="Ellipse_93" data-name="Ellipse 93" cx="12.5" cy="12.5" r="12.5"
                                    transform="translate(438 664)" fill="#f1f0ee"/>
                            <circle className={'hover_circle'} data-name="Ellipse 594" cx="12.5" cy="12.5" r="0"
                                    transform="translate(438 664)" fill={hover}/>

                            <g id="layer1" transform="translate(444.667 671.5)">
                                <path id="path1009"
                                      d="M281.527,167.31l4.26,5.7-4.287,4.632h.965l3.754-4.055,3.033,4.055h3.284l-4.5-6.017,3.991-4.311h-.965l-3.457,3.735-2.793-3.735Zm1.419.711h1.509l6.661,8.907h-1.509Z"
                                      transform="translate(-281.5 -167.31)" fill="#1a181b"/>
                            </g>
                        </g>
                    </svg>


                </a>
                </li>
                <li><a href={'https://instagram.com/trade_interncontinental?igshid=NjIwNzIyMDk2Mg=='}
                       target={'_blank'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                        <g id="Group_19951" data-name="Group 19951" transform="translate(-478 -664)">
                            <circle id="Ellipse_98" data-name="Ellipse 98" cx="12.5" cy="12.5" r="12.5"
                                    transform="translate(478 664)" fill="#f1f0ee"/>
                            <circle className={'hover_circle'} data-name="Ellipse 594" cx="12.5" cy="12.5" r="0"
                                    transform="translate(478 664)" fill={hover}/>

                            <g id="Group_18729" data-name="Group 18729" transform="translate(484.667 670.667)">
                                <path id="Path_2109" data-name="Path 2109"
                                      d="M1095.627,105.945a.711.711,0,1,0,.711.712A.711.711,0,0,0,1095.627,105.945Z"
                                      transform="translate(-1086.349 -103.779)" fill="#1a181b"/>
                                <path id="Path_2110" data-name="Path 2110"
                                      d="M1082.043,108.605a2.988,2.988,0,1,0,2.988,2.988A2.992,2.992,0,0,0,1082.043,108.605Zm0,4.9a1.914,1.914,0,1,1,1.914-1.914A1.916,1.916,0,0,1,1082.043,113.507Z"
                                      transform="translate(-1075.927 -105.527)" fill="#1a181b"/>
                                <path id="Path_2111" data-name="Path 2111"
                                      d="M1078.421,111.761h-4.844a3.649,3.649,0,0,1-3.645-3.645v-4.844a3.649,3.649,0,0,1,3.645-3.644h4.844a3.649,3.649,0,0,1,3.645,3.644v4.844A3.649,3.649,0,0,1,1078.421,111.761Zm-4.844-10.992a2.506,2.506,0,0,0-2.5,2.5v4.844a2.506,2.506,0,0,0,2.5,2.5h4.844a2.506,2.506,0,0,0,2.5-2.5v-4.844a2.506,2.506,0,0,0-2.5-2.5Z"
                                      transform="translate(-1069.932 -99.628)" fill="#1a181b"/>
                            </g>
                        </g>
                    </svg>


                </a>
                </li>
                <li><a href={''}
                       target={'_blank'}>

                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                        <g id="Group_19950" data-name="Group 19950" transform="translate(-518 -664)">
                            <circle id="Ellipse_99" data-name="Ellipse 99" cx="12.5" cy="12.5" r="12.5"
                                    transform="translate(518 664)" fill="#f1f0ee"/>
                            <circle className={'hover_circle'} data-name="Ellipse 594" cx="12.5" cy="12.5" r="0"
                                    transform="translate(518 664)" fill={hover}/>

                            <path id="Path_2114" data-name="Path 2114"
                                  d="M1144.76,112.662a2.345,2.345,0,0,0-2.345-2.345h-6a2.345,2.345,0,0,0-2.344,2.345v2.79a2.345,2.345,0,0,0,2.344,2.345h6a2.345,2.345,0,0,0,2.345-2.345Zm-3.526,1.6-2.689,1.33c-.105.057-.463-.019-.463-.139v-2.73c0-.121.361-.2.466-.137l2.574,1.4C1141.23,114.051,1141.343,114.207,1141.234,114.266Z"
                                  transform="translate(-608.575 562.85)" fill="#1a181b"/>
                        </g>
                    </svg>


                </a></li>
                <li>
                    <a href={'https://www.linkedin.com/company/trade-intercontinental/'} target={'_blank'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                            <g id="Group_19949" data-name="Group 19949" transform="translate(-558 -664)">
                                <circle id="Ellipse_594" data-name="Ellipse 594" cx="12.5" cy="12.5" r="12.5"
                                        transform="translate(558 664)" fill="#f1f0ee"/>
                                <circle className={'hover_circle'} data-name="Ellipse 594" cx="12.5" cy="12.5" r="0"
                                        transform="translate(558 664)" fill={hover}/>
                                <g id="_x31_0.Linkedin" transform="translate(565.5 671.5)">
                                    <path id="Path_2123" data-name="Path 2123"
                                          d="M44.965,42.352V38.575c0-1.856-.4-3.275-2.565-3.275a2.239,2.239,0,0,0-2.024,1.109H40.35v-.941H38.3v6.884h2.14V38.936c0-.9.168-1.766,1.276-1.766s1.109,1.018,1.109,1.818v3.352h2.14Z"
                                          transform="translate(-34.652 -32.038)" fill="#1a181b"/>
                                    <path id="Path_2124" data-name="Path 2124" d="M11.3,36.6h2.14v6.884H11.3Z"
                                          transform="translate(-11.132 -33.171)" fill="#1a181b"/>
                                    <path id="Path_2125" data-name="Path 2125"
                                          d="M11.238,10a1.244,1.244,0,1,0,1.238,1.238A1.238,1.238,0,0,0,11.238,10Z"
                                          transform="translate(-10 -10)" fill="#1a181b"/>
                                </g>
                            </g>
                        </svg>

                    </a>
                </li>
            </ul>
        </StyledSocial>

    )
};


const StyledSocial = styled.div`
  margin-bottom: 0px;

  .social {
    display: flex;
    flex-direction: row;
    gap: 20px;
    li {
      list-style: none !important;

      &:after {
        display: none !important;
      }

      &:before {
        display: none !important;
      }

      a {
        &:after {
          display: none !important;
        }

        &:before {
          display: none !important;
        }
      }
    }

    svg {
      overflow: hidden;
      border-radius: 50%;

      .hover_circle, path {
        transition: 0.5s all cubic-bezier(.25, .74, .22, .99);
      }
    }

    li {
      margin-left: 0px;
      margin-bottom: 0 !important;

      &:first-child {
        margin-left: 0;
      }

      a {
        &:hover {
          svg {
            .hover_circle {
              r: 12.5px;
            }

            path {
              fill: ${text};
            }


          }
        }
      }
    }
  }

  @media (max-width: 992px) and (min-width: 767px) {
    .social {
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 20px;

      li {
       margin-left: 0;
      }
    }
  }
`;

export default React.memo(Social);














