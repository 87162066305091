import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import InsightBoxHover from "../InsightBoxHover";
import reactHtmlParser from "react-html-parser";


const OverviewInner = ({padding, data}) => {

    const space = parseInt(data?.space)
    const NonBreakingSpaces = ({count}) => {
        const nonBreakingSpaces = '\u00A0'.repeat(count); // '\u00A0' is the HTML entity for a non-breaking space
        return <span dangerouslySetInnerHTML={{__html: nonBreakingSpaces}}/>;
    };

    return (
        <StyledOverviewInner className={`about_overview ${padding ? padding : 'pt-200 pb-200'} `}>


            {
                data?.title &&
                <Container>
                    <Row>
                        <Col md={{span: 10, offset: 1}}>
                            <div className="title-wrp">
                                <h3 data-lag={0.1}>
                                    <NonBreakingSpaces count={space}/>
                                    {

                                        data?.title &&
                                        reactHtmlParser(data?.title)
                                    }</h3></div>
                        </Col>

                        <Col md={{span: 7, offset: 5}}>
                            <div className="content">


                                {
                                    reactHtmlParser(data?.left_desc)
                                }
                                {
                                    reactHtmlParser( data?.right_desc)
                                }
                            </div>

                        </Col>


                    </Row>
                </Container>
            }

            {
                data?.list && data?.list?.length > 0 &&
                <Container id={'features'}>
                    <Row>
                        <Col md={{span: 10, offset: 1}} >
                            <Row>
                                {
                                    data?.list && data?.list?.length > 0 &&
                                    data?.list?.map((e,index) => {
                                        return(
                                            <Col md={6} data-lag={0.3} key={index} className={'single-brand'}>
                                                <InsightBoxHover data={e}/>
                                            </Col>
                                        )
                                    })
                                }


                            </Row>
                        </Col>
                    </Row>
                </Container>

            }
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;

  .title-wrp {
    margin-bottom: 80px;
  }



  .content {
    margin-bottom: 200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;

    p {
      width: calc(50% - 15px);
      flex: 0 0 calc(50% - 15px);
      color: #818181;
    }
  }

  .single-brand{
    margin-bottom: 70px;
    
    &:nth-of-type(even){
      transform: translateY(70px);
    }
  }
  @media (max-width: 767px) {

    .single-brand{
      margin-bottom: 40px;

      &:last-child{
        margin-bottom: 0;
      }
      &:nth-of-type(even){
        transform: translateY(0) !important;
      }
    }
    .content {
      margin-bottom: 60px;
      width: 75%;
      flex-direction: column;
      p{
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }
`;


export default React.memo(OverviewInner);
