import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from '../components/Button';
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {postForm} from "../api/redux/home";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../api/network/apiEndPoints";

const PeoplePopup = ({
                         show,
                         handleClose,
                         no_img,
                         item,
                         title,
                         description,
                         data,
                         subtitle,
                         img
                     }) => {


    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])


    const dispatch = useDispatch();
    const responseData = useSelector(state => state.home);

    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };


    const onSubmit = (e) => {

        let api_services = apiEndPoints.BOOK_A_CALL;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.phone);
        formData.append('your-message', e?.message);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            // success('Successfully Submitted')
            reset();
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error?.message)
        }
        if (responseData && responseData?.success !== '') {
            success(responseData?.success)

        }
        reset();

    }, [responseData])






    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gph_modal popup-version-one CareerModal"
            >
                <div className="noise"></div>

                <div className="main_scroll" style={{height: '100vh'}}>
                    <Modal.Body>

                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleClose} className="modal-close ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="62.496" height="15.854"
                                         viewBox="0 0 62.496 15.854">
                                        <g id="Group_18761" data-name="Group 18761"
                                           transform="translate(-97.646 -33.146)">
                                            <text id="Close" transform="translate(124.143 46)" fill="#f1f0ee"
                                                  fontSize="15" fontFamily="Helvetica" letterSpacing="-0.05em">
                                                <tspan x="0" y="0">Close</tspan>
                                            </text>
                                            <g id="Group_18751" data-name="Group 18751" transform="translate(-2.5)">
                                                <line id="Line_1" data-name="Line 1" x2="20"
                                                      transform="translate(100.5 33.5) rotate(45)" fill="none"
                                                      stroke="#f1f0ee" strokeWidth="1"/>
                                                <line id="Line_2" data-name="Line 2" x2="20"
                                                      transform="translate(114.643 33.5) rotate(135)" fill="none"
                                                      stroke="#f1f0ee" strokeWidth="1"/>
                                            </g>
                                        </g>
                                    </svg>

                                </div>

                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col className={'p-0'} md={{span: 8, offset: 2}}>
                                    <div className="d-flex modal-title-wrapper justify-content-between">
                                        <h3 className={'split-text'}>Book a call with <br/>
                                            our experts</h3>

                                        <div className="buttons-wrapper">
                                            <a href={"tel:+8801773253467"}>
                                                <div className="icon">
                                                    <svg id="Group_23649" data-name="Group 23649"
                                                         xmlns="http://www.w3.org/2000/svg" width="24.495"
                                                         height="26.282" viewBox="0 0 24.495 26.282">
                                                        <path id="Path_10757" data-name="Path 10757"
                                                              d="M22.623,18.292c-1.588-1.358-3.2-2.181-4.767-.825l-.936.819c-.685.595-1.959,3.374-6.884-2.291S8.041,9.456,8.727,8.867l.942-.821c1.56-1.359.971-3.07-.154-4.831L8.836,2.149C7.706.392,6.475-.762,4.911.595l-.845.738A7.933,7.933,0,0,0,.974,6.584c-.564,3.731,1.215,8,5.292,12.692s8.058,7.046,11.835,7.005a7.955,7.955,0,0,0,5.627-2.33l.848-.739c1.56-1.356.591-2.736-1-4.1Z"
                                                              transform="translate(-0.872 0)" fill="#f1f0ee"/>
                                                    </svg>
                                                </div>
                                                <div className="text">
                                                    <p>Hotline</p>
                                                    <p>(880) 1773 253467</p>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="form_wrapper">
                                        <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>

                                            <input name={'spam_protector'} type='hidden'/>
                                            <input name={'form_id'} value={'contact-form'} type='hidden'/>
                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">

                                                    <Form.Control
                                                        className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                        {...register("name", {
                                                            required: 'Name is required',

                                                        })}
                                                        type="text"
                                                        placeholder="Name*"
                                                    />
                                                </Form.Group>
                                            </div>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicPhone">

                                                            <Form.Control
                                                                className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                                {...register("phone", {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'please enter your phone first'
                                                                    }, pattern: {
                                                                        value: /^01[0-9]{9}$/,
                                                                        message: 'please enter a valid 11 digit phone number'
                                                                    }
                                                                })}
                                                                type="number"
                                                                placeholder="Phone Number*"/>
                                                        </Form.Group>
                                                    </div>


                                                </Col>
                                                <Col md={6}>
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicEmail">

                                                            <Form.Control
                                                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                                {...register("email", {
                                                                    required: {
                                                                        value: true, message: 'please enter your email'
                                                                    }, pattern: {
                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                        message: 'please enter a valid email address'
                                                                    }
                                                                })}
                                                                type="email"
                                                                placeholder="Email*"/>
                                                        </Form.Group>
                                                    </div>

                                                </Col>
                                            </Row>

                                            <div className="form-group">
                                                <Form.Group controlId="formBasicPhone">
  <textarea
      className={formState?.errors?.message?.message ? 'has-error form-control-lg textarea form-control' : 'textarea form-control form-control-lg'}
      {...register('message', {
          required: {
              // value:true,
              message: 'please enter your Message',
          },
      })}
      placeholder="Message (Optional)"
      rows={4} // You can adjust the number of rows as needed
  />
                                                </Form.Group>

                                            </div>

                                            <div className={`form-group text-center d-flex justify-content-center`}>
                                                <div onClick={handleSubmit(onSubmit, onError)}>

                                                    <Button color={'#F1F0EE'} borderColor={'#F1F0EE'}
                                                            text={'Submit Message'}/>

                                                </div>

                                            </div>

                                        </Form>
                                    </div>
                                </Col>

                            </Row>
                        </Container>
                    </Modal.Body>
                </div>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.div`

  .modal-dialog {
    margin: 0;
  }

  h4 {
    letter-spacing: 1.5px;
  }

`;


export default React.memo(PeoplePopup);