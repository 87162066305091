import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import swiper, {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import BrandBox from "./BrandBox";
import NavigationIcon from "./NavigationIcon";
import ProjectBox from "./ProjectBox";
import Button from "./Button";
import InsightBox from "./InsightBox";

const RelatedInsight = ({data, id, no_slider, bg, padding, offset}) => {

    const [current, setCurrent] = useState('1')
    const [height, setHeight] = useState('0')
    const handleSliderCurrent = () => {
        setTimeout(() => {
            if (document.querySelector('.sustain .swiper-pagination-current')) {
                setCurrent(document.querySelector('.sustain .swiper-pagination-current').innerHTML)
            }
        }, 200)
    }
    let title = data?.section_data?.title;
    let item_list = data?.posts?.list;



    return (
        <StyledComponent bg={bg} height={height} offset={offset} id={`${id ? id : "Sustainability"}`}
                         className={`sustain  ${padding ? padding : 'pt-200 pb-200'} ${no_slider ? 'slider_no' : ''}`}>
            <Container className={'thisis'}>
                <Row>
                    <Col md={12}>
                        <div className={'sustain__top justify-content-space-between align-items-center d-flex'}>
                            <h3 className={'split-up'}>Related Insights</h3>
                            <div className="button-group d-flex align-items-center">
                                {
                                    window?.innerWidth > 767 &&
                                    <Button color={'#F1F0EE'} margin={'0 100px 0 0'} borderColor={'#F1F0EE'} text={'View All'}/>

                                }
                                <NavigationIcon prev_id={'brand-prev'} next_id={'brand-next'} color={'#F1F0EE'}/>

                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
            <Container  className={` slider-wrapper-brand`}>
                <Swiper
                     modules={[Autoplay, Pagination, Navigation, EffectFade]}
                    allowTouchMove={true}
                    autoHeight={true}
                    slidesPerView={3}
                    speed={1500}
                    spaceBetween={50}
                    centeredSlides={true}
                    slidesOffsetAfter={50}

                    pagination={{
                        type: "fraction",
                    }}
                    navigation={{
                        prevEl: '#story-prev',
                        nextEl: '#story-next',
                    }}
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = '#brand-prev';
                        swiper.params.navigation.nextEl = '#brand-next';
                    }}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 15,

                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 15,

                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                    }}
                    loop={true}
                    onSlideChange={(s) => handleSliderCurrent()}
                >

                    <SwiperSlide key={0}>
                        <InsightBox/>
                    </SwiperSlide>
                    <SwiperSlide key={1}>
                        <InsightBox/>
                    </SwiperSlide>
                    <SwiperSlide key={2}>
                        <InsightBox/>
                    </SwiperSlide>
                    <SwiperSlide key={3}>
                        <InsightBox/>
                    </SwiperSlide>
                    <SwiperSlide key={4}>
                        <InsightBox/>
                    </SwiperSlide>
                    <SwiperSlide key={5}>
                        <InsightBox/>
                    </SwiperSlide>
                    <SwiperSlide key={6}>
                        <InsightBox/>
                    </SwiperSlide>

                </Swiper>
                {
                    window?.innerWidth < 767 &&
                    <Col md={12}>
                        <Button color={'#F1F0EE'} margin={'0 100px 0 0'} borderColor={'#F1F0EE'} text={'View All'}/>

                    </Col>
                }
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background: ${props => props.bg ? props.bg : 'inherit'};


  .reveal {
    overflow: hidden;

    img {
      transform-origin: left;
      inset: 0px;
    }
  }

  .swiper-initialized {
    //margin: 0 -60px;
    //padding: 0 120px;
  }

  .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-slide {
    .wrapper .all-merge .item-image-slide::after {
      &:after {
        transition: 0.7s all cubic-bezier(.54,.32,.05,.96);
      }
    }

    .content {
      opacity: 0;
      visibility: hidden;
    }
  }

  .swiper-slide-active {
    .wrapper .all-merge .item-image-slide::after {
      opacity: 0;
      visibility: hidden;
    }

    .logo-brand {
      opacity: 1 !important;
      visibility: visible !important;
    }

    .content {
      opacity: 1;
      visibility: visible;
    }
  }


  .sustain__top {
    padding-bottom: 10px;
    //border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    justify-content: space-between;
    margin-bottom: 50px;

    p {
      margin: 0;
    }
  }




  @media (max-width: 767px) {
    .title {
      h2 {
        font-size: 40px;
      }
    }

    .sustain__top {
      flex-direction: column;

      h2 {
        margin-bottom: 40px;
      }
    }

    .wrapper {
      padding-right: 0;
    }
    .button-group{
      flex-direction: column-reverse;
      margin-top: 40px;
     
    }
    .dc-btn{
      margin-top: 40px;
    }
    .swiper-initialized {
     padding: 0;
      margin: 0 0;
    }

    .slider-wrapper-brand {
      padding: 0 15px !important;
    }

    .logo-brand {
      display: none;
    }

    .all-merge .content {
      margin-top: 20px !important;
      text-align: left !important;
    }
    .sustain__top{
     align-items: flex-start !important; 
      margin-bottom: 40px;
      padding-bottom: 0;
      h3{
        padding: 0;
      }
    }
    
  }




`;

export default React.memo(RelatedInsight);
