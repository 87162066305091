import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Img} from "./Img";
import {Link} from "react-router-dom";
import gsap from 'gsap';
import {hover} from './../styles/globalStyleVars'

const ProjectBox = ({data}) => {


    const dateString = data?.date ? data?.date : '01 January 2023';
    const dateParts = dateString.split(' ');

// Now, dateParts will be an array like ["10", "October", "2023"]
    const day = parseInt(dateParts[0]);
    const month = dateParts[1];
    const year = parseInt(dateParts[2]);

    return (

        <StyledProjectBox>
            <div className="wrapper">
                <div className='blog-single'>
                    <div className="blog-single__inner">
                        <Link to={`/insights/${data?.post_name}`}></Link>
                        <Img src={data?.thumb} objectFit={'cover'} layout={'fill'}/>
                        <div className="blog-single__inner__content">
                            <div className="blog-single__inner__content__top">
                                <h4>
                                    {data?.post_title}
                                </h4>
                            </div>
                            <div className="blog-single__inner__content__bottom">
                                <div className="date">
                                    <h2>{day}</h2>
                                    <p className={'meta-font'}>{month} {year}</p>
                                </div>
                                <p className={'meta-font'}>{data?.category ? data?.category : 'Category'}</p>
                            </div>
                        </div>
                        <div className="noise"></div>
                    </div>
                </div>
            </div>
        </StyledProjectBox>

    )
};


const StyledProjectBox = styled.div`

  .global-image {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #000;
      opacity: 0.3;
      height: 100%;
      width: 100%;
    }
  }

  .blog-single {
    border: 1px solid #C9B385;
    transition: all .3s cubic-bezier(.74, 0, .24, .99);

    &__inner {
      padding-top: calc(490 / 370 * 100%);
      position: relative;
      overflow: hidden;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 3;
      }

      &__content {
        //background-color: #26201E;
        //
        //&:after {
        //  opacity: 1;
        //  top: 0;
        //  transition: all .4s cubic-bezier(.74,0,.24,.99);
        //  background-image: url('/images/static/background-noise.png');
        //  background-blend-mode: multiply;
        //  width: 100%;
        //  height: 100%;
        //  position: absolute;
        //  inset: 0;
        //  opacity: 0.2;
        //  content: "";
        //}

        &__top {

          h4 {
            position: absolute;
            top: 40px;
            left: 40px;
            right: 40px;
            z-index: 2;
            color: #F1F0EE;
            margin: 0;
            transition: all .3s cubic-bezier(.74, 0, .24, .99);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }

        }

        &__bottom {
          position: absolute;
          margin: 0;
          left: 40px;
          right: 40px;
          bottom: 24px;
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          z-index: 2;
          align-items: flex-end;

          h2 {
            color: #C9B385;
          }

          p {
            color: #C9B385;
          }


        }
      }


      .noise {
        position: absolute;
        inset: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background: #1A181B;
        transition: all .4s cubic-bezier(.74, 0, .24, .99);

        &:after {
          content: '';
          background-image: url('/images/static/background-noise.png');
          background-blend-mode: multiply;
          width: 100%;
          height: 100%;
          position: absolute;
          inset: 0;
          opacity: 0.2;
        }

      }

      &:hover {
        .blog-single__inner__content:after {
          height: 0;
        }

        .noise {
          height: 0;
        }

        .blog-single__inner__content__top {
          h4 {
            //opacity: 0;
            //transform: translateY(-20px);
          }

          p {
            transform: none;
            //opacity: 1;
          }
        }

        .blog-single__inner__content__bottom {
          border-color: #FFF;

          h2 {
            color: #ffffff;
          }

          p {
            color: #ffffff;

            span {
              color: #ffffff;
            }
          }
        }
      }

      @media(max-width: 767px){
      .blog-single__inner__content:after {
        height: 0 !important;
      }

      .noise {
        height: 0 !important;
      }

      .blog-single__inner__content__top {
        h4 {
          //opacity: 0;
          //transform: translateY(-20px);
        }

        p {
          transform: none !important;
          //opacity: 1;
        }
      }

      .blog-single__inner__content__bottom {
        border-color: #FFF !important;

        h2 {
          color: #ffffff !important;
        }

        p {
          color: #ffffff !important;

          span {
            color: #ffffff !important;
          }
        }
      }
    }
    }

    &:hover {
      border: 1px solid transparent;

    }
    
    @media(max-width: 767px){
      border: 1px solid transparent !important;
      .blog-single__inner__content__bottom, .blog-single__inner__content__top h4{
        left:30px; 
        right:30px;
      }
    }
  }

`;

export default React.memo(ProjectBox);














