import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {ImageParallax} from "./ImageParallax";
import Title from "./Title";
import {Col, Container, Row} from "react-bootstrap";
import Button from "./Button";
import ButtonLink from "./ButtonLink";


const Cta = ({img,data,getin}) => {



    return (

        <StyledCTA>
            <div className="image-wrapper">
                <ImageParallax no_para src={data?.image?.large}/>
                <Container>
                    <Row>
                        <Col md={12}>
                            {
                                data?.title &&
                                <Title data-lag={0.1} color={'#F1F0EE'} margin={'0 0 40px'} text={data?.title}/>

                            }
                            {
                                data?.url || getin ?
                                    <Button data-lag={0.1}  color={'#F1F0EE'} src={data?.url ? data?.url : '/contacts'}
                                            text={'Get in Touch'}/>
                                    :
                                    <ButtonLink data-lag={0.1}   color={'#F1F0EE'} src={data?.file ? data?.file : '/contacts'}
                                            text={'Download Catalog'}/>
                            }

                        </Col>
                    </Row>
                </Container>
            </div>
        </StyledCTA>

    )
};


const StyledCTA = styled.div`
  position: relative;
  overflow: hidden;

  .image-wrapper {
    padding-top: 150px;
    padding-bottom: 150px;
    //padding-top: calc(606 / 1366 * 100%);
    position: relative;
    
    @media(min-width: 1920px){
      padding-top: 250px;
      padding-bottom: 250px;
    }
  }


  @media (max-width: 767px) {
    .image-wrapper {
      padding-top: 120px;
      padding-bottom: 120px;
    }

  }
`;

export default React.memo(Cta);














