import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {ImageParallax} from "./ImageParallax";
import reactHtmlParser from "react-html-parser";

const Video = ({data}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    return (
        <>
            <StyledComponent className={'video-modal'}>
                <Container>
                    <Row>
                        <Col sm={{span: 10, offset: 1}}>
                            <div className="title-fixed">
                                {
                                    data?.title ?
                                        <h3 className={'split-up'}>{reactHtmlParser(data?.title)}
                                        </h3>

                                        : ''
                                }
                            </div>

                            <div className="video-modal__img hoverable" onClick={handleShow}>
                                <ImageParallax src={data?.image?.large}/>
                                <div className="video-modal__img__click">
                                    <svg width="32" height="40" viewBox="0 0 32 40">
                                        <path id="Polygon_1" data-name="Polygon 1" d="M20,0,40,32H0Z"
                                              transform="translate(32) rotate(90)" fill="#fff"/>
                                    </svg>
                                    <span className="circle"/>
                                    <span className="blur"/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </StyledComponent>

            {/*modal*/}
            <Modal className={'modal-video'} show={show} onHide={handleClose}>

                <svg onClick={handleClose} className={'close-modal'} xmlns="http://www.w3.org/2000/svg" width="13.426"
                     height="13.423"
                     viewBox="0 0 13.426 13.423">
                    <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close"
                          d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z"
                          transform="translate(-11.285 -11.289)" fill="#fff"/>
                </svg>


                <Modal.Body>
                    <iframe width="560" height="315"
                            src={`https://www.youtube.com/embed/${data?.id}?si=H1t21Ym3px2yQicH;controls=0&autoplay=1`}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen/>
                </Modal.Body>

            </Modal>
        </>


    );
};

const StyledComponent = styled.section`
  overflow: hidden;

  .title-fixed {
    transform: translateY(60%);
    position: relative;
    z-index: 5;
    text-align: center;
  }


  .video-modal__img {
    padding-top: calc(660 / 1170 * 100%);
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &__click {
      position: absolute;
      inset: 0;
      margin: auto;
      width: 250px;
      height: 250px;
      display: flex;
      align-items: center;
      justify-content: center;


      svg {
        position: relative;
        z-index: 2;
      }

      .circle {
        border: 1px solid #FFF;
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        border-radius: 50%;
        transition: 1s all cubic-bezier(.74, 0, .24, .99);
      }

      .blur {
        height: 150px;
        width: 150px;
        position: absolute;
        backdrop-filter: blur(5px);
        border-radius: 50%;
        transition: .6s all cubic-bezier(.74, 0, .24, .99);
        background-color: #ffffff24;
        opacity: 0;
        transform: scale(.1);
      }
    }

    &:hover {
      .circle {
        transform: scale(.1);
      }

      .blur {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  @media (max-width: 990px) {
    .title-fixed {
      transform: none;
    }
  }

  @media (max-width: 768px) {
    .video-modal__img {
      margin-left: -12px;
      margin-right: -15px;

      &__click {
        height: 100px;
        width: 100px;
      }
    }
  }

  @media (max-width: 767px) {
    h3 {
      margin-bottom: 40px;
    }

    .title-fixed {
      text-align: left;
    }
  }

`;

export default React.memo(Video);
