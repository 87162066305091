import React, {useEffect,useRef} from 'react';
import styled from 'styled-components';
import {Img} from "./Img";
import {Link} from "react-router-dom";
import gsap from 'gsap';
import Marker from "./svg/Marker";

const GlobalMap = ({data}) => {



    return (

        <StyledProjectBox>
            <a href={data?.url} target={'_blank'}>
                <div className="image-wrapper">
                    <div className="marker">
                        <Marker/>
                    </div>
                    <Img src={data?.image?.large}/>
                </div>
            </a>
        </StyledProjectBox>

    )
};


const StyledProjectBox = styled.div`
  @keyframes button-halo-animation {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(1.5);
      opacity: 0;
    }
  }

  .image-wrapper{
    position: relative;
    padding-top: calc(700 / 1366 * 100%);
    .marker{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index: 99;
      width: 55px;
      height: 55px;


 
    }
  }
`;

export default React.memo(GlobalMap);














