import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import {title} from "../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";


const DiscoverAbout = ({padding,data}) => {



    return (

        <StyledOverviewInner className={`discoverAbout ${padding ? padding : 'pt-200 pb-200'} `}>
            <Container>
                <Row>
                    <Col md={12}>
                        <h2 className={'split-up'}>
                            {
                                data?.title &&
                                reactHtmlParser(data?.title)
                            }
                        </h2>
                    </Col>
                    <Col md={{span: 9, offset: 2}}>
                        <ul>
                            {
                                data?.desc && data?.desc.length > 0 &&
                                data?.desc.map((e,index) => {
                                    return(

                                        <li key={index}>
                                            <p className="p1-font split-up">
                                                {e?.title}
                                            </p>
                                            {reactHtmlParser(e?.desc)}
                                        </li>
                                    )
                                })
                            }



                        </ul>
                    </Col>
                </Row>


            </Container>
        </StyledOverviewInner>)
};

const StyledOverviewInner = styled.div`
  position: relative;
  padding-bottom: 80px;
  h2{
    font-size: 8rem; /* 120px / 15px = 8rem */
    font-weight: 300;
    line-height: 8.3333rem; /* 110px / 15px = 7.3333rem */
    font-family: ${title};
    color: #F1F0EE;
    text-transform: uppercase;
    margin-bottom: 120px;
  }
  
  ul{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    li{
      width: calc(33.3333% - 30px);
      flex: 0 0 calc(33.3333% - 30px);
      margin-bottom: 90px;

      .p1-font{
        color: #F1F0EE;
        margin-bottom: 20px;
      }
      p{
        color: #818181;
        margin: 0;
      }
    }
  }
  
  @media(max-width: 767px){
    h2{
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 60px;
    }
    ul{
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      width: 100%;
      li{
        width: calc(100% - 30px);
        flex: 0 0 calc(100% - 30px);
        margin-bottom: 30px;

        .p1-font{
          color: #F1F0EE;
          margin-bottom: 20px;
        }
        p{
          color: #818181;
          margin: 0;
        }
      }
    }
  }
`;


export default React.memo(DiscoverAbout);
