import React from 'react';
import styled from "styled-components";

const Logo = () => {
    return (
        <StyledLogo>
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
                <g id="Group_18823" data-name="Group 18823" transform="translate(-1075 -22)">
                    <g id="_12" data-name="12" transform="translate(1083.04 30.04)">
                        <path id="Path_1903" data-name="Path 1903" d="M14.768,13.847l-1.835-1.829a6.174,6.174,0,1,0-.914.914l1.829,1.835a.651.651,0,1,0,.921-.921ZM3.283,8.147A4.864,4.864,0,1,1,8.147,13.01,4.864,4.864,0,0,1,3.283,8.147Z" transform="translate(0 0)" fill="#f1f0ee"/>
                    </g>
                    <g id="Rectangle_6209" data-name="Rectangle 6209" transform="translate(1075 22)" fill="none" stroke="#f1f0ee" strokeWidth="1">
                        <rect width="33" height="33" rx="16.5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="32" height="32" rx="16" fill="none"/>
                    </g>
                </g>
            </svg>
        </StyledLogo>
    );
};

const StyledLogo = styled.div`
  cursor: pointer;
  position: relative;
  height: 33px;
  width: 33px;
  border-radius: 50%;

  &:after {
    position: absolute;
    //content: '';
    height: 100%;
    width: 100%;
    box-shadow: 0 0 0 1px ${p => p.borderColor || '#F1F0EE'};
    inset: 0;
    border-radius: 50%;
    transition: all .5s cubic-bezier(.54,.32,.05,.96);
    //animation: button-halo-animation 4.5s infinite;
  }
  @keyframes button-halo-animation {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  
  path{
    transition: all cubic-bezier(.74,0,.24,.99) 0.4s;
  }
  &:hover{
    path{
      transform: scale(1.06);
    }
  }
`;

export default React.memo(Logo);
