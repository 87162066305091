import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import {Col, Container, Row} from "react-bootstrap";
import GlobalMap from "../../components/GlobalMap";
import ContactForm from '../../components/ContactForm'
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPosts, postForm} from "../../api/redux/contact";
import {useDispatch, useSelector} from "react-redux";
import {OnlyImg} from "../../OnlyImg";
import gsap, {CSSPlugin, TimelineLite} from "gsap";
import {SplitText} from "gsap/SplitText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useLocation} from "react-router-dom";
gsap.registerPlugin(ScrollTrigger, CSSPlugin, SplitText);

const CONTACTUS = () => {








    const dispath = useDispatch();
    const location = useLocation()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CONTACT
        dispath(fetchPosts([api_url]))
    }, [dispath,location?.pathname])


    let getPost = useSelector(store => store.contact)

    const page_data = getPost?.posts?.page_data?.[0]?.meta
    const banner = getPost?.posts?.page_data?.[0]?.innerBanner
    const contact_address = getPost?.posts?.page_data?.[0]?.contacts
    const map = getPost?.posts?.page_data?.[0]?.maps


    useEffect(() => {

        gsap.utils.toArray('.split-up').forEach((item, i) => {
            const parentSplit = new SplitText(item, {
                linesClass: "split-parent"
            })
            const childSplit = new SplitText(item, {
                type: "lines, chars",
                linesClass: "split-child"
            })
            gsap.from(parentSplit.lines, {
                duration: 1,
                yPercent: 100,
                // stagger: 0.1,
                scrollTrigger: {
                    trigger: item,
                    toggleActions: "restart none none reset",
                    // start: "top 60%",
                    // markers: true
                }
            })
        });


        const box = document.querySelectorAll('.box');
        const boxplus = document.querySelectorAll('.boxr');
        const cta = document.querySelectorAll('.cta');
        const box_up = document.querySelectorAll('.box-up');
        const box_down = document.querySelectorAll('.box-down');



        if (window.innerWidth > 767) {


            box.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_up.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_down.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });

            boxplus.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            cta.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -50,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });


        }

    }, [getPost, location?.pathname])




    // for close menu
    let getMobileMenuBar = document.querySelector('.main-menu-mobile');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');
    let tl2 = new TimelineLite();
    const closeDelstopMenu = () => {
        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })
    }

    useEffect(() => {
        document.querySelector('body').classList.remove('menu-is-open')

        document.querySelector('.main-menu').classList.remove('menu-is-open')
        closeDelstopMenu()
        // disableScroll.off()
        if (document.body.classList.contains('overflow')) {
            document.body.classList.remove('overflow')
        }

        getMobileMenuBar?.classList.remove('menu-open')
        document.body?.classList.remove('menu-on')
        document.querySelector('.main-menu-mobile').classList.remove('menu-is-open')
        document.body?.classList.remove('stop-scroll')
        if (document.querySelector('.main-item.active')) {
            getItemsParent.classList.remove('active')
            document.querySelector('.main-item.active').classList.remove('active')
        }

        tl2.to(getItemsParent, {
            duration: .2,
            x: '100%'
        }).to(getItemsParent, {
            duration: .2,
            display: 'none'
        })

    }, [getPost, location?.pathname])




    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title+' - Trade Intercontinental' : 'Trade Intercontinental'}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                <InnerBanner title={banner?.title} subtext={banner?.shortDesc}
                             img={banner?.image?.large}/>

                {
                    contact_address && contact_address?.length > 0 &&
                    <section className="contact-section-address pt-120 pb-120">
                        <Container>

                            {
                                contact_address && contact_address?.length > 0 &&
                                contact_address?.map((e, index) => {
                                    const phoneNumbers = e?.phone; // Get the phone property from e
                                    const email = e?.email; // Get the email property from e
                                    const arrayOfNumbers = phoneNumbers ? phoneNumbers.split(', ') : '';
                                    const arrayOfEmails = email ? email.split(', ') : '';





                                    return (
                                        <Row  key={index}>
                                            <Col className={`${index == 0 ? 'd-flex align-content-start' : ''}`} md={4}>
                                                <img src={e?.icon}/>

                                            </Col>
                                            <Col md={4}>
                                                <p className={''}>{e?.name}</p>
                                            </Col>
                                            <Col md={4}>
                                                {
                                                    e?.address &&
                                                    <a className={'p1-font'} href={e?.direction} target={'_blank'}>
                                                        {e?.address} </a>
                                                }
                                                {
                                                    arrayOfNumbers && arrayOfNumbers?.length > 0 &&
                                                    arrayOfNumbers.map((e, index) => {
                                                        return(
                                                            <a className={''} key={index} href={`tel:${e}`}>{e}</a>
                                                        )
                                                    })
                                                }
                                                {
                                                    arrayOfEmails && arrayOfEmails?.length > 0 &&
                                                    arrayOfEmails.map((e, index) => {
                                                        return(
                                                            <a className={''} key={index} href={`mailto:${e}`}>{e}</a>
                                                        )
                                                    })
                                                }

                                            </Col>
                                        </Row>
                                    )
                                })
                            }

                        </Container>
                    </section>
                }

                {
                    map &&
                    <GlobalMap data={map}/>

                }
                <ContactForm/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  .contact-section-address {
    padding: 120px 0;

    .container {
      max-width: calc(80% - 30px);
    }

    .row {
      border-bottom: 1px solid rgba(241, 240, 238, 0.2);
      padding-bottom: 30px;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }

    .d-flex {
    }

    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      color: #818181;
      letter-spacing: 0;
      margin: 0;
    }

    a {
      color: #F1F0EE;

      display: block;
      letter-spacing: 0;
    }
  }

  .form-group {
    margin-bottom: 40px;

    &:last-child {
      border: none !important;
    }
  }

  .form_wrapper .form-group .form-control {
    font-size: 1rem !important; /* 15px / 15px = 1rem */
    font-weight: 600 !important;
    line-height: 1rem !important; /* 20px / 15px = 1.3333rem */
    letter-spacing: -0.05rem !important;
    color: #F1F0EE !important;
    padding-bottom: 15px !important;
    border-bottom-color: #F5F5F5;


    &::placeholder {
      font-size: 1rem !important; /* 15px / 15px = 1rem */
      font-weight: 600 !important;
      line-height: 1rem !important; /* 20px / 15px = 1.3333rem */
      letter-spacing: -0.05rem !important;
      color: #F1F0EE !important;
    }
  }

  @media (max-width: 767px) {

    .contact-section-address {
      backgrxound: #F1EEE9;

      .container {
        max-width: 100%;
      }

      .row {

      }

      .d-flex {
        justify-content: flex-start;

        svg {
          margin-bottom: 10px;
        }
      }

      p {
        margin-bottom: 20px;
      }


    }

  }

`;

export default CONTACTUS;
