import React, {useEffect, useState} from "react";
import gsap from "gsap";
import styled from "styled-components"; // Import your CSS file

const Cursor = () => {

    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        // Your JavaScript code for cursor interaction
        var cursor = document.querySelector(".cursor");
        var follower = document.querySelector(".cursor-follower");
        var text = document.querySelector(".piu");
        const pos = { x: window.innerWidth / 1, y: window.innerHeight / 1 }
        const xSet = gsap.quickSetter(cursor, 'x', 'px')
        const ySet = gsap.quickSetter(cursor, 'y', 'px')

        var posX = window.innerWidth / 1,
            posY = window.innerHeight / 1,
            mouseX = posX,
            mouseY = posY;
        const speed = 1
        gsap.to(cursor, 0.015, {
            repeat: -1,
            onRepeat: function () {
                posX += (mouseX - posX) / 9;
                posY += (mouseY - posY) / 9;

                gsap.set(follower, {
                    css: {
                        left: posX - 20,
                        top: posY - 20,
                    },
                });

                gsap.set(cursor, {
                    css: {
                        left: mouseX,
                        top: mouseY,
                    },
                });
            },
        });

        document.addEventListener("mousemove", function (e) {
            mouseX = e.pageX;
            mouseY = e.pageY;
        });

        gsap.ticker.add(() => {
            const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio())

            pos.x += (mouseX - pos.x) * dt
            pos.y += (mouseY - pos.y) * dt

            xSet(pos.x)
            ySet(pos.y)
        })




        // Add scroll listener to update scrollY
        window.addEventListener("scroll", () => {
            setScrollY(window.scrollY);
        });

        // Add hover effect to anchor tags with the class "link-h"
        const links = document.querySelectorAll(".link-h");

        links.forEach((link) => {
            link.addEventListener("mouseenter", () => {
                cursor.classList.add("active");
                follower.classList.add("active");
                text.classList.add("active");
            });

            link.addEventListener("mouseleave", () => {
                cursor.classList.remove("active");
                follower.classList.remove("active");
                text.classList.remove("active");
            });
        });


        return () => {
            // Remove scroll listener when the component unmounts
            window.removeEventListener("scroll");
        };
    }, []);

    return (
        <StyledComponent>
            <div className="cursor">
                <span className="piu p1-font">Explore</span>
            </div>
            <div className="cursor-follower"></div>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  .cursor {
    position: fixed;
    background: #AC835E;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    transition: 0.7s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity;
    user-select: none;
    pointer-events: none;
    transform: scale(0.8);
    will-change: transform;
    z-index: 999999999999999999999999;
    background-blend-mode: multiply;
    display: flex;
    align-items: center;
    justify-content: center;
    will-change: transform;
    transform-origin: inherit;
  }

  .cursor::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }

  .piu {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }

  .piu.active {
    font-size: 2px;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    letter-spacing: 0;
    opacity: 1;
    visibility: visible;
    height: auto;
  }

  .cursor.active {
    opacity: 0.8;
    transform: scale(12);
  }

  .cursor.active::before {
    opacity: 1;
  }

  .cursor-follower {
    position: fixed;

    background: rgba(241, 240, 238, 0.3);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.4s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity;
    user-select: none;
    pointer-events: none;
    transform: translate(4px, 4px);
    will-change: transform;
    z-index: 999999999999999999999999;

  }

  .cursor-follower.active {
    opacity: 0.3;
    transform: scale(0);
  }
`;
export default React.memo(Cursor);
