import React from 'react';
import styled from "styled-components";

const Logo = () => {
    return (
        <StyledMarker>
            <svg xmlns="http://www.w3.org/2000/svg"  width="73" height="73" viewBox="0 0 73 73">
                <defs>
                    <filter id="Ellipse_611" x="0" y="0" width="73" height="73" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur"/>
                        <feFlood flood-opacity="0.161"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g id="Group_23859" data-name="Group 23859" transform="translate(16 13)">
                    <g transform="matrix(1, 0, 0, 1, -16, -13)" filter="url(#Ellipse_611)">
                        <circle id="Ellipse_611-2" data-name="Ellipse 611" cx="27.5" cy="27.5" r="27.5" transform="translate(9 6)" fill="#ac835e"/>
                    </g>
                    <path id="Path_1899" data-name="Path 1899" d="M7.026,17.978H15.67v3.485H7.026c0,4.2,1.474,7.517,6.115,7.517H15.67v3.486H11.435a8.693,8.693,0,0,1-8.484-8.416V10.168L7.026,7.361Z" transform="translate(11.189 0.587)" fill="#fff" fill-rule="evenodd"/>
                </g>
            </svg>


        </StyledMarker>
    );
};

const StyledMarker = styled.div`

  @keyframes flicker {
    0% {
      stroke-width: 0;
    }
    50% {
      stroke-width: 4;
    }
    100% {
      stroke-width: 0;
    }
  }

  #Ellipse_611-2 {
    stroke: #ac835e;
    stroke-width: 0;
    stroke-dasharray: 0;
    animation: flicker 1s infinite;
  }
    
`;

export default React.memo(Logo);
