import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

export const OnlyImageNoLoad = ({src, alt, sizes, position, objectFit, margin, left, right, top, bottom}) => {


    return (
        <img
            src={src}
            alt={alt || ''}
        />
    );
};


