import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import BrandBox from "./BrandBox";
import NavigationIcon from "./NavigationIcon";

const BrandSlider = ({data, id, no_slider, bg, padding, offset}) => {

    const [current, setCurrent] = useState('1')
    const [height, setHeight] = useState('0')
    const handleSliderCurrent = () => {
        setTimeout(() => {
            if (document.querySelector('.sustain .swiper-pagination-current')) {
                setCurrent(document.querySelector('.sustain .swiper-pagination-current').innerHTML)
            }
        }, 200)
    }
    let title = data?.section_data?.title;
    let item_list = data?.posts?.list;

    const getImageHeight = document.querySelector('.swiper-slide-active .item-image-slide')
    const getImageHeightMobile = document.querySelector('.item-image-slide')
    const getLeft = document.querySelector('.slider-wrapper-brand .navigation_button .hover_left')
    const getRight = document.querySelector('.slider-wrapper-brand .navigation_button .hover_right')
    const heightGet = getImageHeight?.clientHeight;
    useEffect(() => {

        if (getLeft) {
            getLeft.style.top = `${(heightGet / 2) - 40}px`;
        }
        if (getRight) {
            getRight.style.top = `${(heightGet / 2) - 40}px`;
        }

    }, [getImageHeight, heightGet, getLeft, getRight])


    return (
        <StyledComponent bg={bg} height={heightGet} offset={offset} id={`${id ? id : "Sustainability"}`}
                         className={`sustain  ${padding ? padding : 'pt-200 pb-200'} ${no_slider ? 'slider_no' : ''}`}>
            <Container className={'thisis'}>
                <Row>
                    <Col md={12}>
                        <div className={'sustain__top justify-content-center align-items-center d-flex'}>
                            <h3 className={'split-up'}>Our Brands</h3>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className={`p-0 slider-wrapper-brand`}>
                <NavigationIcon prev_id={'brand-prev'} next_id={'brand-next'} color={'#F1F0EE'}/>

                {
                    data && data?.length > 0 &&
                    <Swiper
                        modules={[Autoplay, Pagination, Navigation, EffectFade]}
                        slidesPerView={3}
                        speed={1500}
                        spaceBetween={50}
                        centeredSlides={true}
                        initialSlide={2}
                        loop={true}
                        navigation={{
                            prevEl: '#story-prev',
                            nextEl: '#story-next',
                        }}
                        onBeforeInit={(swiper) => {
                            if (getLeft) {
                                getLeft.style.top = `${(heightGet / 2) - 40}px`;
                            }
                            if (getRight) {
                                getRight.style.top = `${(heightGet / 2) - 40}px`;
                            }
                            swiper.params.navigation.prevEl = '#brand-prev';
                            swiper.params.navigation.nextEl = '#brand-next';
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 15,

                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 15,

                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        onUpdate={() => {
                            if (getLeft) {
                                getLeft.style.top = `${(heightGet / 2) - 40}px`;
                            }
                            if (getRight) {
                                getRight.style.top = `${(heightGet / 2) - 40}px`;
                            }

                        }
                        }
                    >

                        {
                            data?.map((e, index) => {
                                if (index < 9) {
                                    return (
                                        <SwiperSlide key={index} className={'hoverable'}>
                                            <BrandBox title={e?.brand_slider_subtitle}
                                                      link={`/brands/${e?.brand_slider_slug}`}
                                                      img={e?.brand_slider_image} logo={e?.brand_slider_logo}/>
                                        </SwiperSlide>
                                    )
                                }

                            })
                        }


                    </Swiper>
                }

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background: ${props => props.bg ? props.bg : 'inherit'};

  .logo-brand {
    transition: 0.7s all cubic-bezier(.74, 0, .24, .99);
  }

  .reveal {
    overflow: hidden;

    img {
      transform-origin: left;
      inset: 0px;
    }
  }

  .swiper-initialized {
    margin: 0 -60px;
  }

  .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-slide {
    .wrapper .all-merge .item-image-slide::after {
      &:after {
        transition: 0.7s all cubic-bezier(.74, 0, .24, .99);
      }
    }

    .content {
      opacity: 0;
      visibility: hidden;
    }
  }

  .swiper-slide-active {
    .wrapper .all-merge .item-image-slide::after {
      opacity: 0;
      visibility: hidden;
    }

    .logo-brand {
      opacity: 1 !important;
      visibility: visible !important;
      transition: 0.7s all cubic-bezier(.74, 0, .24, .99);

      @media (min-width: 1800px) {
        //width: calc(100% + 300px);
      }
    }

    .content {
      opacity: 1;
      visibility: visible;
    }
  }


  .sustain__top {
    padding-bottom: 10px;
    //border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    justify-content: space-between;
    margin-bottom: 50px;

    p {
      margin: 0;
    }
  }


  .slider-wrapper-brand {
    position: relative;

    .navigation_button {
      position: unset;

      .hover_left {
        position: absolute;
        left: ${props => props.offset ? props.offset : '0'}px;
        top: 130px;
        @media(min-width: 1800px){
          top: 230px;
        }
        @media(min-width: 1440px){
          top: 177px;
        }
      }

      .hover_right {
        position: absolute;
        right: ${props => props.offset ? props.offset : '0'}px;
        top: 130px;
        @media(min-width: 1800px){
          top: 230px;
        }
        @media(min-width: 1440px){
          top: 177px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .title {
      h2 {
        font-size: 40px;
      }
    }

    .hover_left {
      top: 90px !important;
    }

    .hover_right {
      top: 90px !important;

    }

    .sustain__top {
      flex-direction: column;

      h2 {
        margin-bottom: 40px;
      }
    }

    .wrapper {
      padding-right: 0;
    }


    .swiper-initialized {
      margin: 0 0;
      padding-right: 100px;
    }

    .slider-wrapper-brand {
      padding: 0 15px !important;
    }

    .logo-brand {
      //display: none;
    }

    .all-merge .content {
      margin-top: 20px !important;
      text-align: left !important;
    }

    .sustain__top {
      align-items: flex-start !important;
      margin-bottom: 40px;
      padding-bottom: 0;

      h3 {
        padding: 0;
      }
    }

    .slider-wrapper-brand .navigation_button {
      .hover_right {
        right: 15px !important;
      }

      .hover_left {
        display: none;
      }
    }

  }




`;

export default React.memo(BrandSlider);
