export const apiEndPoints = {
    PAGE_DATA:'page-data',
    POSTS: 'posts',
    HOME: 'page/home',
    ABOUT: 'page/about',
    CONTACT: 'page/contact',
    INSIGHTS: 'insights',
    INSIGHTSPAGE: 'page/insights',
    BRANDPAGE: 'page/brand',
    PRODUCT: 'getProduct/all',
    CATEGORY: 'category',
    CATEGORYTiles: 'category/tiles',
    CATEGORYLights: 'category/lights',
    CATEGORYFurtinure: 'category/furniture',
    BRAND: 'brand',
    PRODUCTDETAILS: 'products',
    CONTACT_FORM: 'contact-form-7/v1/contact-forms/90/feedback',
    PRODUCT_FORM: 'contact-form-7/v1/contact-forms/88/feedback',
    UPCOMING_PRODUCT_FORM: 'contact-form-7/v1/contact-forms/89/feedback',
    BOOK_A_CALL: 'contact-form-7/v1/contact-forms/7/feedback',
    SUBSCRIBE: 'contact-form-7/v1/contact-forms/146/feedback',
    SUBSCRIBENEWSLETTER: 'newsletter/v2/subscriptions',
    UPCOMING: 'upcoming/all',
    SEARCH: 'search/result'
};
