import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

export const OnlyImg = ({src, alt,classname}) => {
    const [imageSrc, setImageSrc] = useState(null);
    const imgRef = useRef(null);

    const DefaultBanner = "";

    const onImageLoad = () => {
        gsap.fromTo(imgRef.current, { opacity: 0 }, { opacity: 1, duration: 1 });
    };

    const handleIntersection = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                // Image is in the viewport
                if (imageSrc !== src) {
                    // Check if src is not already set
                    setImageSrc(src);
                }
                observer.unobserve(entry.target); // Stop observing once the image is in view
            }
        });
    };

    useEffect(() => {
        const options = {
            root: null, // viewport
            rootMargin: '0px',
            threshold: 0, // 10% of the image must be in the viewport
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            // Cleanup the observer when the component unmounts
            observer.disconnect();
        };
    }, [src]);

    return (
        <img className={classname ? classname : ''}
            ref={imgRef}
            src={imageSrc || DefaultBanner}
            alt={alt || ''}
            onLoad={onImageLoad}
        />
    );
};


