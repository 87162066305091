import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "./Img";
import reactHtmlParser from "react-html-parser";


const ZigZag = ({padding, img, title,reverse, offset, description}) => {

    return (
        <StyledOverviewInner offset={offset} className={`mission_visioin ${padding ? padding : 'pt-200 pb-200'} `}>
            <Container fluid>
                {
                    reverse ?
                        <Row className={'align-items-center reverse'}>
                            <Col className={'p-0 d-flex content-col'} md={{span:6}}>
                                <div className="content">
                                    <h4>{title ? title : 'RECTIFIED PORCELAIN DECORATIVE MAT WALL TILES'}</h4>
                                    <p className={'p1-font'}>Enhance your walls with Rectified Porcelain Decorative Mat Wall Tiles. Crafted to perfection, these tiles offer a seamless and refined look. They resist moisture, come in diverse styles, and can transform any indoor space. Choose from matte finishes for a sleek aesthetic. With straightforward installation and enduring quality, our Rectified Porcelain Decorative Mat Wall Tiles are the ultimate choice for sophisticated wall decor. Explore our collection today and elevate your space with a touch of luxury.
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} className={'p-0 d-flex'}>
                                <div className="image-wrapper reveal">
                                    <Img src={img ? img : '/images/dynamic/zig1.jpg'}/>
                                </div>
                            </Col>
                        </Row>

                        :
                        <Row className={'align-items-center no-reverse'}>
                            <Col md={6} className={'p-0 d-flex'}>
                                <div className="image-wrapper reveal">
                                    <Img src={img ? img : '/images/dynamic/zig1.jpg'}/>
                                </div>
                            </Col>
                            <Col className={'p-0 d-flex content-col'} md={{span:5,offset:1}}>
                                <div className="content">
                                    <h4>{title ? title : 'RECTIFIED PORCELAIN DECORATIVE MAT WALL TILES'}</h4>
                                    <div className={'p1-font'}>
                                        {
                                            description ? reactHtmlParser(description) : 'Enhance your walls with Rectified Porcelain Decorative Mat Wall Tiles. Crafted to perfection, these tiles offer a seamless and refined look. They resist moisture, come in diverse styles, and can transform any indoor space. Choose from matte finishes for a sleek aesthetic. With straightforward installation and enduring quality, our Rectified Porcelain Decorative Mat Wall Tiles are the ultimate choice for sophisticated wall decor. Explore our collection today and elevate your space with a touch of luxury.\n'
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                }


            </Container>
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;

  .row {
    position: relative;
    div{
      height: 100%;
    }
  }
  .reverse{
    .content-col{
      padding-left: ${props => props.offset ? props.offset : '90'}px !important;
      padding-right: ${props => props.offset ? props.offset : '90'}px !important;
    }
  }
  .no-reverse{
    .content-col{
      padding-right: ${props => props.offset ? props.offset + 15 : '90'}px  !important;
    }
  }
  .image-wrapper {
    position: relative;
    padding-top: calc(768 / 683 * 100%);
  }

  h4 {
    margin-bottom: 80px;
  }
  
  
  @media(max-width: 767px){
    margin-bottom: 120px;
    
    h4{
      margin-bottom: 20px;
    }
    .reverse{
      flex-direction: column-reverse;
      .content-col{
        margin-top: 40px;
        padding-left: 15px !important;
        padding-right: 15px !important;
        
      }
    }
    .no-reverse{
      .content-col{
        margin-top: 40px;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }

`;


export default React.memo(ZigZag);
