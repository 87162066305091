import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "./Img";


const OverviewInner = ({padding, mission, vision}) => {

    return (
        <StyledOverviewInner className={`mission_visioin ${padding ? padding : 'pt-200 pb-200'} `}>
            <Container>
                <Row>
                    <Col md={7}>
                        <div className="image-wrapper reveal">
                            <Img src={mission?.image?.large}/>
                        </div>
                    </Col>
                    <Col md={5}>
                        <h3 className={'split-up'}>{mission?.title}</h3>
                        <p className={'split-up'}>{mission?.description}</p>
                    </Col>
                </Row>

                <Row>
                    <Col md={5}>
                        <h3 className={'split-up'}>{vision?.title}</h3>
                        <p className={'split-up'}>{vision?.description}</p></Col>
                    <Col md={7}>
                        <div className="image-wrapper reveal-right">
                            <Img src={vision?.image?.large}/>
                        </div>
                    </Col>

                </Row>
            </Container>
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;

  .row {
    position: relative;

    &:first-child {
      .col-md-5 {
        padding-left: 70px;
      }
    }

    &:last-child {
      z-index: 3;
      margin-top: -50px;
      justify-content: flex-end;

      .col-md-5 {
        top: 50px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-right: 70px;
      }
    }
  }

  .image-wrapper {
    position: relative;
    padding-top: calc(470 / 670 * 100%);
  }

  h3 {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    .row {
      position: relative;

      .col-md-5 {
        margin-top: 40px;
      }

      &:first-child {
        .col-md-5 {
          padding-left: 15px;
        }
      }

      &:last-child {
        z-index: 3;
        justify-content: flex-end;
        flex-direction: column-reverse;
        margin-top: 80px;

        .col-md-5 {
          top: 0px;
          padding-right: 15px;
        }
      }
    }

  }
`;


export default React.memo(OverviewInner);
