import React, {useEffect, useRef, useState, useLayoutEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {useLocation} from "react-router-dom";
import TilesBox from "./TilesBox";
import {OnlyImg} from "../OnlyImg";
import reactHtmlParser from "react-html-parser";


const Banner = ({data, getOffset}) => {
    gsap.registerPlugin(CSSPlugin, ScrollTrigger, ScrollToPlugin);
    const bannerRef = useRef();
    const [offset, setOffset] = useState(90);
    const [containerOffset, setContainerOffset] = useState(90);


    // data from api
    const bannerData = data;
    const imageMainLarge = data?.image?.large

    // tiles information
    const tile_name = data?.tiles_title
    const tiles_img = data?.tiles_img
    const tiles_link = data?.tiles_link


    // furniture information
    const furniture_title = data?.furniture_title
    const furniture_img = data?.furniture_img
    const furniture_link = data?.furniture_link


    // furniture information
    const lights_title = data?.lights_title
    const lights_img = data?.lights_img
    const lights_link = data?.lights_link


    const bannerScrollerImg = document.querySelector('.banner__scroller img');
    const containerElement = document.querySelector('.container');

    // remove address bar hight from mobile
    useEffect(() => {
        // Get the viewport height and multiply it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;

        // Set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, [data]); // Empty dependency array to run this effect only once


    // useEffect for offset and containerOffset
    useEffect(() => {


        if (bannerScrollerImg && containerElement) {
            // Ensure that the elements are found in the DOM
            setOffset(bannerScrollerImg?.offsetLeft ? bannerScrollerImg?.offsetLeft : offset);
            setContainerOffset(containerElement?.offsetLeft);

        }
    }, [data, bannerScrollerImg?.offsetLeft]);


    // smooth scroll
    const useIsomorphicLayoutEffect = typeof window !== "undefined"
        ? useLayoutEffect
        : useEffect;


    const location = useLocation();
    useEffect(() => {

        if (bannerRef?.current && window?.innerWidth > 992) {
            // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
            let ctx = gsap.context(() => {

                const globalTimeline = gsap.timeline({
                    // defaults: {ease: "none"},
                    scrollTrigger: {
                        id: `whois`,
                        trigger: bannerRef?.current,
                        pinType: 'transform',
                        start: '15',
                        ease: "elastic",
                        pin: true,
                        anticipatePin: 1,
                        immediateRender: false,
                        pinnedContainer: bannerRef?.current,
                        end: "bottom-=320px",
                        toggleActions: "play none none reverse",
                        pinReparent: true,
                        pinSpacing: true,
                        // scrub: true,
                    },
                })


                    globalTimeline?.to('.banner__scroller', {
                        opacity: 1,
                        duration: 0.3,
                        ease: 'power2',
                    }).to('.banner__scroller .main-banner', {
                        ease: 'Expo.easeInOut',
                        duration: 1,
                        transform: 'scale(1)',
                        width: '100%',
                        opacity: 1,
                        height: '100vh', // Set height to '100vh' to fill the viewport
                        objectFit: 'cover',
                    })?.to('.banner__button', {
                        opacity: 0,
                        duration: 0.6,
                        ease: 'power2',
                    }, '-=1')?.to('.banner__content h2', {
                        opacity: 1,
                        fontSize: '5.333rem',
                        lineHeight: '4.667rem',
                        position: 'relative',
                        duration: 0.3,
                    }, '-=0.8')?.to(' .banner__content p', {
                        opacity: 1,
                        position: 'relative',
                        duration: 0.3,
                    }, '-=0.8')?.to(' .banner__content ', {
                        opacity: 1,
                        top: window?.innerWidth > 1800 ? '180px' : '120px',
                        duration: 0.3,
                    }, '-=0.8')?.to('.item-content', {
                            opacity: 1,
                            display: 'block',
                            duration: 0.4,
                        }, '-=0.5');


            }, bannerRef?.current,location?.pathname); // <- IMPORTANT! Scopes selector text
            document.querySelector('.banner__button').addEventListener('click', () => {
                window.scrollBy(0, 50);
            })
            return () => ctx.revert(); // cleanup
        }



        const getAllLinks = document.querySelectorAll('a');
        if (getAllLinks) {
            getAllLinks.forEach(link => {
                link.addEventListener('click', () => {
                    const kill = ScrollTrigger.getById('whois');
                    if (kill) {
                        kill.kill();
                    }
                });
            });
        }



    }, [data,location?.pathname]);


    // useEffect for after load animation
    useEffect(() => {
        if (offset > 0 || window.scrollY < 300) {
            gsap.to('.banner__button', {
                delay: 0.6,
                opacity: 1,
                duration: 1.5,
                ease: 'Expo.easeInOut',
            });

            gsap.to('.banner__content', {
                delay: 0.6,
                opacity: 1,
                left: containerOffset + 15,
                right: containerOffset + 15,
                duration: 1.5,
                ease: 'Expo.easeInOut',
                display: 'block'
            });


            gsap.to('.banner__scroller', {
                delay: 0.4,
                y: 0,
                duration: 1,
                ease: 'Expo.easeInOut',
            });
        }
    }, [data]);

    return (
        <StyledComponent ref={bannerRef} offset={offset} className={'banner-main-home'}>
            <div className="banner__scroller ">
                <OnlyImg classname={'main-banner '} src={imageMainLarge}/>
            </div>
            <div className="banner__button hoverable">
                <svg id="Button_-_Explore" data-name="Button - Explore" xmlns="http://www.w3.org/2000/svg"
                     width="140" height="140" viewBox="0 0 140 140">
                    <g id="Ellipse_389" data-name="Ellipse 389" fill="none" stroke="#f1f0ee" strokeWidth="1"
                       opacity="0.2">
                        <circle cx="70" cy="70" r="70" stroke="none"/>
                        <circle cx="70" cy="70" r="69.5" fill="none"/>
                    </g>
                    <text id="EXPLORE" transform="translate(38.5 75.5)" fill="#f1f0ee" fontSize="15"
                          fontFamily="BananaGrotesk-Semibold, Banana Grotesk" fontWeight="600"
                          letterSpacing="-0.05em">
                        <tspan x="0" y="0">EXPLORE</tspan>
                    </text>
                    <g id="Ellipse_595" data-name="Ellipse 595" transform="translate(0 140) rotate(-90)" fill="none"
                       stroke="#f1f0ee" strokeWidth="1">
                        <circle cx="70" cy="70" r="70" stroke="none"/>
                        <circle cx="70" cy="70" r="69.5" fill="none"/>
                    </g>
                </svg>
            </div>

            <div className="banner__content">
                <Container>
                    <Row>
                        <Col className={'only-content'} md={12}>
                            <h2> {
                                reactHtmlParser(bannerData?.title)
                            }
                            </h2>


                            <p className="p1-first">{
                                reactHtmlParser(bannerData?.short_desc)
                            } </p>


                        </Col>
                    </Row>
                    <Row className={'item-content'}>
                        <Col md={{span: 10, offset: 1}}>
                            <Row>
                                <Col className={'single hoverable' } md={4}>
                                    {
                                        furniture_img &&
                                        <TilesBox title={furniture_title} link={furniture_link} img={furniture_img}/>

                                    }
                                </Col>
                                <Col className={'single hoverable'} md={4}>
                                    {
                                        tiles_img &&
                                        <TilesBox title={tile_name} link={tiles_link} img={tiles_img}/>

                                    }
                                </Col>
                                <Col className={'single hoverable'} md={4}>
                                    {
                                        lights_img &&
                                        <TilesBox title={lights_title} link={lights_link} img={lights_img}/>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  z-index: 6;
  width: 100%;
  will-change: transform;
  position: relative;

  .side {
    height: 100%;
    width: 1rem;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .side {
    background-color: red;
  }

  .banner__scroller {
    overflow: hidden;
    position: relative;
    transform: translateY(100%) scale(1);
    height: 100%;
    width: 100%;

    &:after {
      content: '';
      background: rgba(0, 0, 0, 0.45);
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background-blend-mode: hard-light;
      mix-blend-mode: hard-light;
    }

    .main-banner {
      width: 52%;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: auto;
      right: 0;
      height: auto !important;
      opacity: 0.45 !important;
      padding-top: calc(492 / 770 * 100%);

      img {
        position: absolute;
        inset: 0;
      }
    }


  }

  .banner__content {
    position: absolute;
    top: 180px;
    z-index: 95;
    opacity: 0;
    left: 0;
    text-align: center;

    .only-content {
      margin-bottom: 40px;
    }

    h2 {
      text-transform: uppercase;
      font-size: 8rem; /* 120px / 15px = 8rem */
      font-weight: 300;
      line-height: 8rem; /* 110px / 15px = 7.3333rem */
    }

    .item-content {
      text-align: center;
      opacity: 0;
      display:none;
      .all-merge {
        //backdrop-filter: blur(0);
      }

      .row {
        margin: 0;
        gap: 40px;
        justify-content: space-between;

        .single {
          padding: 0;
          max-width: calc(33.333333% - 40px);
          flex: 0 0 calc(33.333333% - 40px);
        }
      }
    }
  }

  .banner__button {
    position: absolute;
    bottom: 70px;
    right: 18%;
    height: 140px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    cursor: pointer;
    opacity: 0;

    @media (max-width: 1400px) {
      right: 19%;
    }
    @media (min-width: 1800px) {
      right: 20%;
    }

    #Ellipse_595 {
      transform: rotate(-90deg);
      stroke-dasharray: 0 441px;
      transform-origin: 50% 50%;
      will-change: stroke-dashoffset, transform;
      transition: 0.7s all cubic-bezier(.74, 0, .24, .99);
    }

    &:hover {
      #Ellipse_595 {
        stroke-dasharray: 441px 441px;
        transform: none;
        //stroke-dasharray: 260px 260px;
        opacity: 1;
      }
    }

  }


  @media (min-width: 1800px) {
    .banner__content {
      top: 250px !important;

    }
  }
  @media (max-width: 1280px) and (min-width: 1024px) {
    .banner__scroller {
      .content {
        h3 {
          font-size: 4.333rem;
          line-height: 4rem;
          font-weight: 300;
          color: #F1F0EE;
          margin-bottom: 20px;
          text-transform: uppercase;
        }

        p {
          color: #F1F0EE;
          margin-bottom: 40px;
        }

      }
    }

  }

  @media(max-width: 992px){
    display: none !important;
  }
  @media (max-width: 767px) {

    .banner__content {
      left: 15px !important;
      right: 15px;
      bottom: 67px;

      h2 {
        font-size: 25px;
        line-height: 27px;
        color: #ffffff;
      }
    }
  }
  @media (max-width: 768px) {
    height: auto;
    padding-top: calc(1080 / 1920 * 100%);
    position: relative;
    .banner__content {
      bottom: 30px;
      display: none;
    }

  }

`;

export default Banner;
