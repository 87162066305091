import React, {useState, useEffect} from 'react';
import {hover} from '../styles/globalStyleVars';
import styled from 'styled-components';
import {Link} from "react-router-dom";



const QuotationButton = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    target,
                    borderColor
                }) => {

    return (
        <StyledBtn className={`dc-btn`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
        >
            {src ? (
                <Link  href={src || '/'}>
                    <span>{text}</span>
                </Link>
            ) : (
                <a  target={target || '_self'}> <span>{text}</span></a>
            )}
        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: fit-content;
    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      color: ${p => p.color || '#1A181B'};
      font-size: ${props => props.fontSize || '15'}px;
      font-weight: ${props => props.fontWeight || 500};
      margin: 0;
      padding: 15px 30px;
      line-height: ${props => props.lineHeight || '15'}px;
      background-color: ${props => props.background || `#F1F0EE`};
      letter-spacing: ${props => props.letterSpacing || `-0.5px`};
      position: relative;
      border-radius: ${props => props.borderRadius || '0'};
      z-index: 0;
      border-radius: 30px;
      overflow: hidden;
      span{
        position: relative;
        z-index: 1;
      }
      
      &:after{
        background: ${hover};
        content: '';
        border-radius: 30px;
        height: 0;
        width: 100%;
        position: absolute;
        z-index: 0;
        transition: 0.7s all cubic-bezier(.54,.32,.05,.96);
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      }
  

      &:hover {
        color: ${p => p.color || '#F1F0EE'} !important;
        &:after{
          height: 100%;
        }
      }

 

    }
  }





`;


export default React.memo(QuotationButton);
