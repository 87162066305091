import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Img} from "./Img";
import {Link} from "react-router-dom";

const TilesBox = ({img, title, link}) => {
    return (
        <StyledProjectBox>
            <div className="wrapper  single_item_home_banner">
                <Link className={''} to={link}>
                    <div className=" all-merge">
                        <div className="item-image-slide">
                            <div className="slide_1 img-wrapper hoverable">
                                <Img src={img ? img : '/images/dynamic/tiles.jpg'}/>
                            </div>

                        </div>
                        <div className="item-content">
                            <h6>{title ? title : 'Furniture'}</h6>
                        </div>
                    </div>
                </Link>
            </div>
        </StyledProjectBox>
    )
};

const StyledProjectBox = styled.div`
  .wrapper {
    .all-merge {
      overflow: hidden;
      border: 1px solid #F1F0EE;
      border-radius: 20px;
      padding: 10px;
      backdrop-filter: blur(10px);
      background: rgba(26, 24, 27, 0.2);
      //transition: 0.7s all cubic-bezier(.74,0,.24,.99);

      .item-image-slide {
        padding-top: calc(243 / 250 * 100%);
        position: relative;
        overflow: hidden;
        border-radius: 20px;

        .img-wrapper {
          position: absolute;
          inset: 0;
          overflow: hidden;

          .global-image {
            overflow: hidden;

            img {
              transition: 0.7s all cubic-bezier(.74, 0, .24, .99);
              transform: scale(1.01);
            }
          }

          img {
            overflow: hidden;
          }

          &:first-child {
            z-index: 1;
            transition: 1s all cubic-bezier(.74, 0, .24, .99);

            img {
              transition: 1s all cubic-bezier(.74, 0, .24, .99);

            }

          }

        }
      }

      .item-content {
        padding-top: 20px;
        padding-bottom: 10px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        text-align: center;

        h6 {
          //font-size: 1.333rem;
          //line-height: 1.867rem;
          color: #F1F0EE;
        }
      }


      &:hover {
        img {
          transform: scale(1.04) !important;
        }
      }
    }
  }
`;

export default React.memo(TilesBox);














