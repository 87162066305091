import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import homeReducer from '../redux/home'
import aboutReducer from '../redux/about'
import insightReducer from '../redux/insights'
import productReducer from '../redux/product'
import contactReducer from '../redux/contact'
import categoryReducer from '../redux/category'
import categoryReducerLight from '../redux/category/indexLight'
import categoryReducerTile from '../redux/category/indexTile'
import brandsReducer from '../redux/brands'
import upcomingReducer from '../redux/upcoming'
import searchReducer from '../redux/search'
import newsletterReducer from '../redux/newsletter'

const store = configureStore({
    reducer: {
        posts: postReducer,
        home: homeReducer,
        about: aboutReducer,
        insight: insightReducer,
        product: productReducer,
        contact: contactReducer,
        category: categoryReducer,
        categoryLight: categoryReducerLight,
        categoryTile: categoryReducerTile,
        brands: brandsReducer,
        upcoming: upcomingReducer,
        search: searchReducer,
        newsletter: newsletterReducer,

    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

