import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Img} from "./Img";
import {Link} from "react-router-dom";
import gsap from 'gsap';

const ProjectBox = ({img, title, link, katha, type, location, data}) => {
    return (

        <StyledProjectBox>
            <div className="wrapper">
                <Link to={`/product/${data?.post_name}`}>
                    <div className="all-merge">
                        <div className="item-image-slide">
                            {
                                data?.product_stock ?
                                    <p className="stock">Out of Stock</p>
                                    : ''
                            }
                            <div className="slide_1 img-wrapper">
                                <Img src={data?.thumbnail}/>
                            </div>
                            <div className="slide_2 img-wrapper">
                                <Img src={data?.hover}/>
                            </div>
                        </div>
                        <div className="item-content">
                            <div className="terms">
                                <ul>
                                    <li className={'meta-font'}>{data?.brand?.[0]?.name}</li>
                                    <li className={'meta-font'}>{data?.origin?.[0]?.name}</li>
                                </ul>
                                <p className={'meta-font'}>{data?.type?.[0]?.name}</p>
                            </div>
                            <p className="p1-font">{data?.post_title}</p>

                        </div>
                    </div>
                </Link>
            </div>
        </StyledProjectBox>

    )
};


const StyledProjectBox = styled.div`

  .wrapper {
    .all-merge {
      overflow: hidden;

      .item-image-slide {
        padding-top: calc(370 / 370 * 100%);
        position: relative;
        overflow: hidden;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        .stock{
          position:absolute;
          bottom:35px;
          left: 50%;
          transform: translate(-50%, 50%);
          margin: 0 auto;
          text-align:center;
          z-index: 3;
          background: #AC835E;
          border-radius:20px;
          padding: 3px 10px;
          color: white;
          font-size:12px;
        }
        .img-wrapper {
          position: absolute;
          inset: 0;
          overflow: hidden;

          .global-image {
            overflow: hidden;
          }

          img {
            overflow: hidden;
          }

          &:first-child {
            z-index: 1;
            transition: 1s all cubic-bezier(.74, 0, .24, .99);

            img {
              transition: 1s all cubic-bezier(.74, 0, .24, .99);

            }

          }

          &:last-child {
            z-index: 0;
            transform: translateY(50px);
            transition: 0.5s all cubic-bezier(.74, 0, .24, .99);
          }
        }
      }

      .item-content {
        padding: 20px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-left: 1px solid #F1F0EE;
        border-right: 1px solid #F1F0EE;
        border-bottom: 1px solid #F1F0EE;

        .terms {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;

          ul {
            display: flex;

            li {
              color: #818181;
              position: relative;
              padding-right: 14px;
              padding-left: 14px;


              &:after {
                content: '';
                position: absolute;
                right: -1px;
                top: 5px;
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background: #818181;
              }

              &:first-child {
                padding-left: 0;
              }

              &:last-child {
                padding-right: 0;

                &:after {
                  display: none;
                }
              }
            }
          }

          .meta-font {
            color: #818181;

          }

        }

        .p1-font {
          color: #F1F0EE;
        }
      }


      &:hover {
        .img-wrapper {
          &:first-child {
            transform: translateY(-100%);

            img {
              border-radius: 20px;
            }
          }

          &:last-child {
            transform: translate(0);
          }
        }
      }
    }
  }

`;

export default React.memo(ProjectBox);














