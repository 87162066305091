import React, {useEffect,useState,useRef} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory, Link, useLocation
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail, fetchPosts} from "../../api/redux/category/indexLight";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import SelectField from "../../components/brands/SelectField";
import ProjectBox from "../../components/ProjectBox";
import InnerBanner from "../../components/InnerBanner";
import OverviewInner from "../../components/products/OverviewInner";
import Cta from "../../components/Cta";
import RelatedCategory from "../../components/RelatedCategory";
import CateogoriesOverviewInner from "../../components/products/CateogoriesOverviewInner";
import BrandSlider from "../../components/BrandSlider";
import BuildHome from "../../components/BuildHome";
import gsap, {CSSPlugin, TimelineLite} from "gsap";
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Select, {components} from "react-select";
import {text} from "../../styles/globalStyleVars";
gsap.registerPlugin(CSSPlugin,ScrollToPlugin,ScrollTrigger);


const Cateogries = () => {



    let [offset, setOffset] = useState(90)
    const router = useLocation();
    const pathname = router?.pathname;
    const title = pathname && pathname.substring(1);





    const dispath = useDispatch()
    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CATEGORYLights
        dispath(fetchPosts([api_url ]))
    }, [dispath,, router?.pathname])

    let getPost = useSelector(state => state.categoryLight)


    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container').offsetLeft + 0)
        }

    }, [getPost, router?.pathname])


    // for close menu
    let getMobileMenuBar = document.querySelector('.main-menu-mobile');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');
    let tl2 = new TimelineLite();
    const closeDelstopMenu = () => {
        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })
    }

    useEffect(() => {

        document.querySelector('.main-menu').classList.remove('menu-is-open')
        closeDelstopMenu()
        // disableScroll.off()
        if (document.body.classList.contains('overflow')) {
            document.body.classList.remove('overflow')
        }
        document.querySelector('body').classList.remove('menu-is-open')


        getMobileMenuBar?.classList.remove('menu-open')
        document.body?.classList.remove('menu-on')
        document.querySelector('.main-menu-mobile').classList.remove('menu-is-open')
        document.body?.classList.remove('stop-scroll')
        if (document.querySelector('.main-item.active')) {
            getItemsParent.classList.remove('active')
            document.querySelector('.main-item.active').classList.remove('active')
        }

        tl2.to(getItemsParent, {
            duration: .2,
            x: '100%'
        }).to(getItemsParent, {
            duration: .2,
            display: 'none'
        })

    },[getPost, router?.pathname])



    const pinRef = useRef()
    // Update the scrollTrigger according to the current scroll direction
    useEffect(() => {
        const tl = gsap.timeline();
        if(window.innerWidth > 767 && pinRef?.current){

            let ctx = gsap.context(() => {
                // Pinning at top: 0 when scrolling down
                tl.to(pinRef?.current, {
                    scrollTrigger: {
                        id: '#scroll-nav',
                        trigger: '.scroll_to',
                        start: 'top top+=0',
                        endTrigger: pinRef?.current,
                        end: 'bottom-=250px top',
                        pin: true,
                        pinSpacing: false,
                        // onUpdate: self => {
                        //     // Check if scrolling up (direction -1)
                        //     if (self.direction === -1) {
                        //         // Update the top value to 120 when scrolling up
                        //         gsap.to('.scroll_to', { marginTop: 90, duration: 1 });
                        //
                        //     }
                        //
                        //     if  (self.direction === 1 || window.scrollY < 500){
                        //
                        //         // Reset the top value to 0 when scrolling down or not scrolling
                        //         gsap.to('.scroll_to', { marginTop: -2 , duration: 1});
                        //     }
                        // }
                    },
                });
            });
            return () => ctx.kill(); // <-- cleanup!


        }

    }, [getPost, router?.pathname]); // No need to include scrollUp and scrollDown in the dependency array

    useEffect(() => {
        if(window.innerWidth > 767){
            const links = document.querySelectorAll('.scroll_to a[href^="#"]');
            if(links){
                links.forEach(link => {
                    link.addEventListener('click', function (e) {
                        e.preventDefault();

                        const targetId = this.getAttribute('href').substring(1);
                        const targetSection = document.getElementById(targetId);

                        if (targetSection) {
                            gsap.to(window, {
                                duration: 1,
                                scrollTo: { y: "#"+targetId, offsetY: 70 },
                            });
                            ScrollTrigger.refresh()
                        }
                    });
                });

            }
        }
    }, [getPost, router?.pathname]);

    const [selectLocation, setSelectLocation] = useState('')


    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s cubic-bezier(.74,0,.24,.99)",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `${text}` : '#221F1F',
            backgroundColor: state.isSelected ? `rgba(232, 231, 231, 0.6)` : '#FFF',
            margin: 0,
            fontSize: 14,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 700 : 400,
            "&:hover": {
                backgroundColor: `${text}`,
                color: '#000',
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#191818',
            backgroundColor: state.isSelected ? `${text}` : '#191818',
            margin: 0,
            borderRadius: 0,
            fontSize: 14,
            zIndex: 10
            // width: 200,
        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#191818',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 14,
        }),

    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };
    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
            <g id="Group_23485" data-name="Group 23485" transform="translate(0.5 0.5)">
                <line id="Line_12425" data-name="Line 12425" x2="10" transform="translate(0 5)" fill="none" stroke="#f1f0ee" strokeLinecap="round" strokeWidth="1"/>
                <line id="Line_12426" data-name="Line 12426" x2="10" transform="translate(5) rotate(90)" fill="none" stroke="#f1f0ee" strokeLinecap="round" strokeWidth="1"/>
            </g>
        </svg>

            ;
    };


    const items = [
        { label: 'Lights', value: 'lights' },
        { label: 'Features', value: 'features' },
        { label: 'Brands', value: 'brands' },
        { label: 'Catalog', value: 'catalog' },
        { label: 'Products', value: 'products' },
    ];

    const handBrand = (e) => {
        setSelectLocation(e);

    }


    const page_data = getPost?.posts?.meta
    const banner = getPost?.posts?.banner
    const showcase = getPost?.posts?.showcase
    const brand = getPost?.posts?.brand
    const catalog = getPost?.posts?.catalog
    const products = getPost?.posts?.products
    const upcoming_product = getPost?.posts?.upcoming_product


    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title+' - Trade Intercontinental' : 'Trade Intercontinental'}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent ref={pinRef}>
                <InnerBanner  title={banner?.title? banner?.title:'Lights'} subtext={banner?.desc}
                             img={banner?.image}/>


                <section className={`scroll_to `}>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <ul>
                                    <li>
                                        <a href="#lights">Lights</a>
                                    </li>
                                    <li>
                                        <a href="#features">Features</a>
                                    </li>
                                    <li>
                                        <a href="#brands">Brands</a>
                                    </li>
                                    <li>
                                        <a href="#catalog">Catalog</a>
                                    </li>
                                    <li>
                                        <a href="#products">Products</a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="mobile-filter">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <SelectField selection={items}/>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {
                    showcase?.list && showcase?.list?.length  > 0 &&
                    <div id="lights">
                        <CateogoriesOverviewInner padding={`${showcase?.list?.length > 0 ? 'pb-200 pt-200' : 'pb-200'}`} data={showcase} />
                    </div>
                }

                {
                    brand &&
                    <div id={'brands'}>
                        <BrandSlider data={brand} offset={offset} padding={'pb-200'}/>
                    </div>
                }

                {
                    catalog &&
                    <div id={'catalog'}>
                        <Cta data={catalog}/>
                    </div>
                }


                <div id={'products'}>
                    {
                        products?.length > 0 &&
                        <RelatedCategory data={products}/>

                    }
                    <BuildHome data={upcoming_product} padding={`${products?.length > 0 ? 'pb-200' : 'pb-200 pt-200'}`} offset={offset}/>
                </div>

            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

  .scroll_to {
    background: #1a181b;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        margin-right: 60px;

        &:last-child {
          margin-right: 0;
        }

        a {
          font-size: 1rem; /* 15px / 15px = 1rem */
          font-weight: 600;
          line-height: 1.3333rem; /* 20px / 15px = 1.3333rem */
          letter-spacing: -0.05rem;
          color: #818181;
        }
      }
    }

    @media(max-width: 767px){
      display: none;
    }
  }
  .mobile-filter{
    display: none;
    @media(max-width: 767px){
      display: block;
      position: sticky;
      top: 0;
      z-index: 9999;
    }
  }
`;

export default Cateogries;
