import React, {useEffect,useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {useParams, useLocation} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail, fetchPosts} from "../../api/redux/upcoming";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import SelectField from "../../components/SelectField";
import ProjectBox from "../../components/ProjectBox";
import {Img} from "../../components/Img";
import Button from "../../components/Button";
import gsap, {CSSPlugin, TimelineLite} from "gsap";
import ProductModal from "../../components/ProductModal";
import NoProduct from "../../components/svg/NoProduct";
gsap.registerPlugin(CSSPlugin);

const UpcomingProduct = () => {

    const dispath = useDispatch()
    const history = useLocation();





    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
    }


    let {slug} = useParams();
    // api call
    useEffect(() => {
        let api_url = apiEndPoints.UPCOMING
        dispath(fetchPosts([api_url]))
    }, [dispath, history])

    let getPost = useSelector(state => state.upcoming)


    const page_data = getPost?.posts?.data?.meta
    const list = getPost?.posts?.data?.list

    // for close menu
    let getMobileMenuBar = document.querySelector('.main-menu-mobile');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');
    let tl2 = new TimelineLite();
    const closeDelstopMenu = () => {
        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })
    }

    useEffect(() => {

        document.querySelector('.main-menu').classList.remove('menu-is-open')
        closeDelstopMenu()
        // disableScroll.off()
        if (document.body.classList.contains('overflow')) {
            document.body.classList.remove('overflow')
        }
        document.querySelector('body').classList.remove('menu-is-open')


        getMobileMenuBar?.classList.remove('menu-open')
        document.body?.classList.remove('menu-on')
        document.querySelector('.main-menu-mobile').classList.remove('menu-is-open')
        document.body?.classList.remove('stop-scroll')
        if (document.querySelector('.main-item.active')) {
            getItemsParent.classList.remove('active')
            document.querySelector('.main-item.active').classList.remove('active')
        }

        tl2.to(getItemsParent, {
            duration: .2,
            x: '100%'
        }).to(getItemsParent, {
            duration: .2,
            display: 'none'
        })

    }, [getPost])


    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title+' - Trade Intercontinental' : 'Trade Intercontinental'}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>
                }
            </Helmet>
            <ProductModal show={show} data={popupId} handleClose={handleClose}/>

            <StyledComponent>
                <Container>
                    <Row>
                        <Col md={12} className={'text-center'}>
                            <h2>UPCOMING PRODUCTS</h2>
                        </Col>
                    </Row>

                    <Row className={'first-row'}>
                        <Col md={{span: 3, offset: 2}} className={'single-items'}>
                            <p className="p1-font">Name</p>
                        </Col>
                        <Col md={2} className={'single-items'}>
                            <p className="p1-font">Category</p>
                        </Col>
                        <Col md={2} className={'single-items'}>
                            <p className="p1-font">Brand</p>
                        </Col>
                        <Col md={3} className={'single-items'}>
                            <p className="p1-font">ETA</p>
                        </Col>
                    </Row>

                    {
                        list && list?.length > 0 &&
                        list?.map((e,index) => {
                            return(
                                <Row key={index} className={'d-flex align-items-center single-item-mains'}>
                                    <Col md={2} className={'main-image-wrapper'}>
                                        <div className="img-single-wrp">
                                            <Img src={e?.thumbnail}/>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="d-flex name-value">
                                            {
                                                window?.innerWidth <= 767 &&
                                                <p className={'p1-font color'}>Name</p>
                                            }
                                            {
                                                e?.post_title &&
                                                <p>{e?.post_title}</p>

                                            }
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className="d-flex category-value">
                                            {
                                                window?.innerWidth <= 767 &&
                                                <p className={'p1-font color'}>Category</p>
                                            }
                                            <p>{
                                                e?.type?.[0].term_id != 33 && e?.type?.[0] &&
                                                e?.type?.[0]?.name
                                            }</p>
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className="d-flex logo-value">
                                            {
                                                window?.innerWidth <= 767 &&
                                                <p className={'p1-font color'}>Brand</p>
                                            }

                                            {
                                                e?.logo &&
                                                <img className={'brand-logo'} src={e?.logo}
                                                     alt=""/>
                                            }


                                        </div>
                                    </Col>
                                    <Col md={1}>
                                        <div className="d-flex eta-value">
                                            {
                                                window?.innerWidth <= 767 &&
                                                <p className={'p1-font color'}>ETA</p>
                                            }
                                            <p>{e?.post_excerpt ? e?.post_excerpt : 'December\n' +
                                                '                                                2023'} </p>
                                        </div>
                                    </Col>
                                    <Col md={2} className={'d-flex justify-content-end'}>
                                        <div className="button" onClick={() => handleShow(e)}>

                                            <Button color={'#F1F0EE'} borderColor={'#F1F0EE'} text={'Get Update'}/>
                                        </div>
                                    </Col>
                                </Row>

                            )
                        })
                    }
                </Container>

                {
                    list && list?.length <= 0 &&
                    <div data-lag={0.5}>
                        <NoProduct/>
                    </div>
                }
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
  padding-top: 200px;
  padding-bottom: 140px;

  h2 {
    text-transform: uppercase;
    margin-bottom: 60px;
  }

  .img-single-wrp {
    position: relative;
    padding-top: calc(130 / 130 * 100%);
  }

  .first-row {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 40px;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      width: calc(100% - 30px);
      margin: 0 auto;
      background: #F1F0EE;
    }
  }

  .single-item-mains {
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 40px;

    .main-image-wrapper {
      padding-right: 30px;
    }

    &:last-child {
      margin-bottom: 0;

      &:after {
        display: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      width: calc(100% - 30px);
      margin: 0 auto;
      background: #F1F0EE;
    }
  }

  @media (max-width: 992px) {
    .d-flex.justify-content-end.col-md-2 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 767px) {
    padding-top: 120px;
    padding-bottom: 80px;
    .first-row {
      display: none;
    }

    .single-item-mains {
      .d-flex.justify-content-end.col-md-2 {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: flex-start !important;
      }

      .p1-font.color {
        color: #818181;
        flex: 0 0 30%;
      }

      .name-value, .category-value, .logo-value, .eta-value {
        margin-bottom: 20px;
        margin-top: 20px;
      }

      .main-image-wrapper {
        padding-right: 15px;
        flex: 0 0 45%;
        max-width: 45%;
      }

    }
  }

`;

export default UpcomingProduct;
