import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory, Link, useLocation
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail, fetchPosts} from "../../api/redux/product";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import SelectField from "../../components/SelectField";
import ProjectBox from "../../components/ProjectBox";
import gsap, {CSSPlugin, TimelineLite} from "gsap";
import {ApiParam as ApiParams} from "../../api/network/apiParams";
gsap.registerPlugin(CSSPlugin);

const Lights = () => {

    const dispath = useDispatch()






    const history = useLocation();
    const queryParams = new URLSearchParams(history.search);
    const typeParam = queryParams.get('type');
    const [selectedOrigin, setSelectedOrigin] = useState('');
    const [selectedLight, setSelectedLight] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');


    const [enabledType, setEnabledType] = useState(true);
    const [enabledBrand, setEnabledBrand] = useState(false);
    const [enabledOrigin, setEnabledOrigin] = useState(false);


    // Callback function to handle value change
    const handleSelectChange = (value, type, router) => {


        if(value?.slug === 'all'){
            let api_url = apiEndPoints.PRODUCT
            let param = {
                [ApiParams.type]: 'lights',

            };
            dispath(fetchPosts([api_url, param]))
        }else{
            switch (type) {
                case 'light_type':
                    setSelectedBrand('')
                    setSelectedOrigin('')
                    setEnabledBrand(true)
                    break;
                case 'brand':
                    setEnabledOrigin(true)
                    setSelectedOrigin('')

                    break;
                case 'origin':
                    setSelectedBrand('')
                    setSelectedOrigin('')
                    setEnabledBrand(false)
                    setEnabledOrigin(false)
                    setEnabledType(true)

                    break;
                default:
                    setEnabledType(true)
                    setEnabledBrand(false)
                    setEnabledOrigin(false)
                    break;
            }

            // Construct the updated API URL
            let api_url = apiEndPoints.PRODUCT;
            let param = {
                [ApiParams.type]: type === 'type' ? 'lights' : 'lights',
                [ApiParams.light_type]: type === 'light_type' ? selectedLight ? selectedLight : value?.slug : selectedLight,
                [ApiParams.brand]: type === 'brand' ? selectedBrand ? selectedBrand : value?.slug : selectedBrand,
                [ApiParams.origin]: type === 'origin' ? selectedOrigin ? selectedOrigin : value?.slug : selectedOrigin,
            };



            // Update the route using React Router's history object
            // const updatedRoute = `/product?${type}=${param[type]}`;
            // history.push(updatedRoute);
            dispath(fetchPosts([api_url,param]))
        }




    };

    // api call
    useEffect(() => {

        let api_url = apiEndPoints.PRODUCT
        let param = {
            [ApiParams.type]: 'lights',
            [ApiParams.light_type]:  selectedLight || '',
            [ApiParams.brand]: selectedBrand || '',
            [ApiParams.origin]: selectedOrigin ||  '',
        };
        dispath(fetchPosts([api_url,param]))
    }, [history,dispath])

    let getPost = useSelector(state => state.product)

    const filter = getPost?.posts?.filter
    const list = getPost?.posts?.list
    const title = filter?.type?.find(f => f?.slug === typeParam);

    // for close menu
    let getMobileMenuBar = document.querySelector('.main-menu-mobile');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');
    let tl2 = new TimelineLite();
    const closeDelstopMenu = () => {
        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })
    }

    useEffect(() => {

        document.querySelector('.main-menu').classList.remove('menu-is-open')
        closeDelstopMenu()
        // disableScroll.off()
        if (document.body.classList.contains('overflow')) {
            document.body.classList.remove('overflow')
        }

        document.querySelector('body').classList.remove('menu-is-open')

        getMobileMenuBar?.classList.remove('menu-open')
        document.body?.classList.remove('menu-on')
        document.querySelector('.main-menu-mobile').classList.remove('menu-is-open')
        document.body?.classList.remove('stop-scroll')
        if (document.querySelector('.main-item.active')) {
            getItemsParent.classList.remove('active')
            document.querySelector('.main-item.active').classList.remove('active')
        }

        tl2.to(getItemsParent, {
            duration: .2,
            x: '100%'
        }).to(getItemsParent, {
            duration: .2,
            display: 'none'
        })

    }, [getPost])




    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Lights - Trade Intercontinental</title>
                <meta name="description" content="Trade Intercontinental is InnStar’s tallest commercial building until date. Boasting 5 basements and 32 floors above ground, it’s not just a high rise building but rather a modern day sculpture. This project has been officially Pre-Certified USGBC LEED Platinum, which is a testament to InnStar’s goal to reduce carbon footprint and be environmentally conscience."/>
            </Helmet>

            <StyledComponent>
                <Container>
                    <Row>
                        <Col md={12} className={'text-center'}>
                            <h1>{`${title ? 'Lights' : 'Lights' }`}</h1>
                        </Col>
                    </Row>
                    <Row className={'filter'}>
                        <Col md={4}>
                            <SelectField    disabled={enabledType ? false : true} onChange={e => {
                                setSelectedLight(e?.slug)
                                handleSelectChange(e,'light_type');
                            }} placeholder={'Type'} selection={filter?.light}/>
                        </Col>
                        <Col md={4}>
                            <SelectField   disabled={enabledBrand ? false : true} onChange={e => {
                                setSelectedBrand(e?.slug)
                                handleSelectChange(e,'brand');
                            }} placeholder={'Brand'} selection={filter?.brand}/>
                        </Col>


                        <Col md={4}>
                            <SelectField   disabled={enabledOrigin ? false : true}  onChange={e => {
                                setSelectedOrigin(e?.slug)
                                handleSelectChange(e,'origin');
                            }}  placeholder={'Origin'} selection={filter?.origin}/>
                        </Col>
                    </Row>
                    <Row>
                        {
                            list && list?.length > 0 &&
                            list?.map((e,index) => {
                                return(
                                    <Col md={4} key={index} className={'single-items'}>
                                        <ProjectBox data={e}/>
                                    </Col>
                                )
                            })
                        }


                    </Row>
                </Container>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
  padding-top: 200px;
  padding-bottom: 140px;

  h1 {
    text-transform: uppercase;
    margin-bottom: 60px;
  }
  .filter{
    .col-md-2{
      max-width: 33.33333333%;
      flex: 0 0 33.33333333%;
    }
    margin-bottom: 60px;
  }

  .single-items{
    margin-bottom: 60px;
  }
  
  
  @media(max-width: 767px){
    padding-top: 120px;
    padding-bottom: 80px;
    h1{
      font-size: 3.933rem;
      line-height: 4.2rem;
    }
    .single-items{
      margin-bottom: 40px;
    }

    .filter{
      .col-md-2{
        max-width: 100%;
        flex: 0 0 100%;
      }
      margin-bottom: 60px;
    }
  }
  
`;

export default Lights;
