import React, {useEffect, useState, memo} from 'react';
import styled from "styled-components";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hover} from '../styles/globalStyleVars';
import {gsap} from "gsap";
import Logo from "./svg/Logo";
import Social from "./Social";
import SubscribeForm from "./SubscribeForm";
import ScrollTrigger from 'gsap/ScrollTrigger';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';

gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin);

const FooterXom = (offset) => {


    useEffect(() => {

        // Add click event to all links
        const allLinks = document.querySelectorAll('a[href*="#"]');
        if (!allLinks.length) {
            return;
        }

        allLinks.forEach(link => {
            link.addEventListener('click', function (event) {
                // event.preventDefault(); // Prevent the default link behavior (navigating to the href)

                const href = link.getAttribute('href');
                if (!href || !href.includes('#')) {
                    return;
                }

                const targetSelector = href.slice(href.indexOf('#')); // Extract the # value from the href
                const targetElement = document.querySelector(targetSelector);

                if (targetElement) {
                    setTimeout(() => {


                        // Revert body overflow to its original value on blur
                        document.body.style.overflow = 'auto';
                        const html = document.querySelector('html');
                        html.style.overflow = 'auto';

                        // Use GSAP ScrollTo plugin to smoothly scroll to the target section
                        gsap.to(window, {
                            duration: 1,
                            scrollTo: {y: targetElement.offsetTop - 100} // Adjust the offset as needed
                        });
                    }, 600)
                }

                // if (!targetElement) {
                //     console.error(`Target element with selector '${targetSelector}' not found.`);
                //     // return;
                // }


            });
        });
    }, [])


    return (
        <>

            <Footer className={'footer'}>
                <div className="noise"></div>

                <div className="footer__top">
                    <Container>
                        <Row>
                            <Col md={9}>
                                <Logo/>
                            </Col>
                            <Col md={3}>
                                <Social/>
                            </Col>

                            <Col md={3}>
                                <div className="footer__top__info">
                                    <p className="phone">
                                        <span>Find Us</span>
                                        <a href="https://maps.app.goo.gl/F9FreY1m7bF8mMu4A" target={'_blank'}>
                                            3rd Floor, 214/D, Navana Osman,<br/> Tejgaon Gulshan Link Road,<br/>
                                            Dhaka - 1215
                                        </a>
                                        <a href={'mailto:info@ticbd.com'}>
                                            info@ticbd.com
                                        </a>
                                        <a href={'tel:+8801773253467'}>
                                            +8801773253467
                                        </a>
                                    </p>


                                </div>
                            </Col>
                            <Col md={3} className={'footer__top_col border'}>
                                <div className="footer__top__menu">
                                    <div className="hide-desktop">
                                        <p>Trade Intercontinental</p>
                                        <ul>
                                            <li><Link to={'/about'}>About Us</Link></li>
                                            <li><Link to={'/insights'}>Insights</Link></li>
                                            {/*<li><Link to={'/categories'}>Categories</Link></li>*/}
                                            <li><Link to={'/contacts'}>Contact</Link></li>
                                        </ul>

                                    </div>

                                </div>
                            </Col>
                            <Col md={3} className={'footer__top_col border'}>
                                <div className="footer__top__menu">
                                    <div className="hide-desktop">
                                        <p>Products</p>
                                        <ul>
                                            <li><Link to={'/furniture'}>Furniture</Link></li>
                                            <li><Link to={'/lights'}>Lights</Link></li>
                                            <li><Link to={'/tiles'}>Tiles</Link></li>
                                            <li><Link to={'/upcoming-products'}>Upcoming Products</Link></li>
                                            {/*<li><Link to={'/no-products'}>No Product Found</Link></li>*/}
                                        </ul>
                                    </div>

                                </div>

                            </Col>
                            <Col md={3} className={'footer__top_col'}>
                                <div className="footer__top__menu">

                                    <div className="hide-desktop">
                                        <p>Subscribe</p>
                                        <SubscribeForm/>
                                    </div>

                                </div>

                            </Col>

                        </Row>
                    </Container>
                </div>
                <div className="footer__bottom">
                    <Container>
                        <Row>
                            <Col md={6}>
                                <p className={'footer_title_bottom'}><span>Sister Concerns</span></p>
                                <p className={'white'}><a target={'blank'} href={'https://www.innstarbd.com'}>InnStar
                                    Ltd</a></p>
                                <p className={'white'}><a target={'blank'} href={'http://spaceapartments.com/'}>Space
                                    Apartments (Hotel)</a></p>
                            </Col>
                            <Col md={3} className={'one'}>
                                <p>© {new Date().getFullYear()} Trade Intercontinental. <br/> All Rights Reserved.</p>

                            </Col>
                            <Col md={3} className={'two'}>

                                <p><a target={'blank'} href={'https://dcastalia.com'}>Design & Developed by
                                    Dcastalia</a></p>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </Footer>
        </>
    );
};

const Footer = styled.section`
  position: relative;
  overflow: hidden;
  padding-top: 120px;
  //border-top: 1px solid #F1F0EE;
  .noise {
    position: absolute;
    inset: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #1A181B;
    transition: all .4s cubic-bezier(.74, 0, .24, .99);

    &:after {
      content: '';
      background-image: url('/images/static/background-noise.png');
      background-blend-mode: multiply;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      opacity: 0.2;
    }
  }
  .content_notices {
    p {
      margin: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .footer {
    &__top {

      .container {
        position: relative;
        padding: 0 15px 0;

        &:before {
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          //content: '';
          width: calc(100% - 30px);
          margin: 0 auto;
          height: 1px;
          background: rgba(247, 243, 240, 0.3);
          @media (max-width: 767px) {
            display: none;
          }
        }
      }


      &__info {
        p {
          margin-bottom: 40px;
          font-size: 1rem; /* 15px / 15px = 1rem */
          font-weight: 600;
          line-height: 1.5rem; /* 20px / 15px = 1.3333rem */
          color: #818181;

          &:last-child {
            margin-bottom: 0;
          }

          span {
            font-size: 1rem; /* 15px / 15px = 1rem */
            font-weight: 600;
            line-height: 1.5rem; /* 20px / 15px = 1.3333rem */
            /* 20px / 15px = 1.3333rem */
            color: #818181;
            margin-bottom: 30px;
            display: block;
          }

          a {
            display: block;
            color: #F1F0EE;
            font-size: 1rem; /* 15px / 15px = 1rem */
            font-weight: 600;
            line-height: 1.5rem; /* 20px / 15px = 1.3333rem */
            margin-bottom: 30px;

            &:nth-child(3) {
              margin-bottom: 10px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .border {
        border: none !important;
      }

      &__menu {
        p {
          font-size: 1rem; /* 15px / 15px = 1rem */
          font-weight: 600;
          line-height: 1rem; /* 20px / 15px = 1.3333rem */
          color: #818181;
          margin-bottom: 30px;
        }

        ul {
          li {
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              display: inline-flex;
              align-items: flex-start;
              font-size: 1rem; /* 15px / 15px = 1rem */
              font-weight: 600;
              line-height: 1rem; /* 20px / 15px = 1.3333rem */
              color: #F1F0EE;

              &:hover {
                color: ${hover} !important;

                p {
                  color: ${hover} !important;
                }

                svg {
                  path {
                    fill: ${hover};
                  }
                }
              }
            }
          }
        }
      }

      .social {
        margin-bottom: 60px;
      }
    }
    &__bottom {
      padding: 60px 0 30px;
      position: relative;
      .footer_title_bottom {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1rem;
        color: rgb(129, 129, 129);
        margin-bottom: 30px;
        display: block;
      }
      @media (min-width: 1024px) {
        .row {
          display: flex;
          align-items: flex-end;
        }
      }
      p {
        color: rgba(241, 240, 238, 0.2);
        font-size: 1rem; /* 15px / 15px = 1rem */
        font-weight: 600;
        line-height: 1rem; /* 20px / 15px = 1.3333rem */

        &.white {
          color: rgb(241, 240, 238) !important;
          margin-bottom: 10px;
          &:last-child{
            margin-bottom: 0;
          }
          a {
            color: rgb(241, 240, 238) !important;
            
            &:hover{
              color: ${hover} !important;
            }
          }
        }
      }
      a {
        color: rgba(241, 240, 238, 0.2);
        font-size: 1rem; /* 15px / 15px = 1rem */
        font-weight: 600;
        line-height: 1rem; /* 20px / 15px = 1.3333rem */
      }
    }
  }
  //responsive
  .hide-mobile {
    display: none;
  }


  @media (max-width: 767px) {
    .footer__top {
      &__info {
        margin-bottom: 40px;

        p {
          span {
            margin-bottom: 20px;
          }
        }
      }

      .social {
        margin-top: 30px;
        margin-bottom: 40px;
        justify-content: flex-start;
      }

      &__menu {
        p {
          margin-bottom: 20px;
        }
      }
    }

    .footer__top_col {
      .footer__top__menu {
        margin-bottom: 40px;
      }

      &:last-child {
        .footer__top__menu {
          margin-bottom: 0;
        }
      }
    }


    .footer__bottom {
      padding-bottom: 20px;
      padding-top: 40px;

      .col-md-6 {
        margin-bottom: 20px;
      }
    }

  }



`;


export default React.memo(FooterXom);
