import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Img} from "./Img";
import {Link} from "react-router-dom";
import gsap from 'gsap';
import {hover} from './../styles/globalStyleVars'

const ProjectBox = ({data}) => {


    return (

        <StyledProjectBox>
            <div className="wrapper">
                <div className='blog-single'>
                    <div className="blog-single__inner">
                        {/*<Link to={'/blog-details'}></Link>*/}
                        <Img src={data?.image} objectFit={'cover'} layout={'fill'}/>
                        <div className="blog-single__inner__content">
                            <div className="blog-single__inner__content__top">
                                <h4>
                                    {data?.title}
                                </h4>
                                <p className="p1-fonts">
                                    {data?.desc}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledProjectBox>

    )
};


const StyledProjectBox = styled.div`

  .global-image {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #000;
      opacity: 0.3;
      height: 100%;
      width: 100%;
    }
  }

  .blog-single {
    &__inner {
      padding-top: calc(570 / 445 * 100%);
      position: relative;
      overflow: hidden;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 3;
      }

      &__content {
        //background-color: #26201E;
        //
        //&:after {
        //  opacity: 1;
        //  top: 0;
        //  transition: all .4s cubic-bezier(.74,0,.24,.99);
        //  background-image: url('/images/static/background-noise.png');
        //  background-blend-mode: multiply;
        //  width: 100%;
        //  height: 100%;
        //  position: absolute;
        //  inset: 0;
        //  opacity: 0.2;
        //  content: "";
        //}

        &__top {
          position: absolute;
          bottom: 0px;
          left: 40px;
          right: 40px;
          transition: 0.7s all cubic-bezier(.74,0,.24,.99);

          h4 {
            z-index: 2;
            color: #F1F0EE;
            margin: 0;
            transition: all .3s cubic-bezier(.74,0,.24,.99);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            margin-bottom: 20px;
          }
          p{
            height: auto;
            opacity: 0;
            visibility: hidden;
            transition-delay: 0.4s;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            transition: 0.7s all cubic-bezier(.74,0,.24,.99);
          }

        }

        &__bottom {
          position: absolute;
          margin: 0;
          left: 40px;
          right: 40px;
          bottom: 24px;
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          z-index: 2;
          align-items: flex-end;

          h2 {
            color: #C9B385;
          }

          p {
            color: #C9B385;
          }


        }
      }



      &:hover {
      
      
        .blog-single__inner__content__top {
          bottom: 40px;
          h4 {
            //opacity: 0;
            //transform: translateY(-20px);
          }

          p {
            height: auto;
            visibility: visible;
            opacity: 1;
          }
        }

        .blog-single__inner__content__bottom {
          border-color: #FFF;

          h2 {
            color: #ffffff;
          }

          p {
            color: #ffffff;

            span {
              color: #ffffff;
            }
          }
        }
      }

    }
  }

`;

export default React.memo(ProjectBox);














