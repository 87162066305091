import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";
import {Col, Container, Row} from "react-bootstrap";
import InsightBox from "../../components/InsightBox";
import gsap, {CSSPlugin, TimelineLite} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from 'gsap/SplitText';
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPosts} from "../../api/redux/insights";
import {useDispatch, useSelector} from "react-redux";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useLocation} from "react-router-dom";
gsap.registerPlugin(CSSPlugin, ScrollTrigger, SplitText);


const Insights = () => {

    const dispath = useDispatch()
    const location = useLocation()

    let [offset, setOffset] = useState(90)

    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container').offsetLeft + 0)
        }

    }, [location?.pathname])


    // for close menu
    let getMobileMenuBar = document.querySelector('.main-menu-mobile');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');
    let tl2 = new TimelineLite();
    const closeDelstopMenu = () => {
        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })
    }
    useEffect(() => {

        document.querySelector('.main-menu').classList.remove('menu-is-open')
        closeDelstopMenu()
        // disableScroll.off()
        if (document.body.classList.contains('overflow')) {
            document.body.classList.remove('overflow')
        }

        document.querySelector('body').classList.remove('menu-is-open')

        getMobileMenuBar?.classList.remove('menu-open')
        document.body?.classList.remove('menu-on')
        document.querySelector('.main-menu-mobile').classList.remove('menu-is-open')
        document.body?.classList.remove('stop-scroll')
        if (document.querySelector('.main-item.active')) {
            getItemsParent.classList.remove('active')
            document.querySelector('.main-item.active').classList.remove('active')
        }

        tl2.to(getItemsParent, {
            duration: .2,
            x: '100%'
        }).to(getItemsParent, {
            duration: .2,
            display: 'none'
        })


        ScrollTrigger.refresh()
    }, [location?.pathname])


// api call
    useEffect(() => {
        let api_url = apiEndPoints.INSIGHTSPAGE
        dispath(fetchPosts([api_url]))
    }, [dispath, location])
    let getPost = useSelector(store => store.insight)
    let page_data = getPost?.posts?.page_data?.meta;
    let inner_banner = getPost?.posts?.page_data?.banner;
    let insights = getPost?.posts?.list


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title+' - Trade Intercontinental' : 'Trade Intercontinental'}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>
            <StyledSection>
                <InnerBanner title={inner_banner?.title} subtext={inner_banner?.shortDesc}
                                  img={inner_banner?.image?.large}/>
                <section className="insight_list pt-200 pb-200">
                    <Container>
                        <Row>
                            {
                                insights && insights?.length > 0 &&
                                insights?.map((e,index) => {
                                    return(
                                        <Col md={4} key={index} className={'single-item'}>
                                            <InsightBox data={e}/>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                </section>
            </StyledSection>
        </HelmetProvider>

    );
};

const StyledSection = styled.section`
  .single-item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

`;

export default Insights;
