import React from 'react';
import styled from "styled-components";

const StoneBlack = () => {
    return (
        <StyledStoneBlack>
            <img src={'/images/static/stone_white.svg'}/>
        </StyledStoneBlack>
    );
};

const StyledStoneBlack = styled.div`
  height: 150px;
  width: 200px;
`;

export default React.memo(StoneBlack);
