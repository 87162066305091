import {Link, useLocation} from 'react-router-dom';
import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Form, Row} from "react-bootstrap";
import {hover} from "../styles/globalStyleVars";
import {useDispatch, useSelector} from "react-redux";
import {gsap, TimelineLite} from "gsap";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {ApiParam} from "../api/network/apiParams";
import {fetchData} from "../api/redux/search";
import {Helmet, HelmetProvider} from "react-helmet-async";
import ReactPaginate from 'react-paginate';

const Search = () => {


    const searchItemRef = useRef()


    // search handle
    const [searchInput, setSearchInput] = useState('')
    const handleInput = (e) => {
        setSearchInput(e.target.value);
    }

    const searchSubmit = (e) => {
        e.preventDefault()

        if (searchInput === '') {

        } else {
            window.location.href = `/search?keyword=${searchInput}`;

            document.querySelector('.search-desktop').classList.remove('search-open')
            gsap.to(searchItemRef.current, {
                duration: .4, height: 0, opacity: 0, overflow: 'hidden'
            })
            gsap.to(searchItemRef?.current?.querySelector('.container'), {
                opacity: 0,
            }, '-=.4')
            gsap.to(searchItemRef.current, {
                display: 'none'
            })
        }

        // setSearchInput('')

        // document.querySelector('.search-desktop').classList.remove('search-open')
        // gsap.to(searchItemRef.current, {
        //     duration: .4, height: 0, opacity: 0, overflow: 'hidden'
        // })
        // gsap.to(searchItemRef.current.querySelector('.container'), {
        //     opacity: 0,
        // }, '-=.4')
        // gsap.to(searchItemRef.current, {
        //     display: 'none'
        // })
    }


    const location = useLocation()


    // api call


    const dispath = useDispatch()
    const searchParams = new URLSearchParams(location.search);
    const keyword = searchParams.get('keyword') ? searchParams.get('keyword') : '';
    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SEARCH


        let param = {
            [ApiParam.KEYWORD]: keyword,
        }

        if (keyword && keyword !== '') {
            setSearchInput(keyword)
        }

        dispath(fetchData([api_url, param]))

    }, [location, dispath])

    let getSearchData = useSelector(state => state.search);


// data
//     const filterProduct = getSearchData?.data?.data?.filter(f => f?.type === 'product');
    const filterProduct = getSearchData?.posts
    const total = filterProduct.length


    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // Set the number of items per page

    const pageCount = Math.ceil(filterProduct.length / itemsPerPage);
    const offset = currentPage * itemsPerPage;

    const displayedItems = filterProduct.slice(offset, offset + itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const router = useLocation();


    // for close menu
    let getMobileMenuBar = document.querySelector('.main-menu-mobile');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');
    let getMainMenu = document.querySelector('.main-menu');
    let tl2 = new TimelineLite();
    const closeDelstopMenu = () => {
        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })
    }

    useEffect(() => {

        if(getMainMenu){
            document.querySelector('.main-menu').classList.remove('menu-is-open')
            closeDelstopMenu()
            // disableScroll.off()
            if (document.body.classList.contains('overflow')) {
                document.body.classList.remove('overflow')
            }
            document.querySelector('body').classList?.remove('menu-is-open')
        }

        if(getMobileMenuBar){
            document.querySelector('body').classList?.remove('menu-is-open')

            getMobileMenuBar?.classList.remove('menu-open')
            document.body?.classList.remove('menu-on')
            document.querySelector('.main-menu-mobile').classList.remove('menu-is-open')
            document.body?.classList.remove('stop-scroll')
            if (document.querySelector('.main-item.active')) {
                getItemsParent.classList.remove('active')
                document.querySelector('.main-item.active').classList.remove('active')
            }

            tl2.to(getItemsParent, {
                duration: .2,
                x: '100%'
            }).to(getItemsParent, {
                duration: .2,
                display: 'none'
            })
        }




    },[ router?.pathname])


    return (
        <>

            {/*{loading && <Loading/>}*/}
            <HelmetProvider>
                {/*dynamic title */}
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{searchInput} - Trade Intercontinental</title>
                    <meta name="description"
                          content="Trade Intercontinental is InnStar’s tallest commercial building until date. Boasting 5 basements and 32 floors above ground, it’s not just a high rise building but rather a modern day sculpture. This project has been officially Pre-Certified USGBC LEED Platinum, which is a testament to InnStar’s goal to reduce carbon footprint and be environmentally conscience."/>
                </Helmet>
                <StyledSearch className=''>
                    {/*{getSearchData?.loading && <Loader/>}*/}
                    <div className="search-desktop">
                        <Container className='search-desktop__top'>
                            <Form onSubmit={searchSubmit}>
                                <Form.Group className="search-input">
                                    <Form.Control type="text" value={searchInput} onBlur={e => handleInput(e)}
                                                  onChange={e => handleInput(e)}
                                                  placeholder={'Search'}/>
                                    <button type='submit'>Search</button>
                                </Form.Group>
                            </Form>
                        </Container>
                    </div>

                    <div className="search-result pt-100 pb-160">
                        <Container>
                            <Row>
                                <Col>
                                    <h3>{keyword && keyword !== '' ? 'Are you looking for : ' + keyword + ' (' + total + ')' : 'Nothing Found' + keyword}</h3>
                                </Col>

                                <Col md={12}>
                                    <div className="search-result-wrap">

                                        {displayedItems.length > 0 ? (
                                            displayedItems.map((item, index) => (
                                                <div key={index} className={`fade-up search-result__single d-flex`}>
                                                    {/* Your existing Link logic here */}
                                                    <Link to={item?.slug}></Link>
                                                    <Col sm={6} className="search-result__single__content p-0">
                                                        <p>{item?.name}</p>
                                                    </Col>
                                                </div>
                                            ))
                                        ) : (
                                            <p>It seems we can’t find what you’re looking for. Perhaps searching can
                                                help.</p>
                                        )}

                                        {pageCount > 1 && (
                                            <ReactPaginate
                                                previousLabel={'Previous'}
                                                nextLabel={'Next'}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageChange}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                            />
                                        )}


                                    </div>

                                </Col>


                            </Row>
                        </Container>
                    </div>

                </StyledSearch>
            </HelmetProvider>
        </>
    );
};

const StyledSearch = styled.section`
  .search-desktop {

    padding: 200px 0 60px 0;
    background-color: transparent;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    //position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 100px;
    //height: 0;
    //display: none;
    //opacity: 0;
    //overflow: hidden;
    z-index: 9;

    
    .container {
      position: relative;
      //opacity: 0;
    }

    svg {
      position: absolute;
      top: -25px;
      right: -14px;
      font-size: 22px;
      cursor: pointer;

      &:hover {
        color: ${hover};
      }
    }

    form {
      min-width: 100%;

      .search-input {
        position: relative;

        button, p {
          box-shadow: none;
          border: none;
          background-color: transparent;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: fit-content;
          font-size: 12px;
          line-height: 18px;
          color: #F9F9F9;
          transition: color .3s cubic-bezier(.54,.32,.05,.96);
          cursor: pointer;

          &:hover {
            color: ${hover};
          }
        }

        img {
          position: absolute !important;
          bottom: 19px;
          margin: auto;
        }

        .form-control {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #F9F9F9;
          padding-left: 0px;
          padding-bottom: 9px;
          padding-top: 0;
          height: 50px;
          font-size: 32px;
          line-height: 36px;
          font-weight: 500;
          color: #F9F9F9;
          background-color: transparent;

          ::placeholder {
            font-size: 32px;
            line-height: 36px;
            font-weight: 600;
            color: rgba(34, 31, 31, 0.20);
          }
        }

      }
    }

    .search-desktop__menu {
      margin-top: 23px;
      min-width: 100%;

      p {
        font-size: 12px;
        color: ${hover};
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 13px;

      }

      ul {
        display: inline-flex;

        li {
          a {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: #F9F9F9;
            display: flex;
            margin-right: 30px;
          }

          &:nth-last-child(1) {
            a {
              margin-right: 0;
            }
          }
        }
      }

    }

    @media (max-width: 992px) {
      padding: 120px 0 60px 0 !important;
    }
  }
  h3{
    margin-bottom: 60px;
  }
  .search-result-wrap {
    width: 100%;
    padding-bottom: 60px;  
  }

  .search-result {
    &__single {
      position: relative;
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.30);

      //&:nth-last-of-type(1) {
      //  margin-bottom: 0;
      //  padding-bottom: 0;
      //  border-bottom: 0;
      //}

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 2;
      }

      &__content {
        h2 {
          font-size: 26px;
          font-weight: bold;
          line-height: 32px;
          margin: 0 0 26px 0;
          transition: color .4s cubic-bezier(.54,.32,.05,.96);

        }

        p {
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }


      &__img {
        height: 160px;

        div {
          width: 100%;
          height: 100%;
        }

        img {
          position: static;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }

      &:hover {
        h2 {
          color: ${hover};
        }
      }
    }
  }

  @media (max-width: 550px) {
    .search-desktop form .search-input .form-control {
      padding-left: 30px;
      font-size: 20px;
      line-height: 25;
      padding-right: 0px;

      &::placeholder {
        font-size: 20px;
        line-height: 25;
      }
    }

    .search-result__single {
      flex-wrap: wrap;
      flex-direction: column-reverse;

      &__content {
        min-width: 100%;
      }

      &__img {
        min-width: 100%;
        margin: 0 0 20px 0;

        span {
          max-width: 200px !important;
          min-width: 200px !important;

          img {
            max-width: fit-content !important;
            min-width: fit-content !important;
            margin: 0 !important;
            left: 15px !important;
          }
        }
      }
    }

  }



`;


export default React.memo(Search);
