    import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Cta from "../../components/Cta";
import InsightSlider from "../../components/InsightSlider";
import BuildHome from "../../components/BuildHome";
import Video from "../../components/Video";
import BrandSlider from "../../components/BrandSlider";
import OverviewInner from "../../components/OverviewInner";
import DiscoverSlider from "../../components/DiscoverSlider";
import Banner from "../../components/Banner";
import BannerMobile from "../../components/BannerMobile";
import gsap, {CSSPlugin, TimelineLite} from "gsap";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useSelector, useDispatch} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPosts} from "../../api/redux/home";
import {SplitText} from "gsap/SplitText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useLocation} from "react-router-dom";
gsap.registerPlugin(ScrollTrigger, CSSPlugin, SplitText);

const Home = () => {

    const dispath = useDispatch()
    const location = useLocation()
    let [offset, setOffset] = useState(90)



    // api call
    useEffect(() => {
        let api_url = apiEndPoints.HOME
        dispath(fetchPosts([api_url]))
    }, [dispath, location?.pathname])


    let getPost = useSelector(store => store.home)


    let page_data = getPost?.posts?.[0]?.meta;
    const banner = getPost?.posts?.[0]?.banner;
    const overview = getPost?.posts?.[0]?.overview;
    const video = getPost?.posts?.[0]?.video;
    const brand = getPost?.posts?.[0]?.brand;
    const discover = getPost?.posts?.[0]?.discover;
    const upcoming_product = getPost?.posts?.[0]?.upcoming_product;
    const insights = getPost?.posts?.[0]?.insights;
    const cta = getPost?.posts?.[0]?.cta;



    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container').offsetLeft + 0)
        }
    }, [offset,getPost,location?.pathname])

    useEffect(() => {

        gsap.utils.toArray('.split-up').forEach((item, i) => {
            const parentSplit = new SplitText(item, {
                linesClass: "split-parent"
            })
            const childSplit = new SplitText(item, {
                type: "lines, chars",
                linesClass: "split-child"
            })
            gsap.from(parentSplit.lines, {
                duration: 1,
                yPercent: 100,
                // stagger: 0.1,
                scrollTrigger: {
                    trigger: item,
                    toggleActions: "restart none none reset",
                    // start: "top 60%",
                    // markers: true
                }
            })
        });


        const box = document.querySelectorAll('.box');
        const boxplus = document.querySelectorAll('.boxr');
        const cta = document.querySelectorAll('.cta');
        const box_up = document.querySelectorAll('.box-up');
        const box_down = document.querySelectorAll('.box-down');



        if (window.innerWidth > 767) {
            box.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_up.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            box_down.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 250,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            boxplus.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: 150,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
            cta.forEach((el, index) => {
                gsap.fromTo(el, {
                    y: -50,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        scrub: true
                    }
                });
            });
        }

    }, [getPost])
    // for close menu
    let getMobileMenuBar = document.querySelector('.main-menu-mobile');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');
    let tl2 = new TimelineLite();
    const closeDelstopMenu = () => {
        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })
    }

    useEffect(() => {
        document.querySelector('body').classList.remove('menu-is-open')

        document.querySelector('.main-menu').classList.remove('menu-is-open')
        closeDelstopMenu()
        // disableScroll.off()
        if (document.body.classList.contains('overflow')) {
            document.body.classList.remove('overflow')
        }

        getMobileMenuBar?.classList.remove('menu-open')
        document.body?.classList.remove('menu-on')
        document.querySelector('.main-menu-mobile').classList.remove('menu-is-open')
        document.body?.classList.remove('stop-scroll')
        if (document.querySelector('.main-item.active')) {
            getItemsParent.classList.remove('active')
            document.querySelector('.main-item.active').classList.remove('active')
        }

        tl2.to(getItemsParent, {
            duration: .2,
            x: '100%'
        }).to(getItemsParent, {
            duration: .2,
            display: 'none'
        })

    }, [ getPost, location?.pathname])



    return (
      <>
          <HelmetProvider>
              <Helmet>
                  <meta charSet="utf-8"/>
                  <title>{`${page_data?.meta_title ? page_data?.meta_title : 'Trade Intercontinental'}`}</title>

                  {
                      page_data?.meta_desc &&
                      <meta name="description" content={page_data?.meta_desc}/>

                  }
                  {
                      page_data?.og_title &&
                      <meta property="og:title" content={page_data?.og_title}/>

                  }
                  {
                      page_data?.og_desc &&
                      <meta property="og:description" content={page_data?.og_desc}/>

                  }

              </Helmet>
              <StyledSection id={'home_page'}>

                  <Banner getOffset={offset} data={banner}/>

                  {
                      window?.innerWidth < 992 && banner &&
                          <BannerMobile data={banner}/>
                  }

                  <OverviewInner data={overview}/>
                  <Video data={video}/>
                  <BrandSlider data={brand} offset={offset}/>
                  <DiscoverSlider data={discover}/>
                  <BuildHome data={upcoming_product} padding={'pt-200'} offset={offset}/>
                  <InsightSlider data={insights}/>
                  <Cta getin data={cta}/>
              </StyledSection>
          </HelmetProvider>
      </>
    );
};

const StyledSection = styled.section`
  .box {
    width: 100px;
    height: 100px;
    background-color: #28a92b;
    z-index: 100;
    text-align: center;
    will-change: transform;
  }
 
`;

export default Home;
