import React from 'react';
import styled from "styled-components";

const Hamburger = () => {
    return (
        <StyledHamburger className={''}>
            <svg id="Button_-_Hamburger" data-name="Button - Hamburger" xmlns="http://www.w3.org/2000/svg" width="67" height="17" viewBox="0 0 67 17">
                <g id="Group_18783" data-name="Group 18783" transform="translate(-98 -32)">
                    <line id="Line_1" data-name="Line 1" x2="20" transform="translate(98 37)" fill="none" stroke="#f1f0ee" strokeWidth="1"/>
                    <line id="Line_2" data-name="Line 2" x2="20" transform="translate(98 44)" fill="none" stroke="#f1f0ee" strokeWidth="1"/>
                </g>
                <g id="Group_18784" data-name="Group 18783" transform="translate(-98 -32)" opacity={0}>
                    <line id="Line_1" data-name="Line 1" x2="20" transform="translate(98 39)" fill="none" stroke="#f1f0ee" strokeWidth="1"/>
                    <line id="Line_2" data-name="Line 2" x2="20" transform="translate(98 42)" fill="none" stroke="#f1f0ee" strokeWidth="1"/>
                </g>
                <text id="Menu" transform="translate(30 14)" fill="#f1f0ee" fontSize="15" fontFamily="BananaGrotesk-Semibold, Banana Grotesk" fontWeight="600" letterSpacing="-0.05em"><tspan x="0" y="0">Menu</tspan></text>
            </svg>

        </StyledHamburger>
    );
};

const StyledHamburger = styled.div`

  line{
    transition: all cubic-bezier(.74,0,.24,.99) 0.7s;
  } 
  
  #Group_18784{
    transition: all cubic-bezier(.74,0,.24,.99) 0.2s;
  }
  #Group_18784{
    transition: all cubic-bezier(.74,0,.24,.99) 0.2s;

  }
  &:hover {
    
    #Group_18784{
      opacity: 1;
      visibility: visible;
    }

    #Group_18783{
      opacity: 0;
      visibility: hidden;
    }
    
    #Line_1{
      //transform: translate(98px,39px);
    }
    
    #Line_2{
      //transform: translate(98px,42px);
    }

  }
`;

export default React.memo(Hamburger);
