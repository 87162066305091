import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "./Img";
import Button from "./Button";
import reactHtmlParser from "react-html-parser";
import StoneBlack from "./svg/StoneBlack";


const BuildHome = ({offset, revers, padding, data}) => {

    return (
        <StyledBuilHome offset={offset} className={`BuilHome ${padding ? padding : 'pt-200 pb-200'}`}>
            <div  className="fixed-icon box-down">
                <StoneBlack/>
            </div>
            <Container>
                <Row>
                    <Col md={{span: 6, offset: 6}}>
                        <h2 data-lag={'0.2'}>{data?.title}</h2>
                        <div data-lag={'0.2'}>
                           <p className={'p1-font split-up'}>{data?.subtitle}</p>
                            <div className="content">
                                {/*<p className={'split-up'}>*/}

                                {/*</p>*/}
                                {
                                    data?.description &&
                                    reactHtmlParser(data?.description)
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className={'padding-left'}>
                <Row>
                    <Col md={4}>

                        <div data-lag={'0.2'} className="img-wrp-ratio ">
                            <div className="reveal">
                                <Img src={data?.left_img}/>
                            </div>

                        </div>


                        {
                            window.innerWidth > 767 &&
                            <Button color={'#F1F0EE'} src={data?.url ? data?.url : '/upcoming-project'} margin={'80px 0 0'}
                                    text={'Upcoming Products'}/>

                        }


                    </Col>
                    <Col md={8}>

                        <div className="img-wrp-ratio-two">
                           <div className="reveal-right">
                               <Img src={data?.right_img}/>
                           </div>
                        </div>


                        {
                            window.innerWidth < 767 &&
                            <Button color={'#F1F0EE'}  src={'/upcoming-project'} margin={'40px 0 0'}
                                    text={'Upcoming Products'}/>
                        }
                    </Col>
                </Row>
            </Container>
        </StyledBuilHome>
    )
};

const StyledBuilHome = styled.div`
  position: relative;
  
  .fixed-icon{
    position: absolute;
    bottom: 15%;
    z-index: 9;
    left: 32%;
  }


  .padding-left {
    padding-right: 0;
    padding-left: ${props => props.offset ? props.offset + 15: '0'}px;
    margin-top: 60px;
  }

  .img-wrp-ratio {
    position: relative;
    padding-top: calc(362 / 370 * 100%);
  }

  .img-wrp-ratio-two {
    position: relative;
    padding-top: calc(654 / 868 * 100%);
  }
  
  h2 {
    margin-bottom: 85px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;

    li {
      flex: 0 0 calc(50% - 15px);
    }
  }

  
  .p1-font{
    margin-bottom: 10px;
  }
    .content{
      p{
        color: #818181;
      }
    }
  @media (max-width: 767px) {
    h2{
      margin-bottom: 40px;
    }
    .padding-left {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .img-wrp-ratio {
      margin-bottom: 40px;
    }

    ul {
      flex-direction: column-reverse;

      li {
        flex: 0 0 100%;
      }
    }

    .fixed-icon{
      position: absolute;
      bottom: 30px;
      z-index: 9;
      left: unset;
      right: 0;
    }
  }
`;


export default React.memo(BuildHome);
