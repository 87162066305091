import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "./Img";
import TilesBox from "./TilesBox";
import {Swiper, SwiperSlide} from 'swiper/react';
import swiper, {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import reactHtmlParser from "react-html-parser";

const BannerMobile = ({data}) => {


    // data from api
    const bannerData = data;
    //const imageMainSmall = data?.image?.thumbnail
   // const imageMainMedium = data?.image?.medium
    const imageMainLarge = data?.image?.large




    // make an array for swipper
    const dataForSlider = [



        {
            title: data?.furniture_title,
            img: data?.furniture_img,
            link: data?.furniture_link
        },

        {
            title: data?.tiles_title,
            img: data?.tiles_img,
            link: data?.tiles_link
        },
        {
            title: data?.lights_title,
            img: data?.lights_img,
            link: data?.lights_link
        }
    ];




    // remove address bar hight from mobile
    useEffect(() => {
        // Get the viewport height and multiply it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;

        // Set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, [data]); // Empty dependency array to run this effect only once






    return (
        <StyledComponent className={'banner'}>

            <div className="banner__scroller_mobile">
                <div className="image-opacity">
                    <Img src={imageMainLarge ? imageMainLarge :''} alt=""/>

                </div>
              <Container className="content">
                    <Row>
                        <Col md={{span: 10, offset: 1}} className={'p-0'}>
                            <h3>{
                                bannerData?.title &&
                                reactHtmlParser(bannerData?.title)
                            }</h3>
                            <p className="p1-first">
                                {
                                    bannerData?.short_desc &&
                                    reactHtmlParser(bannerData?.short_desc)
                                }   </p>
                            <Row className={'custom_row'}>


                                {
                                    dataForSlider?.length > 0 &&
                                    <Swiper
                                         modules={[Autoplay, Pagination, Navigation, EffectFade]}
                                        allowTouchMove={true}
                                        autoHeight={true}
                                        autoplay={false}
                                        draggable={true}
                                        slidesPerView={1}
                                        speed={1500}
                                        spaceBetween={15}
                                        centeredSlides={true}
                                        breakpoints={{
                                            320: {
                                                slidesPerView: 1,
                                                spaceBetween: 15,

                                            },
                                            768: {
                                                slidesPerView: 1,
                                                spaceBetween: 15,

                                            },
                                            1024: {
                                                slidesPerView: 1,
                                                spaceBetween: 50,
                                            },
                                        }}
                                        loop={true}
                                    >

                                        {
                                            dataForSlider && dataForSlider.length > 0 &&
                                            dataForSlider.map((e,index) => {
                                                return(
                                                    <SwiperSlide key={index}>
                                                        <TilesBox title={e.title} img={e.img} link={e.title}/>
                                                    </SwiperSlide>
                                                )
                                            })
                                        }



                                    </Swiper>
                                }


                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  z-index: 6;

  .banner__scroller_mobile {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    &:after {
      content: '';
      background: rgba(0, 0, 0, 0.45);
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background-blend-mode: hard-light;
      mix-blend-mode: hard-light;
    }
    .image-opacity .global-image{
      opacity: 0.45 !important;
    }

    .content {
      position: absolute;
      left: 0;
      right: 0;
      text-align: left;
      bottom: 40px;
      z-index: 99;

      h3 {
        font-size: 3.733rem;
        line-height: 3.733rem;
        font-weight: 300;
        color: #F1F0EE;
        margin-bottom: 20px;
        text-transform: uppercase;

        br {
          display: none;
        }
      }

      p {
        color: #F1F0EE;
        margin-bottom: 40px;
      }

      .row {
        margin: 0;
        gap: 40px;

        .single {
          padding: 0;
          max-width: calc(33.333333% - 40px);
          flex: 0 0 calc(33.333333% - 40px);
        }
      }
    }
  }

  .swiper-initialized {
    padding-right: 60px;
  }

  .custom_row {
    margin: 0 -15px !important;
    padding-left: 15px;
  }


`;

export default React.memo(BannerMobile);

