import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail} from "../../api/redux/product";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import RelatedCategory from "../../components/RelatedCategory";
import ProductForm from "../../components/ProductForm";
import ZigZag from "../../components/ZigZag";
import DetailedSlider from "../../components/DetailedSlider";
import gsap, {CSSPlugin, TimelineLite} from "gsap";

gsap.registerPlugin(CSSPlugin);

const ProjectDetails = () => {

    const dispatch = useDispatch()
    let {slug} = useParams();

    // api call
    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PRODUCTDETAILS
        dispatch(fetchPostDetail([api_url + `/${slug}`]))
    }, [slug,dispatch])

    const getPost = useSelector(store => store.product)


    let [offset, setOffset] = useState(90)

    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container').offsetLeft + 0)
        }

    }, [getPost])


    // for close menu
    let getMobileMenuBar = document.querySelector('.main-menu-mobile');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');
    let tl2 = new TimelineLite();
    const closeDelstopMenu = () => {
        tl2.to('.main-menu__items__list', {
            duration: .2,
            y: '-40px',
            alpha: 0
        }).to('.main-menu__items', {
            opacity: '0',
            duration: '.3'
        }).to('.main-menu__items__img', {
            opacity: '0',
            duration: '.2'
        }, '-=.5').to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0
        }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
        }).to('.main-menu__items', {
            display: 'none'
        })
    }

    useEffect(() => {

        document.querySelector('.main-menu').classList.remove('menu-is-open')
        closeDelstopMenu()
        // disableScroll.off()
        if (document.body.classList.contains('overflow')) {
            document.body.classList.remove('overflow')
        }
        document.querySelector('body').classList.remove('menu-is-open')


        getMobileMenuBar?.classList.remove('menu-open')
        document.body?.classList.remove('menu-on')
        document.querySelector('.main-menu-mobile').classList.remove('menu-is-open')
        document.body?.classList.remove('stop-scroll')
        if (document.querySelector('.main-item.active')) {
            getItemsParent.classList.remove('active')
            document.querySelector('.main-item.active').classList.remove('active')
        }

        tl2.to(getItemsParent, {
            duration: .2,
            x: '100%'
        }).to(getItemsParent, {
            duration: .2,
            display: 'none'
        })

    }, [getPost])

    const page_data = getPost?.detail?.[0]?.meta;
    const zigzag = getPost?.detail?.[0]?.zigzag
    const related_products = getPost?.detail?.[0]?.related_products
    const type = getPost?.detail?.[0]?.type?.[0]?.slug


    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title+' - Trade Intercontinental' : 'Trade Intercontinental'}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                <DetailedSlider type={type} data={getPost?.detail?.[0]}/>
                {
                    zigzag &&
                    zigzag.length > 0 &&
                    zigzag.map((e, index) => (
                        <ZigZag
                            description={e?.text_img_desc}
                            key={index} // Make sure to add a unique key when using map
                            padding={'p-0'} // Apply 'p-0' for even index, undefined for odd index
                            title={e?.text_img_title} // Set title for even index, undefined for odd index
                            reverse={index % 2 === 1} // Reverse for even index
                            img={e?.text_img_image} // Set image for even index, undefined for odd index
                            offset={offset}
                        />
                    ))
                }


                <div id={'request-form'}>
                    <ProductForm padding={`pt-200 ${related_products?.length > 0 ? '' : 'pb-200'}`} data={getPost?.detail[0]?.post_title}/>
                </div>
                {
                    related_products && related_products?.length > 0 &&
                    <RelatedCategory link={`/${type}`} data={related_products}/>

                }


            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
  .form-group {
    margin-bottom: 40px;

    &:last-child {
      border: none !important;
    }
  }

  .form_wrapper .form-group .form-control {
    font-size: 1rem !important; /* 15px / 15px = 1rem */
    font-weight: 600 !important;
    line-height: 1rem !important; /* 20px / 15px = 1.3333rem */
    letter-spacing: -0.05rem !important;
    color: #F1F0EE !important;
    padding-bottom: 15px !important;
    border-bottom-color: #F5F5F5;


    &::placeholder {
      font-size: 1rem !important; /* 15px / 15px = 1rem */
      font-weight: 600 !important;
      line-height: 1rem !important; /* 20px / 15px = 1.3333rem */
      letter-spacing: -0.05rem !important;
      color: #F1F0EE !important;
    }
  }

  @media (max-width: 767px) {
    .list_with_form {
      padding-top: 0 !important;
    }
  }
`;

export default ProjectDetails;
