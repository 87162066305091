import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "./../Img";
import StoneWhiteCircle from "../svg/StoneWhiteCircle";
import StoneWhiteCircleOne from "../svg/StoneWhiteCircleOne";
import {OnlyImg} from "../../OnlyImg";
import reactHtmlParser from "react-html-parser";


const OverviewInner = ({padding, data}) => {


    const space = parseInt(data?.space)
    const NonBreakingSpaces = ({count}) => {
        const nonBreakingSpaces = '\u00A0'.repeat(count); // '\u00A0' is the HTML entity for a non-breaking space
        return <span dangerouslySetInnerHTML={{__html: nonBreakingSpaces}}/>;
    };
    return (
        <StyledOverviewInner className={`about_overview ${padding ? padding : 'pt-200 pb-200'} `}>
            <div className="icon_black box-up">
                <StoneWhiteCircle/>
            </div>

            {
                window?.innerWidth > 767 &&
                <div data-lag={0.3} className="img_fixed">
                    <div className="icon_white box-up">
                        <StoneWhiteCircleOne/>
                    </div>
                    <div className="img_wrp reveal">
                        <OnlyImg src={data?.leftImage?.large}/>
                    </div>
                </div>
            }

            <Container>
                <Row>
                    <Col md={{span: 10, offset: 1}}>
                        <div className="title-wrp">
                            <h3 data-lag={0.1}>
                                <NonBreakingSpaces count={space}/>
                                {

                                    data?.title &&
                                    reactHtmlParser(data?.title)
                                }</h3>
                        </div>
                    </Col>
                    {
                        window?.innerWidth < 767 &&
                        <Col md={12}>
                            <div className="img_fixed">
                                <div className="icon_white box-up">
                                    <StoneWhiteCircleOne/>
                                </div>
                                <div className="img_wrp reveal">
                                    <OnlyImg src={data?.leftImage?.large}/>
                                </div>
                            </div>
                        </Col>
                    }
                    <Col md={{span: 7, offset: 5}} >
                        <div className="content">

                            {reactHtmlParser(data?.leftDesc)}
                            {/*<p className={'p1-font split-text'}></p>*/}
                                {reactHtmlParser(data?.rightDesc)}

                            {/*<p className={'p1-font split-text'}></p>*/}

                        </div>

                    </Col>


                </Row>
            </Container>
            <Container className={'fluid-style'} fluid>
                <Row>
                    <Col md={{span: 7, offset: 5}} className={''}>


                        <div data-lag={0.3} className="image_content">
                            <div className="img-wrapper">

                                <div className="reveal-right">
                                    <Img src={data?.rightImage?.large}/>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;

  .title-wrp {
    margin-bottom: 80px;
  }

  .img_fixed {
    left: 0;
    top: 30%;
    position: absolute;

    .icon_white {
      position: absolute;
      right: -100px;
      bottom: 80px;
      z-index: 8;
    }

    .img_wrp {
      overflow: hidden;
      //padding-top: calc(600 / 468 * 100%);
      position: relative;
      max-width: 468px;
      max-height: 600px;
      @media(min-width: 1920px){
        width: 600px;
        height: 800px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .icon_black {
    right: -100px;
    top: 200px;
    position: absolute;
    img{
      filter: blur(5px);
    }
  }

  .content {
    margin-bottom: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;

    p {
      width: calc(50% - 15px);
      flex: 0 0 calc(50% - 15px);
      color: #818181;
      font-size: 1rem; /* 15px / 15px = 1rem */
      font-weight: 600;
      line-height: 1.3333rem; /* 20px / 15px = 1.3333rem */
      letter-spacing: -0.05rem;
    }
  }

  .image_content {
    .img-wrapper {
      overflow: hidden;
      position: relative;
      padding-top: calc(360 / 470 * 100%);
    }
  }

  @media(min-width: 767px){
    .fluid-style{
      padding-left: 70px;
      .col-md-7{
        padding: 0;
      }
    }
  }
  @media (max-width: 767px) {
    .img_fixed {
      position: relative;
      left: 0;
      right: 0;
      top: unset;
      bottom: unset;
      width: 74%;
      margin-bottom: 60px;
    }

    .image_content {
      width: 75%;
      margin: 0 0 0 auto;

      .icon_black {
        z-index: 3;
        right: unset;
        left: -50px;
        top: -50px;
      }

      .bZKZcg {
        height: 100px;
        width: 150px;
      }

      .img-wrapper {
        overflow: visible;
      }
    }

    .content {
      margin-bottom: 60px;
      width: 75%;
      flex-direction: column;
      p{
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }
`;


export default React.memo(OverviewInner);
