import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store from "./api/store";
import {BrowserRouter} from 'react-router-dom'

// import ReactDOM from 'react-dom/client';
//     import ReactDOM from 'react-dom';


    // ReactDOM.createRoot(document.getElementById('root')).render(
    //     <React.StrictMode>
    //         <Provider store={store}>
    //             <BrowserRouter>
    //                     <App />
    //             </BrowserRouter>
    //         </Provider>
    //     </React.StrictMode>,
    // );

    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );

    reportWebVitals();
