import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory, useLocation
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail} from "../../api/redux/insights";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import InnerBanner from "../../components/InnerBanner";
import Social from "../../components/Social";
import {Img} from "../../components/Img";
import {Swiper, SwiperSlide} from 'swiper/react';
import swiper, {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import BrandBox from "../../components/BrandBox";
import NavigationIcon from "../../components/NavigationIcon";
import VideoDetails from "../../components/VideoDetails";
import RelatedInsight from "../../components/RelatedInsight";
import InsightSliderBackup from "../../components/InsightSliderBackup";
import gsap, {CSSPlugin, TimelineLite} from "gsap";
import {hover, medium, text} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";

gsap.registerPlugin(CSSPlugin);

const InsightDetails = () => {

    const dispatch = useDispatch()
    let {slug} = useParams();
    const location = useLocation()


    // api call
    useEffect(() => {
        let api_url = apiEndPoints.INSIGHTS+`/${slug}`
        dispatch(fetchPostDetail([api_url]))
    }, [dispatch])

    const getPost = useSelector(store => store.insight)

    const page_data = getPost?.detail?.[0]?.meta
    const banner = getPost?.detail?.[0]?.details?.banner
    const details = getPost?.detail?.[0]?.details
    const relatedInsight = getPost?.detail?.[0]?.details?.list
    const video = getPost?.detail?.[0]?.details?.video


    // social share


    // for close menu
    // let getMobileMenuBar = document.querySelector('.main-menu-mobile');
    // let getItemsParent = document.querySelector('.main-menu-mobile__items');
    // let tl2 = new TimelineLite();
    // const closeDelstopMenu = () => {
    //     tl2.to('.main-menu__items__list', {
    //         duration: .2,
    //         y: '-40px',
    //         alpha: 0
    //     }).to('.main-menu__items', {
    //         opacity: '0',
    //         duration: '.3'
    //     }).to('.main-menu__items__img', {
    //         opacity: '0',
    //         duration: '.2'
    //     }, '-=.5').to('.main-menu__items__list', {
    //         duration: .2,
    //         y: '40px',
    //         alpha: 0
    //     }, '-=.3').to('.main-menu__items', {
    //         duration: '.4',
    //         opacity: 0
    //     }).to('.main-menu__items', {
    //         display: 'none'
    //     })
    // }
    //
    // useEffect(() => {
    //
    //     document.querySelector('.main-menu').classList.remove('menu-is-open')
    //     closeDelstopMenu()
    //     // disableScroll.off()
    //     if (document.body.classList.contains('overflow')) {
    //         document.body.classList.remove('overflow')
    //     }
    //     document.querySelector('body').classList.remove('menu-is-open')
    //
    //
    //     getMobileMenuBar?.classList.remove('menu-open')
    //     document.body?.classList.remove('menu-on')
    //     document.querySelector('.main-menu-mobile').classList.remove('menu-is-open')
    //     document.body?.classList.remove('stop-scroll')
    //     if (document.querySelector('.main-item.active')) {
    //         getItemsParent.classList.remove('active')
    //         document.querySelector('.main-item.active').classList.remove('active')
    //     }
    //
    //     tl2.to(getItemsParent, {
    //         duration: .2,
    //         x: '100%'
    //     }).to(getItemsParent, {
    //         duration: .2,
    //         display: 'none'
    //     })
    //
    // }, [getPost])
    //

    //sticky
    const sectionSingle = useRef();
    const detailsRef = useRef();

    useEffect(() => {
        // let details = document.querySelector('.details-wrapper ')

        if (window?.innerWidth > 767 && detailsRef?.current) {
            gsap.to(detailsRef?.current, {
                scrollTrigger: {
                    trigger: sectionSingle?.current,
                    start: "top top+=100px",
                    pinnedContainer: sectionSingle?.current,
                    immediateRender: false,
                    anticipatePin: 1,
                    ease: "elastic",
                    id: `whois`,
                    end: "bottom-=250",
                    pinType: 'transform',
                    pin: true,
                    pinSpacing: false,
                    toggleActions: "play none none reverse",
                    pinReparent: true,


                }
            });
        }
    }, [getPost]);

    let [shareUrl, setShareUrl] = useState('')


    let shareButtonClickM = useRef()
    let shareButtonContentM = useRef()
    let shareToggle = useRef()

    useEffect(() => {
        setShareUrl(window.location.href)
    }, [shareUrl])


    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title + ' - Trade Intercontinental' : 'Trade Intercontinental'}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                <InnerBanner noPara notFull img={banner?.large}/>
                <section ref={detailsRef} className="details-wrapper">
                    <Container>
                        <Row className={'main-row'}>
                            <Col md={3} ref={sectionSingle} className={'social-pin-wrapper'}>
                                <div className="social-wrapper-main">
                                    <p className="gray">Share:</p>
                                    <ul className="social">
                                        <li>
                                            <FacebookShareButton url={shareUrl}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                     viewBox="0 0 25 25">
                                                    <g id="Group_19953" data-name="Group 19953"
                                                       transform="translate(-398 -664)">
                                                        <circle id="Ellipse_447" data-name="Ellipse 447" cx="12.5"
                                                                cy="12.5" r="12.5"
                                                                transform="translate(398 664)" fill="#f1f0ee"/>
                                                        <circle className={'hover_circle'} data-name="Ellipse 594"
                                                                cx="12.5" cy="12.5" r="0"
                                                                transform="translate(398 664)" fill={hover}/>
                                                        <path id="Path_2115" data-name="Path 2115"
                                                              d="M1204.9,102.967l.339-2.21h-2.12V99.323a1.1,1.1,0,0,1,1.246-1.194h.964V96.248a11.752,11.752,0,0,0-1.711-.149,2.7,2.7,0,0,0-2.887,2.974v1.684h-1.941v2.21h1.941v5.341h2.388v-5.341Z"
                                                              transform="translate(-791.62 574.567)" fill="#1a181b"/>
                                                    </g>
                                                </svg>

                                            </FacebookShareButton>
                                        </li>
                                        <li>
                                            <TwitterShareButton url={shareUrl}>

                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                     viewBox="0 0 25 25">
                                                    <g id="Group_19952" data-name="Group 19952"
                                                       transform="translate(-438 -664)">
                                                        <circle id="Ellipse_93" data-name="Ellipse 93" cx="12.5"
                                                                cy="12.5" r="12.5"
                                                                transform="translate(438 664)" fill="#f1f0ee"/>
                                                        <circle className={'hover_circle'} data-name="Ellipse 594"
                                                                cx="12.5" cy="12.5" r="0"
                                                                transform="translate(438 664)" fill={hover}/>

                                                        <g id="layer1" transform="translate(444.667 671.5)">
                                                            <path id="path1009"
                                                                  d="M281.527,167.31l4.26,5.7-4.287,4.632h.965l3.754-4.055,3.033,4.055h3.284l-4.5-6.017,3.991-4.311h-.965l-3.457,3.735-2.793-3.735Zm1.419.711h1.509l6.661,8.907h-1.509Z"
                                                                  transform="translate(-281.5 -167.31)" fill="#1a181b"/>
                                                        </g>
                                                    </g>
                                                </svg>


                                            </TwitterShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton url={shareUrl}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                     viewBox="0 0 25 25">
                                                    <g id="Group_19949" data-name="Group 19949"
                                                       transform="translate(-558 -664)">
                                                        <circle id="Ellipse_594" data-name="Ellipse 594" cx="12.5"
                                                                cy="12.5" r="12.5"
                                                                transform="translate(558 664)" fill="#f1f0ee"/>
                                                        <circle className={'hover_circle'} data-name="Ellipse 594"
                                                                cx="12.5" cy="12.5" r="0"
                                                                transform="translate(558 664)" fill={hover}/>
                                                        <g id="_x31_0.Linkedin" transform="translate(565.5 671.5)">
                                                            <path id="Path_2123" data-name="Path 2123"
                                                                  d="M44.965,42.352V38.575c0-1.856-.4-3.275-2.565-3.275a2.239,2.239,0,0,0-2.024,1.109H40.35v-.941H38.3v6.884h2.14V38.936c0-.9.168-1.766,1.276-1.766s1.109,1.018,1.109,1.818v3.352h2.14Z"
                                                                  transform="translate(-34.652 -32.038)"
                                                                  fill="#1a181b"/>
                                                            <path id="Path_2124" data-name="Path 2124"
                                                                  d="M11.3,36.6h2.14v6.884H11.3Z"
                                                                  transform="translate(-11.132 -33.171)"
                                                                  fill="#1a181b"/>
                                                            <path id="Path_2125" data-name="Path 2125"
                                                                  d="M11.238,10a1.244,1.244,0,1,0,1.238,1.238A1.238,1.238,0,0,0,11.238,10Z"
                                                                  transform="translate(-10 -10)" fill="#1a181b"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </LinkedinShareButton>
                                        </li>
                                        <li>
                                            <EmailShareButton url={shareUrl}>

                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                     viewBox="0 0 25 25">
                                                    <defs>
                                                        <clipPath id="clip-path">
                                                            <rect id="Rectangle_6283" data-name="Rectangle 6283"
                                                                  width="13" height="13"
                                                                  transform="translate(14289 121.282)" fill="#1a181b"/>
                                                        </clipPath>
                                                    </defs>
                                                    <g id="Group_23859" data-name="Group 23859"
                                                       transform="translate(5928 -353)">
                                                        <circle id="Ellipse_612" data-name="Ellipse 612" cx="12.5"
                                                                cy="12.5" r="12.5" transform="translate(-5928 353)"
                                                                fill="#f1f0ee"/>
                                                        <g id="Mask_Group_19945" data-name="Mask Group 19945"
                                                           transform="translate(-20211 237.718)"
                                                           clipPath="url(#clip-path)">
                                                            <g id="Icon_feather-link-2" data-name="Icon feather-link-2"
                                                               transform="translate(14289.585 129.66) rotate(-45)">
                                                                <path id="Path_173" data-name="Path 173"
                                                                      d="M7.267,0H8.824a2.734,2.734,0,0,1,2.6,2.855,2.734,2.734,0,0,1-2.6,2.855H7.267m-3.114,0H2.6A2.734,2.734,0,0,1,0,2.855,2.734,2.734,0,0,1,2.6,0H4.153"
                                                                      fill="none" stroke="#1a181b" strokeLinecap="round"
                                                                      strokeLinejoin="round" strokeWidth="1.2"/>
                                                                <path id="Path_174" data-name="Path 174" d="M0,0H4.841"
                                                                      transform="translate(3.289 2.855)" fill="none"
                                                                      stroke="#1a181b" strokeLinecap="round"
                                                                      strokeLinejoin="round" strokeWidth="1.2"/>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>


                                            </EmailShareButton>

                                        </li>
                                    </ul>

                                </div>
                            </Col>
                            <Col md={9} className={'content-wrapper'}>
                                <ul className="intro">
                                    <li>
                                        <p className="gray">Blog</p>
                                    </li>
                                    <li>
                                        <p className="gray">09 April 2023 </p>
                                    </li>
                                </ul>
                                {
                                    details?.short_title &&
                                    <h3>{details?.short_title}</h3>
                                }

                                {
                                    details?.top_desc &&
                                    reactHtmlParser(details?.top_desc)
                                }
                                {
                                    details?.top_img &&
                                    <div className="img-group-caption">
                                        <div className="img-wrapper-big">
                                            <Img src={details?.top_img?.large}/>
                                        </div>
                                        <p>{details?.top_img?.caption ? details?.top_img?.caption : '[Image Caption] Creating a comprehensive plan with everyone in mind'}</p>
                                    </div>
                                }

                                {
                                    details?.mid_desc &&
                                    reactHtmlParser(details?.mid_desc)
                                }

                                {
                                    details?.slider && details?.slider?.length > 0 &&
                                    <div className="image-gallery-slider">

                                        <Swiper
                                            Swiper modules={[Autoplay, Pagination, Navigation, EffectFade]}
                                            slidesPerView={1}
                                            speed={1500}
                                            spaceBetween={50}
                                            draggable={true}
                                            navigation={{
                                                prevEl: '#details-prev',
                                                nextEl: '#details-next',
                                            }}
                                            loop={true}
                                        >


                                            {
                                                details?.slider && details?.slider?.length > 0 &&
                                                details?.slider.map((e, index) => {
                                                    return (
                                                        <SwiperSlide key={index}>
                                                            <div className="img-group-caption">
                                                                <div className="img-wrapper-big">
                                                                    <Img src={e?.url}/>
                                                                </div>
                                                                <p>{e?.caption}</p>
                                                            </div>
                                                        </SwiperSlide>

                                                    )
                                                })
                                            }

                                        </Swiper>
                                        <div className="navigation">
                                            <NavigationIcon prev_id={'details-prev'} next_id={'details-next'}
                                                            color={'#F1F0EE'}/>
                                        </div>
                                    </div>

                                }

                                {
                                    details?.quote &&
                                    <blockquote className={'split-up'}>
                                        {reactHtmlParser(details?.quote)}
                                    </blockquote>
                                }


                                {
                                    video?.id &&
                                    <VideoDetails data={video}/>
                                }


                                {
                                    details?.bottom_desc &&
                                    reactHtmlParser(details?.bottom_desc)
                                }

                            </Col>
                        </Row>
                    </Container>
                </section>
                {
                    relatedInsight?.length > 0 &&
                    <InsightSliderBackup data={relatedInsight} insight/>

                }
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

  .details-wrapper {
    padding-top: 60px;
    overflow: hidden;

    .gray {
      color: #818181;
      margin-bottom: 30px;
    }

    .social-pin-wrapper {
      .social {
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 20px;

        li {
          margin-left: 0;
        }
      }
    }

    .content-wrapper {
      //padding-bottom: 60px;
      //border-bottom: 1px solid #F5F5F5;
      &:after {
        position: absolute;
        left: 0;
        margin: 0 auto;
        right: 0;
        background: #F5F5F5;
        width: calc(100% - 30px);
        content: '';
        bottom: 0;
        height: 1px;
      }

      .size-full {
        width: 100%;
        object-fit: cover;
      }

      .size-medium {
        width: 50%;
        object-fit: cover;
        float: left;

        &:first-child {
          padding-right: 15px;
        }

        &:last-child {
          padding-left: 15px;
        }

        @media (max-width: 767px) {
          width: 100%;
          padding: 0 !important;
        }
      }

      .intro {
        display: flex;
        justify-content: space-between;
      }

      h3 {
        padding-bottom: 60px;
        border-bottom: 1px solid #F5F5F5;
        margin-bottom: 60px;
      }

      span {
        margin-bottom: 10px;
        display: block;
      }

      ul {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      p {
        margin-bottom: 60px;
      }

      h6 {
        margin-bottom: 30px;
      }

      .t-list {
        margin-top: 0;
      }

      .img-group-caption {
        margin-bottom: 40px;

        .img-wrapper-big {
          position: relative;
          padding-top: calc(520 / 870 * 100%);
        }

        p {
          margin-top: 20px;
        }
      }

      .img-group-multiple {
        margin-bottom: 40px;

        .img-wrapper-big {
          position: relative;
          //padding-top: calc(520 / 870 * 100%);
          margin-bottom: 30px;
        }

        .img-wrapper-small {
          position: relative;
          //padding-top: calc(250 / 420 * 100%);
        }

      }

      table {
        margin-bottom: 40px;
      }

      .image-gallery-slider {
        position: relative;
        margin-bottom: 150px;

        .navigation {
          position: absolute;
          bottom: 30px;
          right: 0;
        }

        .swiper-slide-active {
          p {
            transition-delay: 0.7s;
            opacity: 1;
          }
        }

        p {
          opacity: 0;
          transition: 0.7s all cubic-bezier(.54, .32, .05, .96);
          max-width: 70%;
          margin-top: 50px;
        }
      }

      blockquote {
        margin-bottom: 60px;
      }
    }

  }

  .social {
    display: flex;
    flex-direction: row;
    gap: 20px;

    li {
      list-style: none !important;

      &:after {
        display: none !important;
      }

      &:before {
        display: none !important;
      }

      a {
        &:after {
          display: none !important;
        }

        &:before {
          display: none !important;
        }
      }
    }

    svg {
      overflow: hidden;
      border-radius: 50%;

      .hover_circle, path {
        transition: 0.5s all cubic-bezier(.25, .74, .22, .99);
      }
    }

    li {
      margin-left: 0px;
      margin-bottom: 0 !important;

      &:first-child {
        margin-left: 0;
      }

      a {
        &:hover {
          svg {
            .hover_circle {
              r: 12.5px;
            }

            path {
              fill: ${text};
            }


          }
        }
      }
    }
  }

  @media (max-width: 992px) and (min-width: 767px) {
    .social {
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 20px;

      li {
        margin-left: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .details-wrapper {
      margin: 0 15px;

      .main-row {
        flex-direction: column-reverse;
      }

      .content-wrapper {
        padding: 0;

        .image-gallery-slider .navigation {
          right: unset;
          left: unset;
          bottom: unset;
          position: relative;
        }
      }

      .social-pin-wrapper {
        margin-top: 60px;
        padding: 0;

        .social {

          flex-direction: row;
          gap: 20px;

          li {
            margin-left: 0;
          }
        }
      }
    }
  }

`;

export default InsightDetails;
